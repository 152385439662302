import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../../assets_landing/styles/landing.css";
import { useDispatch } from "react-redux";
import { contactUsRequest } from "../../redux/Landing/lan.action";
import { ToastContainer, toast } from "react-toastify";

export default function LandingPage() {
  const dispatch = useDispatch();
  const [activeLink, setActiveLink] = useState("home");
  const [contactForm, setContactForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  useEffect(() => {
    let script = document.createElement("script");
    script.id = "scriptId";
    script.src = "./assets_landing/scripts/landing.js";
    document.body.append(script);
  }, []);

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  const sectionRefs = {
    freedom: useRef(null),
    commitment: useRef(null),
    workflow: useRef(null),
    happiness: useRef(null),
    api: useRef(null),
    home: useRef(null),
    contact: useRef(null),
  };

  const scrollToSection = (section) => {
    setActiveLink(section);
    const ref = sectionRefs[section];
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleContactForm = (e) => {
    const { name, value } = e.target;
    setContactForm({ ...contactForm, [name]: value });
  };

  const submitRequest = (e) => {
    e.preventDefault();
    if (!contactForm.firstName) {
      toast.error("Please enter your first name", {
        autoClose: 1000,
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d+$/;
    if (!phoneRegex.test(contactForm.phone)) {
      toast.error("Please enter a valid phone number", {
        autoClose: 1000,
      });
      return;
    }

    if (!emailRegex.test(contactForm.email)) {
      toast.error("Please enter a valid email address", {
        autoClose: 1000,
      });
      return;
    }

    if (!contactForm.message) {
      toast.error("Please type your query", {
        autoClose: 1000,
      });
      return;
    }

    const payLoad = {
      fname: contactForm.firstName,
      lname: contactForm.lastName,
      contact: contactForm.phone,
      email: contactForm.email,
      message: contactForm.message,
    };
    dispatch(contactUsRequest(payLoad));
    setContactForm({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      message: "",
    });
  };

  return (
    <>
      <header className="headerSec">
        <div className="container">
          <nav>
            <span className="headerSec__logo">
              <img
                className="replaceWithSVG"
                src="/landing_images/logo.svg"
                alt="logo"
              />
            </span>

            <div className="techBorder pt-0 d-none d-lg-block">
              <span className="techBorder__leftTop"></span>
              <span className="techBorder__leftBot"></span>
              <span className="techBorder__leftDot"></span>
              <span className="techBorder__rightTop"></span>
              <span className="techBorder__rightBot"></span>
              <span className="techBorder__rightDot"></span>
              <ul>
                <li>
                  <Link
                    className={activeLink === "home" ? "active" : ""}
                    to="#home"
                    onClick={() => scrollToSection("home")}
                  >
                    HOME
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "freedom" ? "active" : ""}
                    to="#freedom"
                    onClick={() => scrollToSection("freedom")}
                  >
                    FREEDOM
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "commitment" ? "active" : ""}
                    to="#commitment"
                    onClick={() => scrollToSection("commitment")}
                  >
                    COMMITMENTS
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "workflow" ? "active" : ""}
                    to="#workflow"
                    onClick={() => scrollToSection("workflow")}
                  >
                    WORK FLOW
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "happiness" ? "active" : ""}
                    to="#happiness"
                    onClick={() => scrollToSection("happiness")}
                  >
                    HAPPINESS
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "api" ? "active" : ""}
                    to="#api"
                    onClick={() => scrollToSection("api")}
                  >
                    API
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "contact" ? "active" : ""}
                    to="#contact"
                    onClick={() => scrollToSection("contact")}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
              <ToastContainer />
            </div>

            <div className="headerSec__buttons">
              <Link
                className="headerSec__buttons--green d-none d-lg-flex"
                to="/signIn"
              >
                LOGIN IN
              </Link>
              {/* <Link className="d-none d-lg-flex" to="/signIn">VERIFY NOW</Link> */}
              <Link
                className="d-lg-none menu-btn menu-btn--hamburger me-3"
                // to="javascript:;"
              >
                <img
                  className="menu-btn__icon1"
                  src="/landing_images/menu-btn.svg"
                  alt="menu"
                />
              </Link>
            </div>
          </nav>
        </div>
      </header>

      <aside className="mt-0 menuSec">
        <div className="menuSec__inner">
          <div className="menuSec__head">
            <span></span>
            <a
              className="d-lg-none menu-btn menu-btn--cross me-1"
              // to="javascript:;"
            >
              <img
                className="menu-btn__icon1"
                src="/landing_images/menu-close.svg"
                alt="menu close"
              />
            </a>
          </div>
          <div className="menuSec__deco1">
            <img
              className=""
              src="/landing_images/menu-top.svg"
              alt="menu-top"
            />
          </div>
          <div className="menuSec__logo-wrap">
            <a className="menuSec__head-logo">
              <img src="/landing_images/logo-white.svg" alt="logo" />
            </a>

            <div className="menuSec__head-desc">
              providing a quantum-proof secured cloud environment for document
              management and record-keeping engine.
            </div>
          </div>

          <div className="menuSec__links">
            <div className="row">
              <div className="col">
                <ul>
                  <li>
                    <Link to="#home" className="active">
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to="#freedom">FREEDOM</Link>
                  </li>
                  <li>
                    <Link to="#commitments">COMMITMENTS</Link>
                  </li>
                  <li>
                    <Link to="#workflow">WORK FLOW</Link>
                  </li>
                  <li>
                    <Link to="#happiness">HAPPINESS</Link>
                  </li>
                  <li>
                    <Link to="#contact">Contact us</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul>
                  <li>
                    <Link className="active" hretof="#home">
                      quick links
                    </Link>
                  </li>
                  <li>
                    <Link to="#api">api documentation</Link>
                  </li>
                  <li>
                    <Link to="">github</Link>
                  </li>
                  <li>
                    <Link to="">prices and resources</Link>
                  </li>
                  <li>
                    <Link to="">libraries</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="menuSec__footer">
            <div className="menuSec__footer-hands">
              <img
                src="landing_images/menu/shake-hands.svg"
                alt="shake-hands"
              />
            </div>
            <h2 className="menuSec__footer-title">Join hands with us!</h2>
            <div className="menuSec__footer-follow">
              <Link to="">
                <img src="landing_images/footer/icon-fb.svg" alt="Facebook" />
              </Link>
              <Link to="">
                <img src="landing_images/footer/icon-in.svg" alt="Instagram" />
              </Link>
              <Link to="">
                <img src="landing_images/footer/icon-tel.svg" alt="Telegram" />
              </Link>
              <Link to="">
                <img src="landing_images/footer/icon-li.svg" alt="Linkedin" />
              </Link>
            </div>

            <div className="menuSec__footer-btns">
              <div className="menuSec__buttons">
                <Link className="menuSec__buttons--green" to="">
                  SIGN UP NOW
                </Link>
                <Link className="" to="">
                  VERIFY DOCS
                </Link>
              </div>
            </div>
          </div>
        </div>
      </aside>

      <div id="smooth-wrapper">
        <div id="smooth-content" className="position-relative">
          <div className="landing">
            <div className="clientsSec--carousel">
              <section className="heroSec section" ref={sectionRefs.home}>
                {/* <img
                className="heroSec__deco0 fade-in"
                src="/landing_images/hero/img1.png"
                alt="img1"
              /> */}

                <div className="heroSec__columns">
                  <div className="heroSec__inner">
                    <div className="container">
                      <div className="heroSec__left">
                        <h1 className="section-head1 fade-in fade-in-delay-0">
                          Docur provides a{" "}
                          <span className="color-green">decentralized</span>{" "}
                          hypermedia protocol{" "}
                        </h1>
                        <p className="section-desc fade-in fade-in-delay-0.25">
                          Docur’s decentralised hypermedia protocol offers a
                          secure cloud environment for seamless document
                          management and record-keeping. Our platform ensures
                          unparalleled data integrity, making it the ideal
                          solution for managing sensitive documents.
                        </p>
                        <p className="section-greentxt fade-in fade-in-delay-0.25">
                          Experience the future of secure document management
                          with Docur!
                        </p>
                        <div className="heroSec__buttons fade-in fade-in-delay-0.25">
                          <Link className="button button-dark" to="/signIn">
                            START VERIFICATION
                          </Link>
                        </div>
                        <div className="heroSec__deco4-wrapper fade-in fade-in-delay-0.25">
                          <img
                            className="heroSec__deco4 anim-click-arrow"
                            src="/landing_images/hero/deco4.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="heroSec__statArea">
                    <img
                      className="heroSec__deco0 fade-in"
                      src="/landing_images/hero/img1.png"
                      alt=""
                    />
                    <div className="heroSec__stats fade-in fade-in-delay-0.25">
                      <img
                        className="heroSec__stats-bg"
                        src="/landing_images/hero/count-bg.svg"
                        alt=""
                      />
                      <img
                        className="heroSec__stats-line desk-only"
                        src="/landing_images/hero/count-line.svg"
                        alt=""
                      />
                      <img
                        className="heroSec__stats-line mobile-only"
                        src="/landing_images/hero/heroSec__stats-line-mobile.svg"
                        alt="count-line"
                      />
                      <div className="heroSec__icons">
                        <span className="heroSec__icons-csv">
                          <img
                            src="/landing_images/hero/icon-csv.svg"
                            alt="CSV"
                          />
                          <div>
                            <span>.CSV</span>
                          </div>
                        </span>
                        <span className="heroSec__icons-pdf">
                          <img
                            src="/landing_images/hero/icon-pdf.svg"
                            alt="PDF"
                          />
                          <div>
                            <span>.PDF</span>
                          </div>
                        </span>
                        <span className="heroSec__icons-doc">
                          <img
                            src="/landing_images/hero/icon-doc.svg"
                            alt="DOCX"
                          />
                          <div>
                            <span>.DOCX</span>
                          </div>
                        </span>
                      </div>
                      <div className="heroSec__count">
                        <div>
                          {/* <div>
                            <span className="animate-number" data-number="1250">
                              1250
                            </span>
                            k+
                          </div> */}
                          <div>Data Shared</div>
                        </div>
                        <div>
                          {/* <div>
                            <span className="animate-number" data-number="6">
                              6
                            </span>
                            x
                          </div> */}
                          <div>Uploading</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* <section className="clientsSec section" ref={sectionRefs.freedom}>
                <img
                  className="clientsSec__deco1 anim-to-right"
                  src="/landing_images/clients/deco1.svg"
                  alt="deco1"
                />
                <img
                  className="clientsSec__deco2 anim-to-right"
                  src="/landing_images/clients/deco2.png"
                  alt="deco2"
                />
                <img
                  className="clientsSec__deco3 anim-to-right"
                  src="/landing_images/clients/deco3.svg"
                  alt="deco3"
                />
                <img
                  className="clientsSec__deco4 anim-to-right"
                  src="/landing_images/clients/deco4.svg"
                  alt="deco4"
                />

                <div className="container">
                  <h2 className="clientsSec__title">
                  
                    <img
                      src="/landing_images/clients/bulb.svg"
                      alt="thoughts"
                    />{" "}
                    Decentralised security, delivered with a Smile (and cookies)
                    <img
                      src="/landing_images/clients/cookies.svg"
                      alt="cookies"
                    />
                 
                  </h2>

                  <div className="clientsSec__all">
                    <div className="owl-carousel owl-theme">
                      <div className="item">
                        <div className="clientsSec__single">
                          <img
                            src="/landing_images/clients/client1.svg"
                            alt="client1"
                          />
                        </div>
                      </div>
                      <div className="item">
                        <div className="clientsSec__single">
                          <img
                            src="/landing_images/clients/client2.svg"
                            alt="client2"
                          />
                        </div>
                      </div>
                      <div className="item">
                        <div className="clientsSec__single">
                          <img
                            src="/landing_images/clients/client3.svg"
                            alt="client3"
                          />
                        </div>
                      </div>
                      <div className="item">
                        <div className="clientsSec__single">
                          <img
                            src="/landing_images/clients/client4.svg"
                            alt="client4"
                          />
                        </div>
                      </div>
                      <div className="item">
                        <div className="clientsSec__single">
                          <img
                            src="/landing_images/clients/client5.svg"
                            alt="client5"
                          />
                        </div>
                      </div>
                      <div className="item">
                        <div className="clientsSec__single">
                          <img
                            src="/landing_images/clients/client6.svg"
                            alt="client6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}

              <section className="storiesSec section">
                <div className="container">
                  <div className="row">
                    <div className="col-md-5">
                      <img
                        className="storiesSec__img"
                        src="/landing_images/stories/img1.svg"
                        alt="img1"
                      />
                    </div>
                    <div className="col-md-7 ">
                      <h2 className="section-head1">
                        We narrate{" "}
                        <img
                          src="/landing_images/stories/mike.svg"
                          alt="mike"
                        />{" "}
                        our own stories
                      </h2>
                      <p className="section-desc">
                        Our advanced technology provides tamper-proof support
                        for your documents and certificates, maintaining
                        immutable records of essential information. Docur’s
                        quantum-secured network also secures details such as the
                        issuer, verifier, receiver/owner, last date of audit,
                        last auditor, and date of expiry.
                      </p>
                      <p className="section-greentxt">
                        Infuse unparalleled security and integrity into your
                        document management with Docur!
                      </p>
                    </div>
                  </div>
                </div>

                <img
                  className="storiesSec__deco1"
                  src="/landing_images/stories/deco1.svg"
                  alt="decoration"
                />
              </section>

              <section className="freedomSec section" ref={sectionRefs.api}>
                <div className="container position-relative">
                  <div className="row">
                    <div className="col-md-8 position-relative">
                      <img
                        className="freedomSec__bg"
                        src="/landing_images/freedom/bg.png"
                        alt="bg"
                      />
                      <div className="freedomSec__content">
                        <div className="freedomSec__code">
                          <div className="freedomSec__numberLine">
                            <div>1</div>
                            <div>2</div>
                            <div>3</div>
                            <div>4</div>
                            <div>5</div>
                            <div>6</div>
                            <div>7</div>
                            <div>8</div>
                            <div>9</div>
                            <div>10</div>
                            <div>11</div>
                            <div>12</div>
                            <div>13</div>
                            <div>14</div>
                            <div>15</div>
                            <div>16</div>
                            <div>17</div>
                            <div>18</div>
                            <div>19</div>
                            <div>20</div>
                          </div>

                          <div className="freedomSec__type type hljs-comment">
                            <span id="typeText"></span>{" "}
                            <span id="cursor">|</span>
                          </div>

                          <pre id="codeWrapper" style={{ opacity: "0" }}>
                            <code className="language-javascript">
                              // Imports import mongoose, {"Schema"} from
                              'mongoose' // Collection name export const
                              collection = 'Product'| // Schema const schema =
                              new Schema({"{"}
                              name: {"{"}
                              type: String, required: true
                              {"}"}, description:{"{"}
                              type: String
                              {" }"}
                              {"}"},{"{"}timestamps: true{"}"}) // Model export
                              default mongoose.model(collection, schema,
                              collection)
                            </code>
                            <code className="language-javascript">
                              // Collection name export const collection =
                              'Product'|
                            </code>
                            <code className="language-javascript">
                              // Schema const schema = new Schema({"{"}
                            </code>
                            <code className="language-javascript">
                              name: {"{"}
                              type: String, required: true
                              {"}"},
                            </code>
                            <code className="language-javascript">
                              type: String, required: true
                              {"}"},
                            </code>
                            <code className="language-javascript">
                              description:{"{"}
                              type: String
                              {" }"}
                              {"}"},
                            </code>
                            <code className="language-javascript">
                              {"{"}timestamps: true{"}"})
                            </code>
                            <code className="language-javascript">
                              // Model export default mongoose.model(collection,
                              schema, collection)
                            </code>
                          </pre>
                        </div>
                        <h2 className="section-head1" ref={sectionRefs.freedom}>
                          Freedom <span className="color-green">we offer</span>{" "}
                          to Developers{" "}
                          <img
                            src="/landing_images/stories/bird.svg"
                            alt="freedom"
                          />{" "}
                          <img
                            className="freedomSec__curvy"
                            src="/landing_images/freedom/spiral.svg"
                            alt="freedom"
                          />{" "}
                        </h2>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="freedomSec__box freedomSec__box-1 d-flex">
                        <div className="flex-shrink-0">
                          <img
                            src="/landing_images/freedom/thumb1.svg"
                            alt="thumb1"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h3>API Documentation</h3>
                          <p>
                            Docur’s clear and comprehensive guide empowers
                            developers for an efficient integration process.
                          </p>
                        </div>
                      </div>

                      <div className="freedomSec__box freedomSec__box-2 d-flex">
                        <div className="flex-shrink-0">
                          <img
                            src="/landing_images/freedom/thumb2.svg"
                            alt="thumb2"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h3>Base URL of API</h3>
                          <p>
                            The standardised endpoint structure ensures easy
                            access and interaction with the API.
                          </p>
                        </div>
                      </div>

                      <div className="freedomSec__box freedomSec__box-3 d-flex">
                        <div className="flex-shrink-0">
                          <img
                            src="/landing_images/freedom/thumb3.svg"
                            alt="thumb3"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h3>Project Flow Diagram</h3>
                          <p>
                            Docur’s project flow diagram illustrates how to
                            integrate our API into your system.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <img
                    className="freedomSec__star anim-zoom-in"
                    src="/landing_images/freedom/star1.svg"
                    alt="star1"
                  />
                </div>
              </section>
            </div>

            <section className="commitSec section" ref={sectionRefs.commitment}>
              <div className="commitSec__cloudWrap">
                <img
                  className="commitSec__cloud anim-to-right"
                  src="/landing_images/commit/deco0.svg"
                  alt="deco0"
                />
              </div>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-7">
                    {/* <h2 className="section-head1">
                      Commitments{" "}
                      <img
                        src="/landing_images/commit/handshake.svg"
                        alt="handshake"
                      />{" "}
                      we are <span className="color-green">proud</span> of!
                    </h2> */}
                    <h2 className="section-head1">
                      Our <span className="color-green">Commitments</span>
                      <img
                        src="/landing_images/commit/handshake.svg"
                        alt="handshake"
                      />{" "}
                    </h2>
                    <p className="section-desc">
                      Our unique process sets us apart and delivers the best
                      results.
                    </p>
                  </div>
                  <div className="col-md-5">
                    <img
                      className="commitSec__deco1"
                      src="/landing_images/commit/deco1.svg"
                      alt="deco1"
                    />
                  </div>
                </div>
                <div className="commitSec__deco2-wrap">
                  <img
                    className="commitSec__deco2 anim-to-right"
                    src="/landing_images/commit/deco2.svg"
                    alt="deco2"
                  />
                </div>
                <div className="row commitSec__row">
                  <div className="col-md-5">
                    <div className="commitSec__box commitSec__box-1">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/landing_images/commit/thumb1.svg"
                            alt="thumb1"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h3>Document Sorting and Filtering</h3>
                        </div>
                      </div>
                      <p>
                        Organise and access your documents with our advanced
                        sorting and filtration features.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="commitSec__box commitSec__box-2">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/landing_images/commit/thumb2.svg"
                            alt="thumb2"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h3>User Authentication and Authorisation</h3>
                        </div>
                      </div>
                      <p>
                        We ensure secure access to data through our strong user
                        authentication and authorisation mechanisms.
                      </p>
                      <p>
                        <b>
                          Revolutionize your document management with our
                          commitment to security and efficiency!
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row commitSec__row">
                  <div className="col-md-7">
                    <div className="commitSec__box commitSec__box-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/landing_images/commit/thumb3.svg"
                            alt="thumb3"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h3>Webhooks and Real-Time Updates</h3>
                        </div>
                      </div>
                      <p>
                        Receive instant notifications on any changes with
                        webhooks.
                      </p>
                      <p>
                        {/* <b>
                          Revolutionize your document management with our
                          commitment to security and efficiency!
                        </b> */}
                      </p>
                    </div>
                  </div>
                   <div className="col-md-5">
                    <div className="commitSec__box commitSec__box-4">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/landing_images/commit/thumb4.svg"
                            alt="thumb4"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h3>Sharing Activity Reports</h3>
                        </div>
                      </div>
                      <p>
                      Share instant reports with your team to keep everyone aligned and informed.
                      </p>
                    </div>
                  </div> 
                </div>
              </div>
            </section>

            {/* <section className="numberSec section">
              <img
                className="numberSec__deco1 anim-to-right"
                src="/landing_images/clients/deco1.svg"
                alt="deco1"
              />
              <img
                className="numberSec__deco2 anim-to-right"
                src="/landing_images/clients/deco3.svg"
                alt="deco3"
              />
              <img
                className="numberSec__deco3 anim-to-right"
                src="/landing_images/clients/deco4.svg"
                alt="deco4"
              />

              <div className="container">
                <h2 className="numberSec__title">
                  success{" "}
                  <img
                    className="numberSec__star"
                    src="/landing_images/number/star.svg"
                    alt="star"
                  />{" "}
                  is not just a number for us!
                </h2>

                <div className="numberSec__inner">
                  <div className="row">
                    <div className="col-3">
                      <div className="numberSec__single numberSec__single-1">
                        <div className="numberSec__count">
                          <span
                            className="numberSec__num animate-number"
                            data-number="300"
                          >
                            300
                          </span>
                          K+
                        </div>
                        <div className="numberSec__text">Lorem ipsum dolor</div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="numberSec__single numberSec__single-2">
                        <div className="numberSec__count">
                          <span
                            className="numberSec__num animate-number"
                            data-number="100"
                          >
                            100
                          </span>
                          K+
                        </div>
                        <div className="numberSec__text">Lorem ipsum dolor</div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="numberSec__single numberSec__single-3">
                        <div className="numberSec__count">
                          <span
                            className="numberSec__num animate-number"
                            data-number="100"
                          >
                            100
                          </span>
                          K+
                        </div>
                        <div className="numberSec__text">Lorem ipsum dolor</div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="numberSec__single numberSec__single-4">
                        <div className="numberSec__count">
                          <span
                            className="numberSec__num animate-number"
                            data-number="300"
                          >
                            300
                          </span>
                          K+
                        </div>
                        <div className="numberSec__text">Lorem ipsum dolor</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            <section className="devSec section">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <h2 className="section-head1">
                      Respecting
                      <img src="/landing_images/dev/specs.svg" alt="" /> Our
                      Developers
                    </h2>
                    <p className="section-desc">
                      We dedicate our hard work, creativity, and innovation to
                      your projects. Our team remains updated with the latest
                      document security solutions to ensure your assets are
                      protected.
                    </p>
                    <p className="section-greentxt">
                      Boost your development process with Docur’s customisable
                      document management security solutions.
                    </p>

                    <div className="row align-items-center">
                      <div className="col-6">
                        <div className="devSec__buttons">
                          <Link
                            className="button button-dark"
                            to="#api"
                            onClick={() => scrollToSection("api")}
                          >
                            let's go to api
                          </Link>
                        </div>
                        <div className="devSec__deco4-wrapper">
                          <img
                            className="devSec__deco4 anim-click-arrow"
                            src="/landing_images/dev/deco1.svg"
                            alt="deco1"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <img
                          className="devSec__deco1"
                          src="/landing_images/dev/deco2.svg"
                          alt="decoration"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <img
                      className="devSec__img1"
                      src="/landing_images/dev/img1.svg"
                      alt="img1"
                    />
                  </div>
                </div>
              </div>

              <img
                className="devSec__deco2 anim-zoom-in"
                src="/landing_images/dev/star.svg"
                alt="star"
              />
            </section>

            <section className="verifySec section">
              <div className="container">
                <div className="verifySec__inner">
                  <div>
                    <img
                      className="verifySec__deco1"
                      src="/landing_images/verify/deco1.svg"
                      alt="deco"
                    />
                  </div>
                  <h2 className="section-head1">
                    Need verification of your Files
                  </h2>
                  <p className="section-desc">
                    Providing a quantum-proof secured cloud environment for
                    document management and record-keeping engine.
                  </p>
                  <div className="verifySec__buttons">
                    <Link className="w-100 button button-green" to="/signUp">
                      SIGN UP NOW
                    </Link>
                    {/* <Link className="button button-transparent" to="/signIn">VERIFY DOCS</Link> */}
                  </div>
                  <div className="verifySec__deco3-wrapper">
                    <img
                      className="verifySec__deco3 anim-click-arrow"
                      src="/landing_images/verify/deco2.svg"
                      alt="deco2"
                    />
                  </div>
                  <img
                    className="verifySec__deco2 anim-zoom-in"
                    src="/landing_images/footer/star.svg"
                    alt="star"
                  />
                </div>
              </div>
            </section>

            <section className="howSec section" ref={sectionRefs.workflow}>
              <div className="container">
                <img
                  className="howSec__star2 anim-zoom-in"
                  src="/landing_images/how/star2.svg"
                  alt="deco"
                />
                <div className="howSec__clock-wrap">
                  <img
                    className="howSec__clock"
                    src="/landing_images/how/clock.svg"
                    alt="deco"
                  />
                </div>
                <h2 className="section-head1">
                  Our Commitment to <span className="color-green">On-Time</span>{" "}
                  Delivery
                </h2>
                <div className="howSec__buttons">
                  <Link className="button button-dark" to="/signIn">
                    START VERIFICATION
                  </Link>
                </div>

                <div className="row howSec__row1">
                  <div className="col-md-6">
                    <div className="howSec__box howSec__box-1">
                      <img
                        className="howSec__img1"
                        src="/landing_images/how/img1.svg"
                        alt="deco"
                      />
                      <div className="howSec__text">
                        <h3>Upload Document</h3>
                        <p>Upload your document to the Docur portal.</p>
                      </div>
                      <img
                        className="howSec__arrow1"
                        src="/landing_images/how/arrow1.svg"
                        alt="arrow"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="howSec__box howSec__box-2">
                      <img
                        className="howSec__img2"
                        src="/landing_images/how/img2.svg"
                        alt="deco"
                      />
                      <div className="howSec__text">
                        <h3>Verify Document</h3>
                        <p>
                          Our system verifies your document for accuracy and
                          compliance, making it immutable.
                        </p>
                      </div>
                      <img
                        className="howSec__arrow2"
                        src="/landing_images/how/arrow2.svg"
                        alt="arrow"
                      />
                    </div>
                  </div>
                </div>

                <div className="row howSec__row2">
                  <div className="col-md-6">
                    <div className="howSec__box howSec__box-3">
                      <img
                        className="howSec__img3"
                        src="/landing_images/how/img3.svg"
                        alt="deco"
                      />
                      <div className="howSec__text">
                        <h3>Share quick links</h3>
                        <p>
                          You can instantly generate and share quick links for
                          easy access and distribution.
                        </p>
                      </div>
                      <img
                        className="howSec__arrow3"
                        src="/landing_images/how/arrow3.svg"
                        alt="arrow"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="howSec__box howSec__box-4">
                      <img
                        className="howSec__img4"
                        src="/landing_images/how/img4.svg"
                        alt="deco"
                      />
                      <div className="howSec__text">
                        <h3>Document Access Control</h3>
                        <p>
                          Our access control system allows you to precisely
                          control who can view or edit documents.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="servicesSec section">
              <img
                className="servicesSec__deco3 anim-to-right"
                src="/landing_images/give/deco3.svg"
                alt="deco3"
              />

              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <h2 className="section-head1">
                      Services We
                      <span className="color-green"> Offer</span> to{" "}
                      <img src="/landing_images/services/flag.svg" alt="" />{" "}
                      Assist You Better
                    </h2>
                    <p className="section-desc">
                      Our services go beyond securing uploaded documents; we
                      also protect them from forgery. We extend our offerings to
                      include document sorting, filtering, real-time updates,
                      and comprehensive user authentication.
                    </p>
                    <div className="servicesSec__buttons">
                      <Link className="button button-dark" to="/signIn">
                        Avail services
                      </Link>
                    </div>
                    <div className="servicesSec__deco4-wrapper">
                      <img
                        className="servicesSec__deco4 anim-click-arrow"
                        src="/landing_images/hero/deco4.svg"
                        alt="deco4"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 position-relative">
                    <div className="servicesSec__blocks">
                      <div className="servicesSec__block-row">
                        <div className="servicesSec__block servicesSec__block-1">
                          <img
                            className="servicesSec__block-bg"
                            src="/landing_images/services/block1-bg.svg"
                            alt="block1-bg"
                          />
                          <div className="servicesSec__block-text">
                            API DOCUMENTATION
                          </div>
                          {/* <div className="servicesSec__tooltip servicesSec__tooltip-left">
                            <div className="servicesSec__tooltip-text">
                              Lorem Ipsum
                            </div>
                            <div className="servicesSec__tooltip-number">
                              <span>300</span>K+
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="servicesSec__block-row">
                        <div className="servicesSec__block servicesSec__block-2">
                          <img
                            className="servicesSec__block-bg"
                            src="/landing_images/services/block2-bg.svg"
                            alt="bg"
                          />
                          <div className="servicesSec__block-text">
                            DOCUMENT CUSTOM SHARING
                          </div>
                          {/* <div className="servicesSec__tooltip servicesSec__tooltip-right">
                            <div className="servicesSec__tooltip-text">
                              Lorem Ipsum
                            </div>
                            <div className="servicesSec__tooltip-number">
                              <span>300</span>K+
                            </div>
                          </div> */}
                        </div>

                        <div className="servicesSec__block servicesSec__block-3">
                          <img
                            className="servicesSec__block-bg"
                            src="/landing_images/services/block3-bg.svg"
                            alt="bg"
                          />
                          <div className="servicesSec__block-text">
                            DOCUMENT LEGAL VERIFICATION
                          </div>
                          {/* <div className="servicesSec__tooltip servicesSec__tooltip-left">
                            <div className="servicesSec__tooltip-text">
                              Lorem Ipsum
                            </div>
                            <div className="servicesSec__tooltip-number">
                              <span>300</span>K+
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="servicesSec__block-row">
                        <div className="servicesSec__block servicesSec__block-4">
                          <img
                            className="servicesSec__block-bg"
                            src="/landing_images/services/block4-bg.svg"
                            alt="bg"
                          />
                          <div className="servicesSec__block-text">
                            REPORT QUICK GENERATION
                          </div>
                          {/* <div className="servicesSec__tooltip servicesSec__tooltip-right">
                            <div className="servicesSec__tooltip-text">
                              Lorem Ipsum
                            </div>
                            <div className="servicesSec__tooltip-number">
                              <span>300</span>K+
                            </div>
                          </div> */}
                        </div>
                        <div className="servicesSec__block servicesSec__block-5">
                          <img
                            className="servicesSec__block-bg"
                            src="/landing_images/services/block5-bg.svg"
                            alt="bg"
                          />
                          <div className="servicesSec__block-text">
                            DEGREES ATTESTATION
                          </div>
                          {/* <div className="servicesSec__tooltip servicesSec__tooltip-right">
                            <div className="servicesSec__tooltip-text">
                              Lorem Ipsum
                            </div>
                            <div className="servicesSec__tooltip-number">
                              <span>300</span>K+
                            </div>
                          </div> */}
                        </div>
                        <div className="servicesSec__block servicesSec__block-6">
                          <img
                            className="servicesSec__block-bg"
                            src="/landing_images/services/block6-bg.svg"
                            alt="bg"
                          />
                          <div className="servicesSec__block-text">
                            CERTIFICATES AUTHENTICATION
                          </div>
                          {/* <div className="servicesSec__tooltip servicesSec__tooltip-left">
                            <div className="servicesSec__tooltip-text">
                              Lorem Ipsum
                            </div>
                            <div className="servicesSec__tooltip-number">
                              <span>300</span>K+
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="contactSec section" ref={sectionRefs.contact}>
              <div className="position-relative container">
                <img
                  className="contactSec__star anim-zoom-in"
                  src="/landing_images/freedom/star1.svg"
                  alt="star1"
                />

                <div className="contactSec__inner">
                  <div className="contactSec__left">
                    <img
                      className="contactSec__copy-deco anim-zoom-in"
                      src="/landing_images/footer/star.svg"
                      alt="deco"
                    />

                    <h3 className="ff2 text-capitalize text-center">
                      Fill out the form so we me contact you about your query.
                    </h3>

                    <form className="contactSec__form">
                      <div className="contactSec__form--row">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="firstName"
                            value={contactForm.firstName}
                            onChange={handleContactForm}
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="lastName"
                            value={contactForm.lastName}
                            onChange={handleContactForm}
                          />
                        </div>
                      </div>
                      <div className="contactSec__form--row">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Your Email Address"
                            name="email"
                            value={contactForm.email}
                            onChange={handleContactForm}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Your Contact Number"
                            name="phone"
                            value={contactForm.phone}
                            onChange={handleContactForm}
                          />
                        </div>
                      </div>

                      <div className="contactSec__form--row">
                        <div className="mw-100 flex-1 form-group">
                          <textarea
                            className="form-control"
                            placeholder="Briefly tell about your question Below"
                            name="message"
                            value={contactForm.message}
                            onChange={handleContactForm}
                            required
                          ></textarea>
                          <div className="mt-3 fst-italic text-capitalize greenThemeText">
                            Start writing your message here
                          </div>
                        </div>
                      </div>

                      <div className="btn-row">
                        <hr />
                        <button
                          className="rounded-0 greenThemeText fz12 text-uppercase fw600 btn btn-white"
                          onClick={submitRequest}
                        >
                          submit my query
                        </button>

                        <img
                          className="contactSec__deco4 anim-click-arrow"
                          src="/landing_images/hero/deco4.svg"
                          alt="deco4"
                        />
                      </div>
                    </form>
                  </div>

                  <div className="contactSec__right">
                    <div className="">
                      <img
                        className="contactSec__plane"
                        src="/landing_images/contact/hand-paper-plane.svg"
                        alt="plane in a hand"
                      />

                      <h2 className="section-head1">
                        Want to&nbsp;
                        <span className="color-green">Contact</span> Us?
                      </h2>
                    </div>
                    <p className="section-desc">
                      We are eager to assist you with any support or questions.
                    </p>

                    <div className="contactSec__outer">
                      <div className="contactSec__pair">
                        <p className="mb-1 section-greentxt">Contact</p>
                        <p className="section-desc" style={{textTransform:"none"}}>info@digilion.in</p>
                      </div>
                      <br />
                      <div className="contactSec__pair">
                        <p className="mb-1 section-greentxt">Based in</p>
                        <p className="section-desc">India, Singapore, USA</p>
                      </div>
                    </div>

                    <br />
                    <p className="mb-2 text-capitalize text-orange">
                      nice to follow
                    </p>

                    <ul className="mb-0 list-inline contactSec__social">
                      {/* <li>
                        <a href="https://twitter.com/DIGILION_Tech">
                          <img
                            src="/landing_images/footer/icon-fb.svg"
                            alt="Facebook"
                          />
                        </a>
                      </li> */}
                      <li>
                        <Link to="https://www.instagram.com/digilion_tech/" target="_blank">
                          <img
                            src="/landing_images/footer/icon-in.svg"
                            alt="Instagram"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://twitter.com/DIGILION_Tech" target="_blank">
                          <img
                            src="/landing_images/footer/icon-tel.svg"
                            alt="Telegram"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://in.linkedin.com/company/digilion-technologies" target="_blank">
                          <img
                            src="/landing_images/footer/icon-li.svg"
                            alt="LinkedIn"
                           
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section className="giveSec section" ref={sectionRefs.happiness}>
              <div className="container">
                <h2 className="section-head1">
                  Features <span className="color-green">Loved</span> by
                  <img src="/landing_images/give/happy.svg" alt="happy" />
                  Our Users
                </h2>
                {/* <p className="section-desc">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt.
                </p> */}
                {/* <p className="section-greentxt">Lorem ipsum dolor sit amet,</p> */}

                <div className="row">
                  <div className="col-lg-8 position-relative">
                    <img
                      className="giveSec__bg"
                      src="/landing_images/give/bg.png"
                      alt="bg"
                    />
                    <div className="giveSec__content">
                      <img
                        className="giveSec__code"
                        src="/landing_images/give/code.svg"
                        alt="code"
                      />
                      <h3 className="giveSec__head1">
                        The dashboard deign will display here
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="giveSec__box giveSec__box-1 d-flex">
                      <div className="flex-shrink-0">
                        <img
                          src="/landing_images/give/thumb1.svg"
                          alt="thumb1"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h3>Robust Document Verification</h3>
                        <p>
                          Our system thoroughly verifies documents, ensuring
                          their authenticity.
                        </p>
                      </div>
                    </div>

                    <div className="giveSec__box giveSec__box-2 d-flex">
                      <div className="flex-shrink-0">
                        <img
                          src="/landing_images/give/thumb2.svg"
                          alt="thumb2"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h3>Report Analytics</h3>
                        <p>
                          Gain valuable insights with our detailed analysis and
                          document visualisation tools.
                        </p>
                      </div>
                    </div>

                    <div className="giveSec__box giveSec__box-3 d-flex">
                      <div className="flex-shrink-0">
                        <img
                          src="/landing_images/give/thumb3.svg"
                          alt="thumb3"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h3> Zero-Knowledge Proof</h3>
                        <p>
                          Verify confidential information without revealing
                          sensitive data, thanks to our user-friendly interface.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <img
                  className="giveSec__star anim-zoom-in"
                  src="/landing_images/freedom/star1.svg"
                  alt="star1"
                />
                <img
                  className="giveSec__deco2"
                  src="/landing_images/give/deco2.svg"
                  alt="deco2"
                />
                <img
                  className="giveSec__deco3 anim-to-right"
                  src="/landing_images/give/deco3.svg"
                  alt="deco3"
                />
              </div>
            </section>

            <section className="happySec section">
              <img
                className="clientsSec__deco1 anim-to-right"
                src="/landing_images/clients/deco1.svg"
                alt="deco1"
              />
              <img
                className="happySec__deco2 anim-to-right"
                src="/landing_images/happy/deco2.svg"
                alt="deco2"
              />
              <img
                className="clientsSec__deco3 anim-to-right"
                src="/landing_images/clients/deco3.svg"
                alt="deco3"
              />
              <img
                className="clientsSec__deco4 anim-to-right"
                src="/landing_images/clients/deco4.svg"
                alt="deco4"
              />

              <div className="container">
                <img
                  className="happySec__img1"
                  src="/landing_images/happy/tools.svg"
                  alt="tools"
                />
                <p className="section-desc">
                  a controlled eco-system for Record Storage and Verification
                </p>
                <h2 className="section-head1">
                  happy to provides a unique{" "}
                  <span className="color-green">controlled</span> solution for
                  you!
                </h2>
              </div>
            </section>

            <footer className="footerSec section">
              <div className="container">
                <div className="footerSec__inner">
                  <div className="row">
                    <div className="col-md-6 col-lg-7 col-xl-8">
                      <Link className="footerSec__logo" to="">
                        <img src="/landing_images/logo-footer.svg" alt="logo" />
                      </Link>
                      <p className="footerSec__desc">
                        providing a quantum-proof secured cloud environment for
                        document management and record-keeping engine.
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-4 d-none d-md-block">
                      <div className="footerSec__follow">
                        <span className="me-3">Nice to follow</span>

                        {/* <Link to="">
                          <img
                            src="/landing_images/footer/icon-fb.svg"
                            alt="Facebook"
                          />
                        </Link> */}
                        <Link to="https://www.instagram.com/digilion_tech/" target="_blank">
                          <img
                            src="/landing_images/footer/icon-in.svg"
                            alt="Instagram"
                          />
                        </Link>
                        <Link to="https://twitter.com/DIGILION_Tech" target="_blank">
                          <img
                            src="/landing_images/footer/icon-tel.svg"
                            alt="Telegram"
                          />
                        </Link>
                        <Link to="https://in.linkedin.com/company/digilion-technologies" target="_blank">
                          <img
                            src="/landing_images/footer/icon-li.svg"
                            alt="Linkedin"
                          />
                        </Link>
                      </div>
                      <div className="footerSec__buttons">
                        <Link className="button button-green" to="/signIn">
                          SIGN UP NOW
                        </Link>
                        <Link
                          className="button button-transparent"
                          to="/signIn"
                        >
                          VERIFY DOCS
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 col-md-4">
                      <ul>
                        <li>
                          <Link  className={activeLink === "home" ? "active" : ""}
                    to="#home"
                    onClick={() => scrollToSection("home")}>HOME</Link>
                        </li>
                        <li>
                          <Link className={activeLink === "freedom" ? "active" : ""}
                    to="#freedom"
                    onClick={() => scrollToSection("freedom")}>Freedom</Link>
                        </li>
                        <li>
                          <Link className={activeLink === "commitment" ? "active" : ""}
                    to="#commitment"
                    onClick={() => scrollToSection("commitment")}>Commitments</Link>
                        </li>
                        <li>
                          <Link className={activeLink === "workflow" ? "active" : ""}
                    to="#workflow"
                    onClick={() => scrollToSection("workflow")}>Work flow</Link>
                        </li>
                        <li>
                          <Link  className={activeLink === "happiness" ? "active" : ""}
                    to="#happiness"
                    onClick={() => scrollToSection("happiness")}>happiness</Link>
                        </li>
                        <li>
                          <Link className={activeLink === "contact" ? "active" : ""}
                    to="#contact"
                    onClick={() => scrollToSection("contact")}>Contact us</Link>
                        </li>
                      </ul>
                    </div>

                    
                     <div className="col-6 col-md-4">
                      <ul>
                        <li>
                          <Link to="/terms-and-conditions">Terms and conditions</Link>
                        </li>
                        <li>
                          <Link to="/privacy"> Privacy Policy</Link>
                        </li>
                      </ul>
                    </div>

                    {/* <div className="col-6 col-md-4">
                      <ul>
                        <li>
                          <h3>verification</h3>
                        </li>
                        <li>
                          <Link to="">document verification</Link>
                        </li>
                        <li>
                          <Link to="">id and passport verification</Link>
                        </li>
                        <li>
                          <Link to="">govt doc authentication</Link>
                        </li>
                        <li>
                          <Link to="">degree and legal certificates</Link>
                        </li>
                      </ul>
                    </div> */}

                    {/* <div className="col-6 col-md-4">
                      <ul>
                        <li>
                          <h3>Quick Links</h3>
                        </li>
                        <li>
                          <Link to="">api documentation</Link>
                        </li>
                        <li>
                          <Link to="">github</Link>
                        </li>
                        <li>
                          <Link to="">prices and resources</Link>
                        </li>
                        <li>
                          <Link to="">libraries</Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>

                  <div className="footerSec__botMob col-md-4 d-md-none">
                    <div className="footerSec__follow">
                      <span className="me-3">Nice to follow</span>

                      {/* <Link to="">
                        <img
                          src="/landing_images/footer/icon-fb.svg"
                          alt="Facebook"
                        />
                      </Link> */}
                      <Link to="https://www.instagram.com/digilion_tech/" target="_blank">
                        <img
                          src="/landing_images/footer/icon-in.svg"
                          alt="Instagram"
                        />
                      </Link>
                      <Link to="https://www.instagram.com/digilion_tech/" target="_blank">
                        <img
                          src="/landing_images/footer/icon-tel.svg"
                          alt="Telegram"
                        />
                      </Link>
                      <Link to="https://in.linkedin.com/company/digilion-technologies" target="_blank">
                        <img
                          src="/landing_images/footer/icon-li.svg"
                          alt="Linkedin"
                        />
                      </Link>
                    </div>
                    <div className="footerSec__buttons">
                      <Link className="button button-green" to="/signIn">
                        SIGN UP NOW
                      </Link>
                      <Link className="button button-transparent" to="/signIn">
                        VERIFY DOCS
                      </Link>
                    </div>
                  </div>

                  <div className="footerSec__copy">
                    copyright 2023- docur-
                    <img
                      className="footerSec__copy-deco"
                      src="/landing_images/footer/star.svg"
                      alt="deco"
                    />
                  </div>
                </div>
              </div>
            </footer>
            {/* </div> */}
            {/* <!-- .landing --> */}
          </div>
          {/* <!-- #smooth-content  --> */}
        </div>
        {/* <!-- #smooth-wrapper --> */}
      </div>
    </>
  );
}
