import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets_landing/styles/landing.css";
import { useDispatch } from "react-redux";
import { contactUsRequest } from "../../redux/Landing/lan.action";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { POST_CONTACT_US } from "../../services/Api";

export default function LandingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeLink, setActiveLink] = useState("");
  const [contactForm, setContactForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: ""
  })

  useEffect(() => {
    let script = document.createElement("script");
    script.id = "scriptId";
    script.src = "./assets_landing/scripts/landing.js";
    document.body.append(script);
  }, []);

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  const sectionRefs = {
    freedom: useRef(null),
    commitment: useRef(null),
    workflow: useRef(null),
    happiness: useRef(null),
    api: useRef(null),
    home: useRef(null),
    contact: useRef(null),
  };

  const scrollToSection = (section) => {
    setActiveLink(section);
    const ref = sectionRefs[section];
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleContactForm = (e) => {
    const { name, value } = e.target;
    setContactForm({ ...contactForm, [name]: value })
  }

  const submitRequest = (e) => {
    e.preventDefault();
    if (!contactForm.firstName) {
      toast.error("Please enter your first name", {
        autoClose: 1000,
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d+$/;
    if (!phoneRegex.test(contactForm.phone)) {
      toast.error("Please enter a valid phone number", {
        autoClose: 1000,
      });
      return;
    }

    if (!emailRegex.test(contactForm.email)) {
      toast.error("Please enter a valid email address", {
        autoClose: 1000,
      });
      return;
    }

    if (!contactForm.message) {
      toast.error("Please type your query", {
        autoClose: 1000,
      });
      return;
    }

    // const payLoad = {
    //   name: contactForm.firstName + " " + contactForm.lastName,
    //   email: contactForm.email,
    //   phone: contactForm.phone,
    //   subject: 'Contact',
    //   app_name: 'docur',
    //   message: contactForm.message,
    //   extra_data: null,
    // }

    const payLoad = {
      firstName: contactForm.firstName,
      lastName: contactForm.lastName,
      phone: contactForm.phone,
      email: contactForm.email,
      message: contactForm.message
    }
   
    axios.post(POST_CONTACT_US, payLoad).then(resp => {
      if(resp){
        navigate("/thankyou")
        setContactForm({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          message: ''
        });
      }
   
    }).catch(err => {
      toast.error("Please Try Again After Sometime...")
      console.log(err);
    })

  }

  return (
    <>
      <header className="headerSec">
        <div className="container">
          <nav>
            <a href="/" className="headerSec__logo">
              <img
                className="replaceWithSVG"
                src="/landing_images/logo.svg"
                alt="logo"
              />
            </a>

            <div className="techBorder pt-0 d-none d-lg-block">
              <span className="techBorder__leftTop"></span>
              <span className="techBorder__leftBot"></span>
              <span className="techBorder__leftDot"></span>
              <span className="techBorder__rightTop"></span>
              <span className="techBorder__rightBot"></span>
              <span className="techBorder__rightDot"></span>
              <ul>
                <li>
                  <Link
                    className={activeLink === "home" ? "active" : ""}
                    to="/#home"
                    onClick={() => scrollToSection("home")}
                  >
                    HOME
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "freedom" ? "active" : ""}
                    to="/#freedom"
                    onClick={() => scrollToSection("freedom")}
                  >
                    FREEDOM
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "commitment" ? "active" : ""}
                    to="/#commitment"
                    onClick={() => scrollToSection("commitment")}
                  >
                    COMMITMENTS
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "workflow" ? "active" : ""}
                    to="#workflow"
                    onClick={() => scrollToSection("workflow")}
                  >
                    WORK FLOW
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "happiness" ? "active" : ""}
                    to="#happiness"
                    onClick={() => scrollToSection("happiness")}
                  >
                    HAPPINESS
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "api" ? "active" : ""}
                    to="#api"
                    onClick={() => scrollToSection("api")}
                  >
                    API
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "contact" ? "active" : ""}
                    to="#contact"
                    onClick={() => scrollToSection("contact")}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
              <ToastContainer />
            </div>

            <div className="headerSec__buttons">
              <Link
                className="headerSec__buttons--green d-none d-lg-flex"
                to="/signIn"
              >
                LOGIN IN
              </Link>
              {/* <Link className="d-none d-lg-flex" to="/signIn">VERIFY NOW</Link> */}
              <Link
                className="d-lg-none menu-btn menu-btn--hamburger me-3"
              // to="javascript:;"
              >
                <img
                  className="menu-btn__icon1"
                  src="/landing_images/menu-btn.svg"
                  alt="menu"
                />
              </Link>
            </div>
          </nav>
        </div>
      </header>

      <aside className="mt-0 menuSec">
        <div className="menuSec__inner">
          <div className="menuSec__head">
            <span></span>
            <a
              className="d-lg-none menu-btn menu-btn--cross me-1"
            // to="javascript:;"
            >
              <img
                className="menu-btn__icon1"
                src="/landing_images/menu-close.svg"
                alt="menu close"
              />
            </a>
          </div>
          <div className="menuSec__deco1">
            <img
              className=""
              src="/landing_images/menu-top.svg"
              alt="menu-top"
            />
          </div>
          <div className="menuSec__logo-wrap">
            <a className="menuSec__head-logo">
              <img src="/landing_images/logo-white.svg" alt="logo" />
            </a>

            <div className="menuSec__head-desc">
              providing a quantum-proof secured cloud environment for document
              management and record-keeping engine.
            </div>
          </div>

          <div className="menuSec__links">
            <div className="row">
              <div className="col">
                <ul>
                  <li>
                    <Link to="#home" className="active">
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to="#freedom">FREEDOM</Link>
                  </li>
                  <li>
                    <Link to="#commitments">COMMITMENTS</Link>
                  </li>
                  <li>
                    <Link to="#workflow">WORK FLOW</Link>
                  </li>
                  <li>
                    <Link to="#happiness">HAPPINESS</Link>
                  </li>
                  <li>
                    <Link to="#contact">Contact us</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul>
                  <li>
                    <Link className="active" hretof="#home">
                      quick links
                    </Link>
                  </li>
                  <li>
                    <Link to="#api">api documentation</Link>
                  </li>
                  <li>
                    <Link to="">github</Link>
                  </li>
                  <li>
                    <Link to="">prices and resources</Link>
                  </li>
                  <li>
                    <Link to="">libraries</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="menuSec__footer">
            <div className="menuSec__footer-hands">
              <img
                src="landing_images/menu/shake-hands.svg"
                alt="shake-hands"
              />
            </div>
            <h2 className="menuSec__footer-title">Join hands with us!</h2>
            <div className="menuSec__footer-follow">
              <Link to="">
                <img src="landing_images/footer/icon-fb.svg" alt="Facebook" />
              </Link>
              <Link to="">
                <img src="landing_images/footer/icon-in.svg" alt="Instagram" />
              </Link>
              <Link to="">
                <img src="landing_images/footer/icon-tel.svg" alt="Telegram" />
              </Link>
              <Link to="">
                <img src="landing_images/footer/icon-li.svg" alt="Linkedin" />
              </Link>
            </div>

            <div className="menuSec__footer-btns">
              <div className="menuSec__buttons">
                <Link className="menuSec__buttons--green" to="">
                  SIGN UP NOW
                </Link>
                <Link className="" to="">
                  VERIFY DOCS
                </Link>
              </div>
            </div>
          </div>
        </div>
      </aside>

      <div id="smooth-wrapper">
        <div id="smooth-content" className="position-relative">
          <div className="landing">

            <section style={{ paddingTop: "calc(150px* var(--resp))" }} className="contactSec section" ref={sectionRefs.contact}>
              <div className="position-relative container">
                <img
                  className="contactSec__star anim-zoom-in"
                  src="/landing_images/freedom/star1.svg"
                  alt="star1"
                />

                <div className="contactSec__inner">
                  <div className="contactSec__left">
                    <img
                      className="contactSec__copy-deco anim-zoom-in"
                      src="/landing_images/footer/star.svg"
                      alt="deco"
                    />

                    <h3 className="ff2 text-capitalize text-center">
                      Contact us now to safeguard your valuable information.
                    </h3>

                    <form className="contactSec__form">
                      <div className="contactSec__form--row">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="firstName"
                            value={contactForm.firstName}
                            onChange={handleContactForm}
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="lastName"
                            value={contactForm.lastName}
                            onChange={handleContactForm}
                          />
                        </div>
                      </div>
                      <div className="contactSec__form--row">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Your Email Address"
                            name="email"
                            value={contactForm.email}
                            onChange={handleContactForm}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Your Contact Number"
                            name="phone"
                            value={contactForm.phone}
                            onChange={handleContactForm}
                          />
                        </div>
                      </div>

                      <div className="contactSec__form--row">
                        <div className="mw-100 flex-1 form-group">
                          <textarea
                            className="form-control"
                            placeholder="Briefly tell about your question Below"
                            name="message"
                            value={contactForm.message}
                            onChange={handleContactForm}
                            required
                          ></textarea>
                          <div className="mt-3 fst-italic text-capitalize greenThemeText">
                            Start writing your message here
                          </div>
                        </div>
                      </div>

                      <div className="btn-row">
                        <hr />
                        <button className="rounded-0 greenThemeText fz12 text-uppercase fw600 btn btn-white" onClick={submitRequest}>
                          submit query
                        </button>

                        <img
                          className="contactSec__deco4 anim-click-arrow"
                          src="/landing_images/hero/deco4.svg"
                          alt="deco4"
                        />
                      </div>
                    </form>
                  </div>

                  <div className="contactSec__right">
                    <div className="">
                      <img
                        className="contactSec__plane"
                        src="/landing_images/contact/hand-paper-plane.svg"
                        alt="plane in a hand"
                      />

                      <h2 className="section-head1">
                        Secure Your Documents, Safeguard Your Future
                        {/* <span className="color-green">Contact</span> us? */}
                      </h2>
                    </div>
                    <p className="section-desc">
                      Docur offers a decentralized, quantum-proof cloud solution for document management and record-keeping. Say goodbye to limited auditability and enforcement challenges. Enjoy:
                    </p>

                    <p className="m-0">- Unparalleled auditability and transparency</p>
                    <p className="m-0">- Robust enforcement and compliance</p>
                    <p>- Mitigated risks and economic stability</p>

                    <p>Streamline record-keeping, identity management, and document repositories. Reduce forgery risks and increase efficiency.</p>

                    {/* <div className="contactSec__outer">
                      <div className="contactSec__pair">
                        <p className="mb-1 section-greentxt">Contact</p>
                        <p className="section-desc">dockers.com</p>
                      </div>
                      <br />
                      <div className="contactSec__pair">
                        <p className="mb-1 section-greentxt">Based in</p>
                        <p className="section-desc">
                          New York, <br /> California, Ohio
                        </p>
                      </div>
                    </div> */}

                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </section>

            <section className="clientsSec section" ref={sectionRefs.freedom}>
              <img
                className="clientsSec__deco1 anim-to-right"
                src="/landing_images/clients/deco1.svg"
                alt="deco1"
              />
              <img
                className="clientsSec__deco2 anim-to-right"
                src="/landing_images/clients/deco2.png"
                alt="deco2"
              />
              <img
                className="clientsSec__deco3 anim-to-right"
                src="/landing_images/clients/deco3.svg"
                alt="deco3"
              />
              <img
                className="clientsSec__deco4 anim-to-right"
                src="/landing_images/clients/deco4.svg"
                alt="deco4"
              />

              <div className="container">
                <h2 className="clientsSec__title">
                  We Share Amazing{" "}
                  <img
                    src="/landing_images/clients/bulb.svg"
                    alt="thoughts"
                  />{" "}
                  Thought And Happy Cookies{" "}
                  <img
                    src="/landing_images/clients/cookies.svg"
                    alt="cookies"
                  />
                  With
                </h2>

                <div className="clientsSec__all">
                  <div className="owl-carousel owl-theme">
                    <div className="item">
                      <div className="clientsSec__single">
                        <img
                          src="/landing_images/clients/client1.svg"
                          alt="client1"
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="clientsSec__single">
                        <img
                          src="/landing_images/clients/client2.svg"
                          alt="client2"
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="clientsSec__single">
                        <img
                          src="/landing_images/clients/client3.svg"
                          alt="client3"
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="clientsSec__single">
                        <img
                          src="/landing_images/clients/client4.svg"
                          alt="client4"
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="clientsSec__single">
                        <img
                          src="/landing_images/clients/client5.svg"
                          alt="client5"
                        />
                      </div>
                    </div>
                    <div className="item">
                      <div className="clientsSec__single">
                        <img
                          src="/landing_images/clients/client6.svg"
                          alt="client6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <footer className="footerSec section">
              <div className="container">
                <div className="footerSec__inner">
                  <div className="row">
                    <div className="col-md-6 col-lg-7 col-xl-8">
                      <Link className="footerSec__logo" to="">
                        <img src="/landing_images/logo-footer.svg" alt="logo" />
                      </Link>
                      <p className="footerSec__desc">
                        providing a quantum-proof secured cloud environment for
                        document management and record-keeping engine.
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-4 d-none d-md-block">
                      <div className="footerSec__follow">
                        <span className="me-3">Nice to follow</span>

                        <Link to="">
                          <img
                            src="/landing_images/footer/icon-fb.svg"
                            alt="Facebook"
                          />
                        </Link>
                        <Link to="">
                          <img
                            src="/landing_images/footer/icon-in.svg"
                            alt="Instagram"
                          />
                        </Link>
                        <Link to="">
                          <img
                            src="/landing_images/footer/icon-tel.svg"
                            alt="Telegram"
                          />
                        </Link>
                        <Link to="">
                          <img
                            src="/landing_images/footer/icon-li.svg"
                            alt="Linkedin"
                          />
                        </Link>
                      </div>
                      <div className="footerSec__buttons">
                        <Link className="button button-green" to="/signIn">
                          SIGN UP NOW
                        </Link>
                        <Link
                          className="button button-transparent"
                          to="/signIn"
                        >
                          VERIFY DOCS
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 col-md-4">
                      <ul>
                        <li>
                          <h3>HOME</h3>
                        </li>
                        <li>
                          <Link to="">Freedom</Link>
                        </li>
                        <li>
                          <Link to="">Commitments</Link>
                        </li>
                        <li>
                          <Link to="">Work flow</Link>
                        </li>
                        <li>
                          <Link to="">happiness</Link>
                        </li>
                        <li>
                          <Link to="">Contact us</Link>
                        </li>
                      </ul>
                    </div>

                    <div className="col-6 col-md-4">
                      <ul>
                        <li>
                          <h3>verification</h3>
                        </li>
                        <li>
                          <Link to="">document verification</Link>
                        </li>
                        <li>
                          <Link to="">id and passport verification</Link>
                        </li>
                        <li>
                          <Link to="">govt doc authentication</Link>
                        </li>
                        <li>
                          <Link to="">degree and legal certificates</Link>
                        </li>
                      </ul>
                    </div>

                    <div className="col-6 col-md-4">
                      <ul>
                        <li>
                          <h3>Quick Links</h3>
                        </li>
                        <li>
                          <Link to="">api documentation</Link>
                        </li>
                        <li>
                          <Link to="">github</Link>
                        </li>
                        <li>
                          <Link to="">prices and resources</Link>
                        </li>
                        <li>
                          <Link to="">libraries</Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="footerSec__botMob col-md-4 d-md-none">
                    <div className="footerSec__follow">
                      <span className="me-3">Nice to follow</span>

                      <Link to="">
                        <img
                          src="/landing_images/footer/icon-fb.svg"
                          alt="Facebook"
                        />
                      </Link>
                      <Link to="">
                        <img
                          src="/landing_images/footer/icon-in.svg"
                          alt="Instagram"
                        />
                      </Link>
                      <Link to="">
                        <img
                          src="/landing_images/footer/icon-tel.svg"
                          alt="Telegram"
                        />
                      </Link>
                      <Link to="">
                        <img
                          src="/landing_images/footer/icon-li.svg"
                          alt="Linkedin"
                        />
                      </Link>
                    </div>
                    <div className="footerSec__buttons">
                      <Link className="button button-green" to="/signIn">
                        SIGN UP NOW
                      </Link>
                      <Link className="button button-transparent" to="/signIn">
                        VERIFY DOCS
                      </Link>
                    </div>
                  </div>

                  <div className="footerSec__copy">
                    copyright 2023- docur-
                    <img
                      className="footerSec__copy-deco"
                      src="/landing_images/footer/star.svg"
                      alt="deco"
                    />
                  </div>
                </div>
              </div>
            </footer>
            {/* </div> */}
            {/* <!-- .landing --> */}
          </div>
          {/* <!-- #smooth-content  --> */}
        </div>
        {/* <!-- #smooth-wrapper --> */}
      </div>
    </>
  );
}
