import axios from "axios";
import { toast } from "react-toastify";
import { POST_CONTACT_US } from "../../services/Api";
import { QUERY_SEND_FAILURE, QUERY_SEND_SUCCESS } from "./lan.actionTypes";


export const contactUsRequest = (payLoad) => async (dispatch) => {
  try {
    const res = await axios.post(POST_CONTACT_US, payLoad);
    console.log("res", res)
    if (res.statusText === "OK" ) {
      toast.success("Request successfully sent",{
        autoClose: 1000,
      });
      dispatch({
        type: QUERY_SEND_SUCCESS,
        payload: res.data,
      });
    } else {
      toast.error("Please submit the request again", {
        autoClose: 1000,
      });
      dispatch({
        type: QUERY_SEND_FAILURE,
        payload: {
          message: res?.data?.msg,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: QUERY_SEND_FAILURE,
      payload: {
        message: error,
      },
    });
  }
};




