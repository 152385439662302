import React from "react";
import AuthFooterLeft from "../../layouts/authLayouts/authFooterLeft";

export default function HomePage() {
  return (
    <>
      <main className="w-100 auth-layout auth-layout--splash">
        <div className="container">
            <img src="/images/logos/logo-dark.svg" alt="" class="logo" />
 
            <div className="splash-inner">
              <div className="mx-auto sub-help-info">
                <div className="grayText top-red-bd-text">
                  <span>#WeAreProudWhatWeOffer</span>
                </div>
                <div className="grayText">
                  <span>#WeOfferFreedomToYou</span>
                </div>
                <div className="font-secondary fw600 lh-1 top-green-dark-bd-text">
                  <span>Need Help?</span>
                </div>
              </div>

              <div className="font-secondary fw700 lh-1 masked-text">
                <div className="greenThemeText">Docur-</div>
                <div className="auth-grad-text dark-theme-mix-text">
                  Decentralized
                </div>
                <div className="auth-grad-text dark-white-mix-text">
                  Hypermedia
                </div>
                <div className="auth-grad-text dark-white-mix-text">
                  Protocol
                </div>
                <a
                  href="https://docur.io/"
                  className="fz14 font-primary fw500 link-to-home"
                >
                  https://docur.io/
                </a>
                <div className="fz12 font-primary fw300 above-img-text">
                  Providing A Quantum-Proof <br />
                  Secured Cloud Environment
                </div>

                <img
                  className="main-bg"
                  src="/images/bg-particles/auth-theme-block-bg.svg"
                  alt="auth-theme-block-bg"
                />
                <img
                  src="/images/bg-particles/cross-dark-black.svg"
                  alt="cross-dark-black"
                  className="confetti cross-black"
                />
                <img
                  src="/images/bg-particles/cross-dark-green.svg"
                  alt="cross-dark-green"
                  className="confetti cross-green"
                />
                <img
                  src="/images/bg-particles/rhombus-line-red.svg"
                  alt="rhombus-line-red"
                  className="confetti rhombus-red"
                />
                <img
                  src="/images/bg-particles/rhombus-line-black.svg"
                  alt="rhombus-line-black.svg"
                  className="confetti rhombus-black"
                />
                <img
                  src="/images/bg-particles/long-red-line-wave.svg"
                  alt="long-red-line-wave"
                  className="confetti wave-red-long"
                />
                <img
                  src="/images/bg-particles/short-red-line-wave.svg"
                  alt="short-red-line-wave"
                  className="confetti wave-red-short"
                />
                <img
                  src="/images/bg-particles/short-dark-black-line.svg"
                  alt="short-dark-black-line"
                  className="confetti line-black-short"
                />
              </div>
            </div>

          <a className="shadow-34-05 bg-w-a49 blur-2 to-auth-btn">
            <span>
              <img
                src="/images/icons/icon-green-right-arrow.svg"
                alt="arrow-right"
              />
            </span>
          </a>
          <AuthFooterLeft />
        </div>
      </main>
    </>
  );
}
