import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import './assets/styles/inner-main.css'
import './assets_landing/styles/landing.css'
import "react-toastify/dist/ReactToastify.css";
import SignIn from './containers/authentication/signIn';
import SignInOtp from './containers/authentication/signInOtp';
import SignUp from './containers/authentication/signUp';
import SignUpOtp from './containers/authentication/signUpOtp';
import ProfileMainPage from './containers/profile';
import OrgBasicInformation from './containers/profile/organization/orgBasicInformation';
import OrgDetailedInformation from './containers/profile/organization/orgDetailedInformation';
import OrgDetailedInformation1 from './containers/profile/organization/orgDetailedInformation1';
import IndvBasicInformation from './containers/profile/individual/indvBasicInformation';
import IndvBasicInformation1 from './containers/profile/individual/indvBasicInformation1';
// import IndvDetailedInformation from './containers/profile/individual/indvDetailedInformation';
import OrgBasicInformation1 from './containers/profile/organization/orgBasicInformation1';
import YourInformation from './containers/profile/both/yourInformation';
import AboutYourJob from './containers/profile/both/aboutYourJob';
import CompanyInformation from './containers/profile/both/companyInformation';
import Summary from './containers/profile/both/summary';
import LandingPage from './containers/landing';
import BothPreview from './containers/profile/both/bothPreview';
import OrgPreview from './containers/profile/organization/orgPreview';
import Dashboard from './containers/dashboard';
import UploadDocument from './containers/dashboard/uploadDocument';
import DocumentPrivacy from './containers/dashboard/documentPrivacy';
import MyDocuments from './containers/documents';
import SetDocumentPrivacy from './containers/dashboard/setDocumentPrivacy';
import DocumentPreview from './containers/dashboard/documentPreview';
import SharedDocuments from './containers/shared_documents';
import HomePage from "./containers/homepage";
import MainPage from "./containers/main";
import ThankYouPage from "./containers/thankyou";
import Privacy from "./containers/footer/privacy";
import TermsAndConditions from "./containers/footer/termsAndConditions";

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Homepage */}
          <Route path="/home" element={<HomePage />} />

          {/* Main */}
          <Route path="/" element={<MainPage />} />
          
          {/* landing */}
          <Route path="/secure-your-documents" element={<LandingPage />} />

          {/* Thank You */}
          <Route path="/thankyou" element={<ThankYouPage />} />

          {/* Authentication */}
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/signIn-otp" element={<SignInOtp />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/signUp-otp" element={<SignUpOtp />} />
          <Route path="/signIn/:path/:path/:path/:path" element={<SignIn />} />
          <Route path="/signIn-otp/signIn/:path/:path/:path/:path" element={<SignInOtp />} />

          {/* Profile */}
          <Route path="/profile" element={<ProfileMainPage />} />
          <Route path="/Indv-basic-information" element={<IndvBasicInformation />} />
          <Route path="/Indv-basic-information1" element={<IndvBasicInformation1 />} />
          {/* <Route path="/Indv-detailed-information" element={<IndvDetailedInformation />} /> */}

          <Route path="/org-basic-information" element={<OrgBasicInformation />} />
          <Route path="/org-basic-information1" element={<OrgBasicInformation1 />} />
          <Route path="/org-detailed-information" element={<OrgDetailedInformation />} />
          <Route path="/org-detailed-information1" element={<OrgDetailedInformation1/>} />
          <Route path="/org-preview" element={<OrgPreview/>} />

          <Route path="/your-information" element={<YourInformation/>} />
          <Route path="/about-your-job" element={<AboutYourJob/>} />
          <Route path="/about-company" element={<CompanyInformation/>} />
          <Route path="/summary" element={<Summary/>} />
          <Route path="/both-preview" element={<BothPreview/>} />

          {/* Dashboard */}
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/upload-document" element={<UploadDocument/>} />
          <Route path="/document-privacy" element={<DocumentPrivacy/>} />
          <Route path="/set-document-privacy" element={<SetDocumentPrivacy/>} />
          <Route path="/document-preview" element={<DocumentPreview/>} />

          {/* Documents */}
          <Route path="/documents" element={<MyDocuments/>} />

          {/* Shared Documents */}
          <Route path="/shared-documents" element={<SharedDocuments/>} />

          {/* Footer */}
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
