import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopNavBar from "../../layouts/topNavBar";
import SideNavBar from "../../layouts/sideNavBar";
import { useDispatch, useSelector } from "react-redux";
import {
  allSharedDocuments,
  getAllDocuments,
} from "../../redux/Documents/doc.action";
import {
  convertTime,
  formatDate,
  formatHour,
  formatMinute,
  truncateString,
  truncateURL,
} from "../../services/utils";
import LineChart from "../../charts/lineChart";
import { HASH_URL, IMAGE_PROXY_URL } from "../../services/Api";

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const allDocuments = store?.DocumentsManager?.myDocuments;
  const sharedDocs = store?.DocumentsManager?.allSharedDocs;
  const [myDocs, setMyDocs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [allSharedDocs, setAllSharedDocs] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [chartForUploadedDocs, setChartForUploadedDocs] = useState([]);
  const [chartForSharedDocs, setChartForSharedDocs] = useState([]);

  useEffect(() => {
    dispatch(getAllDocuments());
    dispatch(allSharedDocuments());
  }, []);

  useEffect(() => {
    if (sharedDocs) {
      const reverseDocs = sharedDocs?.reverse();
      setAllSharedDocs(reverseDocs);
    }
  }, [sharedDocs]);

  useEffect(() => {
    localStorage.removeItem("userEmail1");
  }, []);

  useEffect(() => {
    if (allDocuments) {
      const docs = allDocuments?.reverse();
      setMyDocs(docs);
      setSearchResults(docs);
    }
  }, [allDocuments]);

  //Handle my documents
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const results = performSearch(value);
    setMyDocs(results);
  };

  function performSearch(value) {
    return searchResults?.filter((el) => el?.id?.toString()?.includes(value) || truncateURL(el?.file?.toLowerCase())?.includes(value.toLowerCase()));
  }

  //handle chart data
  useEffect(() => {
    if (allDocuments) {
      // To get total documents uploaded month wise
      const currentDate = new Date();
      const filteredArray = allDocuments.filter((obj) => {
        const documentDate = new Date(obj.created_at);
        return documentDate < currentDate;
      });

      const countsByMonth = {};
      filteredArray.forEach((obj) => {
        const month = new Date(obj.created_at).getMonth();
        countsByMonth[month] = (countsByMonth[month] || 0) + 1;
      });

      const dataArray = Array.from(
        { length: 12 },
        (_, i) => countsByMonth[i] || 0
      );

      setChartForUploadedDocs(dataArray);
    }
  }, [allDocuments]);

  useEffect(() => {
    if (allSharedDocs) {
      // To get total shared documents month wise
      const currentDate = new Date();
      const filteredArray = allSharedDocs.filter((obj) => {
        const documentDate = new Date(obj.created_at);
        return documentDate < currentDate;
      });

      const countsByMonth = {};
      filteredArray.forEach((obj) => {
        const month = new Date(obj.created_at).getMonth();
        countsByMonth[month] = (countsByMonth[month] || 0) + 1;
      });

      const dataArray = Array.from(
        { length: 12 },
        (_, i) => countsByMonth[i] || 0
      );

      setChartForSharedDocs(dataArray);
    }
  }, [allDocuments]);

  const data1 = chartForUploadedDocs ? chartForUploadedDocs : Array.fill(0); //all uploaded documents
  const data2 = chartForSharedDocs ? chartForSharedDocs : Array.fill(0); // all shared documents

  const handleNavigate = () => {
    navigate("/upload-document");
  };

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <SideNavBar />
          <div className="w-100 dashboard-content">
            <div className="line-ch-row">
              <div className="blurred-widget-block bg-white position-relative line-ch-row__chart-box">
                <img
                  className="top-left"
                  src="/images/bg-particles/dashboard-chart-left-top-knot.svg"
                  alt=""
                />
                <img
                  className="bottom-right"
                  src="/images/bg-particles/dashboard-chart-right-bottom-knot.svg"
                  alt=""
                />
                <div className="widget-title-top">
                  <div className="widget-title-top__left">
                    {/* <h4 className="mb-0 lh1-5 fw600 fz16 font-secondary">
                      Document Verification.
                    </h4> */}
                    <h4 className="mb-0 lh1-5 fw600 fz16 font-secondary">
                      Documents
                    </h4>
                    {/* <p className="mb-0 fz12 grayText">
                      Total Verified Documents:
                      <span className="fw600 greenThemeText">300k</span>
                    </p> */}
                    <p className="mb-0 fz12 grayText">
                      Total Uploaded Documents:
                      <span className="fw600 greenThemeText">
                        {" "}
                        {allDocuments?.length}
                      </span>
                    </p>
                  </div>
                  <div className="widget-title-top__right">
                    {/* <ul className="mb-0 list-inline anchor-tag-list">
                      <li>
                        <div className="lightgreenThemeText">
                          <a href="javascript:void(0);">
                            <img
                              src="/images/icons/icon-cross-green-light.svg"
                              alt="icon-cross-green-light"
                            />
                          </a>
                          <span>Yearly</span>
                        </div>
                      </li>
                      <li>
                        <div className="lightgreenThemeText">
                          <a href="javascript:void(0);">
                            <img
                              src="/images/icons/icon-cross-green-light.svg"
                              alt="icon-cross-green-light"
                            />
                          </a>
                          <span>Shared Docuemnts</span>
                        </div>
                      </li>
                      <li>
                        <div className="lightgreenThemeText">
                          <a href="javascript:void(0);">
                            <img
                              src="/images/icons/icon-cross-green-light.svg"
                              alt="icon-cross-green-light"
                            />
                          </a>
                          <span>Verified documents</span>
                        </div>
                      </li>
                    </ul> */}

                    {/* <a href="#" className="position-relative filter-reverse">
                      <img
                        src="/images/icons/icon-filter.svg"
                        alt="icon-filter"
                      />
                      <div className="position-absolute rounded-circle dot"></div>
                    </a>

                    <a href="#" className="calendar">
                      <img
                        src="/images/icons/icon-table-calender.svg"
                        alt="icon-table-calender"
                      />
                    </a> */}
                  </div>
                </div>

                <div className="chart-stats">
                  <div className="num num--red">
                    <span className="lh1 fz28 font-tertiary">
                      {allDocuments?.length ? allDocuments?.length : "00"}
                    </span>
                    {/* <p className="mb-0 text-capitalize fz12 grayText">
                      Verification Requested
                    </p> */}
                    <p className="mb-0 text-capitalize fz12 grayText">
                      Doucuments Uploaded
                    </p>
                  </div>
                  <div className="num num--green greenThemeText">
                    <span className="lh1 fz28 font-tertiary">
                      {allSharedDocs?.length ? allSharedDocs?.length : "00"}
                    </span>
                    {/* <p className="mb-0 text-capitalize fz12 grayText">
                      Verification Received
                    </p> */}
                    <p className="mb-0 text-capitalize fz12 grayText">
                      Documents Shared
                    </p>
                  </div>
                </div>

                <div className="double-line-chart">
                  {/* <canvas id="twoLineChart"></canvas> */}
                  <LineChart data1={data1} data2={data2} />
                </div>
              </div>

              <div className="shadow-34-05 blur-2 theme-feature-widget">
                <img
                  src="/images/illustrations/theme-feature-illustration.svg"
                  alt="theme-feature-illustration"
                  className="right-out-canvas"
                />

                <h4 className="font-secondary text-capitalize fw800 fz20 text-white mb-0">
                  Need to verify bulk docs?
                </h4>

                <p className="neutral-para fz12">
                  Learn how to create beautiful scenes in illustrator. Tips and
                  Tricks with real life projects and case studies.
                </p>

                <button
                  className="w-100 border-0 blurred-widget-block bdrs10 bg-white position-relative upload-docs"
                  onClick={handleNavigate}
                >
                  {/* <input className="p-0" type="file"/> */}

                  <div className="btn btn-solid btn-solid--green bdrs10">
                    <img
                      src="/images/icons/icon-upload-cloud-white.svg"
                      alt="icon-upload-cloud-white"
                    />
                  </div>
                  <span className="text-uppercase fw600 fz10 greenThemeText">
                    Upload your documents
                  </span>
                </button>

                <p className="mt-1 mb-0 neutral-para fz12 opacity-100">
                  Join the network of happy clients
                </p>

                <ul className="list-inline mb-0 avatars">
                  <li>
                    <img src="/images/avatars/avatar01.png" alt="avatar01" />
                  </li>
                  <li>
                    <img src="/images/avatars/avatar02.png" alt="avatar02" />
                  </li>
                  <li>
                    <img src="/images/avatars/avatar03.png" alt="avatar03" />
                  </li>
                  <li>
                    <img src="/images/avatars/avatar04.png" alt="avatar04" />
                  </li>
                  <li>
                    <img src="/images/avatars/avatar05.png" alt="avatar05" />
                  </li>
                </ul>
              </div>
            </div>

            <ul className="w-100 list-inline mb-0 widget-stats">
              <li>
                <div className="bdrs12 w-100 stats stats--gray">
                  <div className="fz26 font-tertiary lh-1 num">
                    {allDocuments?.length ? allDocuments?.length : "00"}
                  </div>
                  <span className="grayText fz12">Documents Uploaded</span>
                </div>
              </li>
              <li>
                <div className="bdrs12 w-100 stats stats--green">
                  <div className="fz26 font-tertiary lh-1 num">
                    {allSharedDocs?.length ? allSharedDocs?.length : "00"}
                  </div>
                  <span className="grayText fz12">Documents Shared</span>
                </div>
              </li>
              <li>
                <div className="bdrs12 w-100 stats stats--yellow">
                  <div className="fz26 font-tertiary lh-1 num">00</div>
                  <span className="grayText fz12">Reports Generated</span>
                </div>
              </li>
              <li>
                <div className="bdrs12 w-100 stats stats--blue">
                  <div className="fz26 font-tertiary lh-1 num">00</div>
                  <span className="grayText fz12">Documents Verified</span>
                </div>
              </li>
              <li>
                <div className="bdrs12 w-100 stats stats--peach">
                  <div className="fz26 font-tertiary lh-1 num">00</div>
                  <span className="grayText fz12">API Integrated</span>
                </div>
              </li>
            </ul>

            <div className="api-docs-row">
              <div className="blurred-widget-block bg-white api-docs-row__left">
                <div className="text-center">
                  <h4 className="mb-0 font-secondary fw600 fz16">
                    API Integration
                  </h4>
                  <p className="mb-0 grayText fz12">
                    Today API Calls:{" "}
                    <span className="greenThemeText fw600">298K</span>{" "}
                  </p>
                </div>

                <div className="rounded-circle blur-7 doughnut-chart">
                  <div className="rounded-circle bg-white">
                    <div className="font-tertiary fz40 lh-1 num">300K</div>
                    <div className="fz10 lh-1 grayText">Total API Call</div>
                  </div>
                  <div className="rounded-circle marker-dot">
                    <span className="rounded-circle"></span>
                  </div>
                  <canvas id="apiDoughnut"></canvas>
                </div>

                <ul className="mb-0 list-unstyled api-docs-row__list">
                  <li>
                    <div className="api-info">
                      <div className="font-tertiary fz12 text-white square square--gray">
                        220K
                      </div>
                      <span className="fz12 grayText">
                        {" "}
                        Total API Integrated Count
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="api-info">
                      <div className="font-tertiary fz12 text-white square square--green">
                        220K
                      </div>
                      <span className="fz12 greenThemeText"> API Success</span>
                    </div>
                  </li>
                  <li>
                    <div className="api-info">
                      <div className="font-tertiary fz12 text-white square square--mustard">
                        220K
                      </div>
                      <span className="fz12 mustardText"> API Failed</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="blurred-widget-block api-docs-row__right">
                <div className="widget-title-top">
                  <div className="widget-title-top__left">
                    <h4 className="mb-0 lh1-5 fw600 fz16 font-secondary">
                      Shared Documents
                    </h4>
                    <p className="mb-0 fz12 grayText">
                      Avg Rate:
                      <span className="fw600 greenThemeText">2.98%</span>
                    </p>
                  </div>
                  <div className="widget-title-top__right">
                    {/* <a href="#" className="position-relative filter-reverse">
                      <img
                        src="/images/icons/icon-filter.svg"
                        alt="icon-filter"
                      />
                     <!-- <div className="position-absolute rounded-circle dot"></div> --> 
                    </a> */}

                    {/* <a href="#" className="calendar">
                      <img
                        src="/images/icons/icon-table-calender.svg"
                        alt="icon-table-calender"
                      />
                    </a> */}
                  </div>
                </div>

                <div className="table-status-area">
                  <div className="table-responsive">
                    <table className="table mb-0 custom-dataTable">
                      <thead>
                        <tr>
                          <th className="text-center">
                            <div className="fz12 fw400 grayText">
                              <span className="greenThemeText fz16 fw500">
                                #
                              </span>
                              <span>ID</span>
                            </div>
                          </th>
                          <th>
                            <div className="fz12 fw400 grayText">
                              <img
                                src="/images/icons/icon-th-name.svg"
                                alt="Name"
                              />
                              <span>Email ID</span>
                            </div>
                          </th>
                          <th>
                            <div className="fz12 fw400 grayText">
                              <img
                                src="/images/icons/icon-calender.svg"
                                alt="calendar"
                              />
                              <span>Date</span>
                            </div>
                          </th>
                          <th>
                            <div className="fz12 fw400 grayText">
                              <img
                                src="/images/icons/icon-th-type.svg"
                                alt="type"
                              />
                              <span>Link</span>
                            </div>
                          </th>
                          <th>
                            <div className="fz12 fw400 grayText">
                              <img
                                src="/images/icons/icon-status.svg"
                                alt="calendar"
                              />
                              <span>Status</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allSharedDocs &&
                          allSharedDocs?.map((item) => (
                            <tr>
                              <td className="text-center fw500">
                                {item?.file?.id}
                              </td>
                              <td>{item?.to_user}</td>
                              <td>
                                {formatDate(item?.created_at)}
                                <br />
                                {/* <span className="lightGrey">
                                  {formatHour(item?.created_at)} :{" "}
                                  {formatMinute(item?.created_at)}{" "}
                                  {formatHour(item?.created_at) >= 12
                                    ? "PM"
                                    : "AM"}
                                </span> */}
                                     <span className="lightGrey">
                                 {convertTime(item?.created_at)}
                                </span>
                              </td>
                              <td>
                                <a
                                  href={`${IMAGE_PROXY_URL}${item?.file?.file?.replace(
                                    "media/files/5/",
                                    ""
                                  )}`}
                                  className="greenThemeText fst-italic"
                                  target="_blank"
                                  rel="noopener noreferrer" // Recommended for security reasons
                                >
                                  {item?.file?.file?.substring(0, 15)}...
                                </a>
                              </td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" id="togBtn" />
                                  <div className="slider round">
                                    <span className="on">ON</span>
                                    <span className="off">Off</span>
                                  </div>
                                </label>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="blurred-widget-block bg-white doc-listing-widget">
              <div className="widget-title-top">
                <div className="widget-title-top__left">
                  <h4 className="mb-0 lh1-5 fw600 fz16 font-secondary">
                    Document Listing
                  </h4>
                  <p className="mb-0 fz12 grayText">
                    Total Documents:
                    <span className="fw600 greenThemeText">300k</span>
                  </p>
                </div>
                <div className="widget-title-top__right">
                  <div className="blur-2 form-group form-group--search">
                    <div className="bdrs12 fab fab--search">
                      <img
                        src="/images/icons/icon-search-pastel-green.svg"
                        alt="Search"
                      />
                    </div>

                    <input
                      type="text"
                      className="border-0 bdrs12 fz14 form-control"
                      placeholder="Search your document here..."
                      value={searchTerm}
                      onChange={handleSearch}
                    />
{/* 
                    <a
                      href="#"
                      className="ms-2 position-relative filter-reverse"
                    >
                      <img
                        src="/images/icons/icon-filter.svg"
                        alt="icon-filter"
                      />
                      <div className="position-absolute rounded-circle dot"></div>
                    </a>

                    <a href="#" className="mx-2 calendar">
                      <img src="/images/icons/icon-table-calender.svg" alt="" />
                    </a> */}
                  </div>
                </div>
              </div>

              <div className="table-area">
                <div className="table-responsive">
                  <table className="table mb-0 custom-dataTable">
                    <thead>
                      <tr>
                        {/* <th>
                          <div className="lh-1 d-inline-block checkbox-wrapper">
                            <input
                              id="all-00"
                              type="checkbox"
                              name="select-all"
                            />
                            <label for="all-00">
                              <span></span>
                              <div>
                                <b className="fw400 fz12 grayText">All</b>
                              </div>
                            </label>
                          </div>
                        </th> */}
                        <th className="text-center">
                          <div className="fz12 fw400 grayText">
                            <span className="greenThemeText fz16 fw500">#</span>
                            <span>ID</span>
                          </div>
                        </th>
                        <th>
                          <div className="fz12 fw400 grayText">
                            <img
                              src="/images/icons/icon-th-name.svg"
                              alt="Name"
                            />
                            <span>Name</span>
                          </div>
                        </th>
                        <th>
                          <div className="fz12 fw400 grayText">
                            <img
                              src="/images/icons/icon-th-verification.svg"
                              alt="verification"
                            />
                            <span>Hash ID</span>
                          </div>
                        </th>
                        <th>
                          <div className="fz12 fw400 grayText">
                            <img
                              src="/images/icons/icon-calender.svg"
                              alt="calendar"
                            />
                            <span>Date</span>
                          </div>
                        </th>
                        <th>
                          <div className="fz12 fw400 grayText">
                            <img
                              src="/images/icons/icon-th-type.svg"
                              alt="type"
                            />
                            <span>Type</span>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex align-items-center">
                            <img
                              src="/images/icons/icon-statusx.svg"
                              alt="icon-status.svg"
                            />
                            <span className="fw400 fz12 grayText">Status</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {myDocs &&
                        myDocs?.map((item) => (
                          <tr key={item?.id}>
                            {/* <td>
                            <div className="lh-1 d-inline-block checkbox-wrapper">
                              <input
                                id="all-02"
                                type="checkbox"
                                name="select-all"
                              />
                              <label for="all-02">
                                <span></span>
                              </label>
                            </div>
                          </td> */}
                            <td className="text-center">
                              <span className="fw500">{item?.id}</span>
                            </td>
                            <td>
                              {/* {item?.file
                                ?.substring(item?.file?.lastIndexOf("/") + 1)
                                ?.substring(
                                  0,
                                  item?.file
                                    ?.substring(
                                      item?.file?.lastIndexOf("/") + 1
                                    )
                                    .lastIndexOf(".")
                                )} */}
                              {truncateString(
                                item?.file?.substring(
                                  item?.file?.lastIndexOf("/") + 1
                                )
                              )}
                            </td>
                            {/* {!item?.id ? ( //replace id with document key verified
                              <td>
                                <div className="verification-status">
                                  <img
                                    src="/images/icons/icon-verified.svg"
                                    alt=""
                                  />
                                  <span className="text-uppercase fw500 fz10 greenThemeText">
                                    Verified
                                  </span>
                                </div>
                              </td>
                            ) : (
                              <td className="fz12 fw400 primaryDarkText">
                                <div className="pending-div">
                                  <img
                                    src="/images/icons/icon-pending.svg"
                                    alt="pending"
                                  />
                                  <span>Pending</span>
                                </div>
                              </td>
                            )} */}
                            <td className="fz12 fw400 primaryDarkText">
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open(
                                    `${HASH_URL}/${item?.mc_hash}`,
                                    "_blank"
                                  );
                                }}
                                className="fz12 fw400 greenThemeText fst-italic"
                                style={{
                                  fontSize: "11px",
                                  transition: "font-size 0.2s",
                                }}
                                target="_blank"
                                rel="noopener noreferrer" // Recommended for security reasons
                              >
                                <span
                                  style={{ fontSize: "11px" }}
                                  onMouseOver={(e) =>
                                    (e.currentTarget.style.fontSize = "12px")
                                  }
                                  onMouseOut={(e) =>
                                    (e.currentTarget.style.fontSize = "11px")
                                  }
                                >
                                  {item?.mc_hash
                                    ? truncateString(item?.mc_hash)
                                    : "NA"}{" "}
                                </span>
                              </a>
                            </td>

                            <td className="fz12 fw400 primaryDarkText">
                              {formatDate(item?.created_at)}
                              <br />
                              {/* <span className="lightGrey">
                                {formatHour(item?.created_at)} :{" "}
                                {formatMinute(item?.created_at)}{" "}
                                {formatHour(item?.created_at) >= 12
                                  ? "PM"
                                  : "AM"}
                              </span> */}
                                     <span className="lightGrey">
                                 {convertTime(item?.created_at)}
                                </span>
                            </td>
                            <td>
                              <div className="p-instructions">
                                {item?.file
                                  ?.split(".")
                                  ?.pop()
                                  ?.toLowerCase() === "jpg" ? (
                                  <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                                    .{item?.file?.split(".").pop()}
                                  </span>
                                ) : item?.file
                                    ?.split(".")
                                    .pop()
                                    ?.toLowerCase() === "pdf" ? (
                                  <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--png">
                                    .{item?.file?.split(".").pop()}
                                  </span>
                                ) : (
                                  <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--docx">
                                    .{item?.file?.split(".").pop()}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="fz12 fw400 primaryDarkText">
                              {item?.permission === "Private" ? (
                                <div className="status-div">
                                  {" "}
                                  <img
                                    src="/images/icons/icon-user-red.svg"
                                    alt=""
                                  />
                                  {item?.permission}
                                </div>
                              ) : (
                                <div className="status-div">
                                  <img
                                    src="/images/icons/icon-public.svg"
                                    alt="public"
                                  />
                                  {item?.permission}
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </main>
  );
}
