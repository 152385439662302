import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const SparkLinePrivate = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        // Destroy previous chart instance if exists
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
        let gradientGreen = ctx.createLinearGradient(20, 0, 10, 70);
        gradientGreen.addColorStop(0, "rgba(210, 244, 234,0.55)");
        gradientGreen.addColorStop(0.5, "white");
        gradientGreen.addColorStop(1, "white");

        chartInstance.current = new Chart(ctx, {
          type: "line",
          data: {
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
            ],
            datasets: [
              {
                data: [4.5, 6.8, 5.8, 8.4, 7.2, 4.3, 6.4, 9.5, 8.2, 8.5, 5.0],
                borderColor: "#20C997",
                borderWidth: 2,
                tension: 0.4,
                fill: true,
                backgroundColor: gradientGreen,
                order: 3,
                pointRadius: 0,
                onHoverPointRadius: 0,
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              easing: "easeInOutQuad",
              duration: 520,
            },
            scales: {
              x: {
                display: false,
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
              y: {
                display: false,
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          },
        });
      }
    }

    // Cleanup function
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return <canvas ref={chartRef} />;
};

export default SparkLinePrivate;
