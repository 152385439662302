import React, { useEffect, useState } from "react";
import TopNavBar from "../../layouts/topNavBar";
import SideNavBar from "../../layouts/sideNavBar";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  allSharedDocuments,
  getAllDocuments,
} from "../../redux/Documents/doc.action";
import {
  convertTime,
  formatDate,
  formatHour,
  formatMinute,
  truncateString,
  truncateURL,
} from "../../services/utils";
import { allUsers } from "../../redux/Authentication/auth.action";
import LineChart from "../../charts/multiFillChart";
import SparkLinePublic from "../../charts/staticChart/sparkLinePublic";
import SparkLinePrivate from "../../charts/staticChart/sprakLinePrivate";
import SparkLineShared from "../../charts/staticChart/sparkLineShared";
import { HASH_URL } from "../../services/Api";

export default function SharedDocuments() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sharedDocs, setSharedDocs] = useState([]);
  const [sharedDocs1, setSharedDocs1] = useState([]);
  const [chartArray, setChartArray] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const store = useSelector((state) => state);
  const allSharedDocs = store?.DocumentsManager?.allSharedDocs;
  const userList = store?.AuthManager?.allUsers;
  const allDocuments = store?.DocumentsManager?.myDocuments;

  useEffect(() => {
    dispatch(allSharedDocuments());
    dispatch(getAllDocuments());
    dispatch(allUsers());
  }, []);

  useEffect(() => {
    if (allSharedDocs) {
      // const reverseDocArray = allSharedDocs?.reverse();
      setSharedDocs(allSharedDocs);
      setSharedDocs1(allSharedDocs);
    }
  }, [allSharedDocs]);

  // handle chart data
  useEffect(() => {
    if (allSharedDocs) {
      const currentDate = new Date();
      const filteredArray = allSharedDocs.filter((obj) => {
        const documentDate = new Date(obj.created_at);
        return documentDate < currentDate;
      });

      const countsByMonth = {};
      filteredArray.forEach((obj) => {
        const month = new Date(obj.created_at).getMonth();
        countsByMonth[month] = (countsByMonth[month] || 0) + 1;
      });

      const dataArray = Array.from(
        { length: 12 },
        (_, i) => countsByMonth[i] || 0
      );

      setChartArray(dataArray);
    }
  }, [allSharedDocs]);

  const data = chartArray ? chartArray : Array.fill(0);

   // handle search event
   const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const results = performSearch(value);
    results && setSharedDocs(results);
    
  };

  // function performSearch(value) {
  //   return sharedDocs1?.filter((el) => el?.file?.id?.toString()?.includes(value));
  // }
  function performSearch(value) {
    return sharedDocs1?.filter((el) => {
      // Check if the file ID or name includes the search value
      return (
        el?.file?.id?.toString()?.includes(value) ||
        truncateURL(el?.file?.file?.toLowerCase())?.includes(value.toLowerCase())
      );
    });
  }

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />
      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <SideNavBar />
          <section className="mt-0 body-profile2">
            <div className="w-100 line-ch-row">
              <div className="blurred-widget-block bg-white position-relative line-ch-row__chart-box">
                <img
                  className="top-left"
                  src="/images/bg-particles/dashboard-chart-left-top-knot.svg"
                  alt=""
                />
                <img
                  className="bottom-right"
                  src="/images/bg-particles/dashboard-chart-right-bottom-knot.svg"
                  alt=""
                />

                <div className="widget-title-top">
                  <div className="widget-title-top__left">
                    <h4 className="mb-0 lh1-5 fw600 fz16 font-secondary">
                      Shared Documents
                    </h4>
                    <p className="mb-0 fz12 grayText">
                      Total Documents Uploaded:
                      <span className="fw600 greenThemeText">
                        {" "}
                        {allDocuments?.length}
                      </span>
                    </p>
                  </div>
                  <div className="widget-title-top__right">
                    {/* <ul className="mb-0 list-inline anchor-tag-list">
                      <li>
                        <div className="lightgreenThemeText">
                          <a href="javascript:void(0);">
                            <img
                              src="/images/icons/icon-cross-green-light.svg"
                              alt=""
                            />
                          </a>
                          <span>Public</span>
                        </div>
                      </li>
                      <li>
                        <div className="lightgreenThemeText">
                          <a href="javascript:void(0);">
                            <img
                              src="/images/icons/icon-cross-green-light.svg"
                              alt=""
                            />
                          </a>
                          <span>Private</span>
                        </div>
                      </li>
                      <li>
                        <div className="lightgreenThemeText">
                          <a href="javascript:void(0);">
                            <img
                              src="/images/icons/icon-cross-green-light.svg"
                              alt=""
                            />
                          </a>
                          <span>Shared</span>
                        </div>
                      </li>
                      <li>
                        <div className="lightgreenThemeText">
                          <a href="javascript:void(0);">
                            <img
                              src="/images/icons/icon-cross-green-light.svg"
                              alt=""
                            />
                          </a>
                          <span>Yearly</span>
                        </div>
                      </li>
                    </ul> */}

                    {/* <a href="#" className="position-relative filter-reverse">
                      <img src="/images/icons/icon-filter.svg" alt="" />
                      <div className="position-absolute rounded-circle dot"></div>
                    </a>

                    <a href="#" className="calendar">
                      <img src="/images/icons/icon-table-calender.svg" alt="" />
                    </a> */}
                  </div>
                </div>

                <div className="sparkline-line-charts">
                  <div className="chart-area">
                    {/* <canvas id="multiLineFillChart"></canvas> */}
                    <LineChart data={data} />
                  </div>
                  <div className="blurred-widget-block sparks-box">
                    <ul className="list-unstyled">
                      <li>
                        <div className="s-stats">
                          <div className="s-stats__left">
                            <h4 className="fz12 mb-0 fw400">Public</h4>
                            <div className="fz22 font-tertiary num">00</div>
                          </div>
                          <div className="s-stats__right">
                            {/* <canvas id="sparkline01"></canvas> */}
                            <SparkLinePublic />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="s-stats">
                          <div className="s-stats__left">
                            <h4 className="fz12 mb-0 fw400">Private</h4>
                            <div className="fz22 font-tertiary num">00</div>
                          </div>
                          <div className="s-stats__right">
                            {/* <canvas id="sparkline02"></canvas> */}
                            <SparkLinePrivate />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="s-stats">
                          <div className="s-stats__left">
                            <h4 className="fz12 mb-0 fw400">Shared</h4>
                            <div className="fz22 font-tertiary num">
                              {allSharedDocs?.length}
                            </div>
                          </div>
                          <div className="s-stats__right">
                            {/* <canvas id="sparkline03"></canvas> */}
                            <SparkLineShared />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="p-0 theme-feature-widget background-none border-0 side-cards-container">
                <div className="blur-2 shadow-34-05 light-green-theme-block">
                  <div className="light-green-theme-block__inner">
                    <h4 className="font-secondary text-capitalize fw800 fz20 mb-0">
                      Share your file or folder <br /> in just one click!
                    </h4>

                    <p className="mb-1 neutral-para fz12 grayText">
                      Tips and Tricks with real life projects and case studies.
                    </p>

                    <button className="ps-3 w-100 border-0 blurred-widget-block bdrs10 bg-white position-relative upload-docs">
                      <input className="p-0" type="file" />

                      <div className="btn btn-solid btn-solid--green bdrs10">
                        <img src="/images/icons/icon-share-doc.svg" alt="" />
                      </div>
                      <span className="text-uppercase fw600 fz10 greenThemeText">
                        Share your document
                      </span>
                    </button>
                  </div>
                </div>

                <div className="mt-3 blur-2 shadow-34-05 light-orange-theme-block">
                  <div className="light-orange-theme-block__inner">
                    <h4 className="font-secondary text-capitalize fw800 fz20 mb-0">
                      Add new recipient to
                      <br /> share your files!
                    </h4>

                    <p className="mb-1 neutral-para fz12 grayText">
                      Tips and Tricks with real life projects and case studies.
                    </p>

                    <button className="w-100 blurred-widget-block bdrs10 bg-white position-relative upload-docs">
                      <div className="btn btn-solid btn-solid--dark bdrs10">
                        <img src="/images/icons/icon-recipient.svg" alt="" />
                      </div>
                      <span className="text-uppercase fw600 fz10 text-start">
                        Add new recipient
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 w-100 blurred-widget-block">
              <div className="d-flex custom-tabletop p-3">
                <div className="fw600 fz16 primaryDarkText font-secondary d-flex flex-column justify-content-center">
                  Shared Document Listing <br />
                  <p className="m-0 fw400 fz12 grayText font-primary">
                    <span className="fw400 fz12">
                      Total Shared Documents :
                      <span className="fw600 greenThemeText">
                        {" "}
                        {sharedDocs?.length}
                      </span>
                    </span>
                  </p>
                </div>
                <div className="d-flex">
                  <div className="blur-2 form-group form-group--search">
                    <div className="bdrs12 fab fab--search">
                      <img
                        src="/images/icons/icon-search-pastel-green.svg"
                        alt="Search"
                      />
                    </div>

                    <input
                      type="text"
                      className="border-0 bdrs12 fz14 form-control"
                      placeholder="Search your document here..."
                      value={searchTerm}
                      onChange={handleSearch}
                    />

                    <ul className="m-0 lh-1 list-inline icons">
                      <li>
                        <a href="#" className="custom-width">
                          <span className="dot"></span>
                          <img src="/images/icons/icon-reset.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="#" className="custom-width">
                          <img
                            src="/images/icons/icon-calender-checked.svg"
                            alt=""
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="ms-3 table-top-btn-grp bdrs10">
                    <button>
                      <img
                        src="/images/icons/icon-delete.svg"
                        alt="icon-delete"
                      />
                    </button>
                    <button>
                      <img
                        src="/images/icons/icon-forward.svg"
                        alt="icon-forward"
                      />
                    </button>
                    <button>
                      <img
                        src="/images/icons/icon-download-light.svg"
                        alt="icon download"
                      />
                    </button>
                  </div> */}
                </div>
              </div>
              {/* <!-- table --> */}
              <div class="px-3 table-reponsive">
                <table className="custom-dataTable dashboard-table">
                  <thead>
                    <tr>
                      {/* <th>
                        <div className="lh-1 d-inline-block checkbox-wrapper">
                          <input
                            id="all-00"
                            type="checkbox"
                            name="select-all"
                          />
                          <label for="all-00">
                            <span></span>
                            <div>
                              <b className="fw400 fz12 grayText">All</b>
                            </div>
                          </label>
                        </div>
                      </th> */}
                      <th>
                        <div className="d-flex align-items-center">
                          <span className="fw400 fz12 greenThemeText">#</span>
                          <span className="fw400 fz12">ID</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-emailbox.svg"
                            alt="icon-emailbox.svg"
                          />
                          <span className="fw400 fz12 grayText">Name</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-verfication.svg"
                            alt="icon-verfication.svg"
                          />
                          <span className="fw400 fz12 grayText">Hash ID</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-green-profile.svg"
                            alt="icon-verfication.svg"
                          />
                          <span className="fw400 fz12 grayText">
                            Shared With
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-green-profile.svg"
                            alt="icon-verfication.svg"
                          />
                          <span className="fw400 fz12 grayText">Doc Owner</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="images/icons/icon-calender.svg"
                            alt="icon-calender.svg"
                          />
                          <span className="fw400 fz12 grayText">Date</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-calender.svg"
                            alt="icon-calender.svg"
                          />
                          <span className="fw400 fz12 grayText">Type</span>
                        </div>
                      </th>
                      <th>
                        <div className="fz12 fw400 grayText">
                          <img
                            src="/images/icons/icon-calender.svg"
                            alt="calendar"
                          />
                          <span>Privacy Settings</span>
                        </div>
                      </th>
                      {/* <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-flag.svg"
                            alt="icon-flag.svg"
                          />
                          <span className="fw400 fz12 grayText">Action</span>
                        </div>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {sharedDocs &&
                      sharedDocs?.map((item) => (
                        <tr>
                          {/* <td>
                            <div className="lh-1 d-inline-block checkbox-wrapper">
                              <input
                                id="all-03"
                                type="checkbox"
                                name="select-all"
                              />
                              <label for="all-03">
                                <span></span>
                              </label>
                            </div>
                          </td> */}
                          <td className="fz12 fw400 primaryDarkText">
                            {item?.file?.id}
                          </td>
                          <td className="fz12 fw400 primaryDarkText">
                            {/* {item?.file?.file
                              ?.substring(
                                item?.file?.file?.lastIndexOf("/") + 1
                              )
                              ?.substring(
                                0,
                                item?.file?.file
                                  ?.substring(
                                    item?.file?.file?.lastIndexOf("/") + 1
                                  )
                                  .lastIndexOf(".")
                              )} */}
                            {truncateString(
                              item?.file?.file?.substring(
                                item?.file?.file?.lastIndexOf("/") + 1
                              )
                            )}
                          </td>
                          <td>
                            {" "}
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${HASH_URL}/${item?.mc_hash}`,
                                  "_blank"
                                );
                              }}
                              className="fz12 fw400 greenThemeText fst-italic"
                              style={{
                                fontSize: "11px",
                                transition: "font-size 0.2s",
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span
                                style={{ fontSize: "11px" }}
                                onMouseOver={(e) =>
                                  (e.currentTarget.style.fontSize = "12px")
                                }
                                onMouseOut={(e) =>
                                  (e.currentTarget.style.fontSize = "11px")
                                }
                              >
                                {item?.file?.mc_hash
                                  ? truncateString(item?.file?.mc_hash)
                                  : "NA"}
                              </span>{" "}
                            </a>
                          </td>
                          {/* <td className="fz12 fw400 primaryDarkText">
                            {userList &&
                              (() => {
                                const name = userList.find(
                                  (el) => el?.id === item?.from_user
                                );
                                const userName = name
                                  ? name?.userdetail?.name
                                  : "NA";
                                return (
                                  <span className="fw400 fz12 greenThemeText">
                                    {userName}
                                  </span>
                                );
                              })()}
                          </td> */}

                          <td className="fw400 fz12 primaryDarkText">
                            {item?.to_user}
                          </td>
                          <td className="fz12 fw400 primaryDarkText">
                            <span>{item?.file?.about}</span>
                          </td>
                          <td className="fz12 fw400 primaryDarkText">
                            {formatDate(item?.created_at)}
                            <br />
                            {/* <span className="lightGrey">
                              {formatHour(item?.created_at)} :{" "}
                              {formatMinute(item?.created_at)}{" "}
                              {formatHour(item?.created_at) >= 12
                                ? "PM"
                                : "AM"}
                            </span> */}
                                   <span className="lightGrey">
                                 {convertTime(item?.created_at)}
                                </span>
                          </td>
                          <td className="fz12 fw400 primaryDarkText">
                            <div className="p-instructions">
                              {item?.file?.file
                                ?.split(".")
                                ?.pop()
                                ?.toLowerCase() === "jpg" ? (
                                <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                                  .{item?.file?.file?.split(".").pop()}
                                </span>
                              ) : item?.file?.file
                                  ?.split(".")
                                  .pop()
                                  ?.toLowerCase() === "pdf" ? (
                                <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--png">
                                  .{item?.file?.file?.split(".").pop()}
                                </span>
                              ) : (
                                <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--docx">
                                  .{item?.file?.file?.split(".").pop()}
                                </span>
                              )}
                            </div>
                          </td>

                          <td className="fz12 fw400 primaryDarkText">
                            {item?.file?.permission === "Private" ? (
                              <div className="status-div">
                                {" "}
                                <img
                                  src="/images/icons/icon-user-red.svg"
                                  alt=""
                                />
                                {item?.file?.permission}
                              </div>
                            ) : (
                              <div className="status-div">
                                <img
                                  src="/images/icons/icon-public.svg"
                                  alt="public"
                                />
                                {item?.file?.permission}
                              </div>
                            )}
                          </td>

                          {/* <td>
                            <div className="dropdown">
                              <button
                                className="border-0 p-0 btn dropdown-toggle select-privacy"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src="/images/icons/icon-user-red.svg"
                                  alt=""
                                />
                                <span className="fz12">Private</span>
                                <img
                                  className="ts03 arrow"
                                  src="/images/icons/icon-table-dd-arrow.svg"
                                  alt=""
                                />
                              </button>
                              <div className="blurred-widget-block bdr-gray blur-7 dropdown-menu dropdown-menu--privacy">
                                <ul className="mb-0 overflow-hidden blurred-widget-block bdrs12 list-unstyled">
                                  <li>
                                    <a className="fz12 active" href="#">
                                      <b>
                                        <img
                                          src="/images/icons/icon-user-red.svg"
                                          alt="icon-user-red"
                                        />
                                      </b>
                                      <span className="">Private</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a className="fz12" href="#">
                                      <b>
                                        <img
                                          src="/images/icons/icon-multi-users-green-dark.svg"
                                          alt="icon-multi-users-green-dark"
                                        />
                                      </b>
                                      <span className="">Public</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a className="fz12" href="#">
                                      <b>
                                        <img
                                          src="/images/icons/icon-2-users-green-dark.svg"
                                          alt="icon-2-users-green-dark"
                                        />
                                      </b>
                                      <span className="">Shared with</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td> */}
                          {/* 
                          <td>
                            <div className="table-actions p-0">
                              <div className="table-action-innerdiv">
                                <span>99+</span>
                                <img
                                  src="/images/icons/icon-grey-eye.svg"
                                  alt="eye"
                                />
                              </div>

                              <div className="dropdown">
                                <button
                                  className="border-0 p-0 btn dropdown-toggle select-privacy"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img
                                    src="/images/icons/icon-menu.svg"
                                    alt="icon-menu"
                                  />
                                </button>
                                <div className="bdr-gray blur-7 dropdown-menu dropdown-menu--privacy dropdoen-custom">
                                  <nav className="p-0 list-inline navigation">
                                    <li>
                                      <a href="" className="nav-links">
                                        <div className="nav-links__inner">
                                          <span>
                                            <img
                                              src="/images/icons/icon-dowload.svg"
                                              alt="icon-dowload"
                                            />
                                          </span>
                                          <strong className="fz12">
                                            Download
                                          </strong>
                                        </div>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href=""
                                        className="nav-links nav-links--active"
                                      >
                                        <div className="nav-links__inner">
                                          <span>
                                            <img
                                              src="/images/icons/icon-my-documents-nav-link.svg"
                                              alt="icon-my-documents-nav-link"
                                            />
                                          </span>
                                          <strong className="fz12">
                                            My Documents
                                          </strong>
                                        </div>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="" className="nav-links">
                                        <div className="nav-links__inner">
                                          <span>
                                            <img
                                              src="/images/icons/icon-share-black.svg"
                                              alt="icon-share-black"
                                            />
                                          </span>
                                          <strong className="fz12">
                                            Share
                                          </strong>
                                        </div>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="" className="nav-links">
                                        <div className="nav-links__inner">
                                          <span>
                                            <img
                                              src="/images/icons/icon-delete-black.svg"
                                              alt="icon-delete-black"
                                            />
                                          </span>
                                          <strong className="fz12">
                                            Delete
                                          </strong>
                                        </div>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="" className="nav-links">
                                        <div className="nav-links__inner">
                                          <span>
                                            <img
                                              src="/images/icons/icon-history-black.svg"
                                              alt="icon-history-black"
                                            />
                                          </span>
                                          <strong className="fz12">
                                            Versions History
                                          </strong>
                                        </div>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="" className="nav-links">
                                        <div className="nav-links__inner">
                                          <span>
                                            <img
                                              src="/images/icons/icon-copy-black.svg"
                                              alt="icon-copy-black"
                                            />
                                          </span>
                                          <strong className="fz12">
                                            Copy Hash
                                          </strong>
                                        </div>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="" className="nav-links">
                                        <div className="nav-links__inner">
                                          <span>
                                            <img
                                              src="/images/icons/icon-edit-black.svg"
                                              alt="icon-edit-black"
                                            />
                                          </span>
                                          <strong className="fz12">
                                            Edit Document
                                          </strong>
                                        </div>
                                      </a>
                                    </li>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}
