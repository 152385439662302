import React from "react";

export default function AuthFooterLeft() {
  return (
    <>
      <div class="auth-features__bottom mobile-only">
        <ul class="list-inline">
          <li>
            <img
              class="amazon-logo"
              src="/images/logos/logo-amazon.svg"
              alt="logo-amazon"
            />
          </li>
          <li>
            <img src="/images/logos/logo-instacart.svg" alt="logo-instacart" />
          </li>
          <li>
            <img src="/images/logos/logo-duolingo.svg" alt="logo-duolingo" />
          </li>
          <li>
            <img src="/images/logos/logo-sony.svg" alt="logo-sony" />
          </li>
          <li>
            <img src="/images/logos/logo-hulu.svg" alt="logo-hulu" />
          </li>
          <li>
            <img src="/images/logos/logo-disnep.svg" alt="logo-disnep" />
          </li>
        </ul>
      </div>

      <ul className="list-inline tnc-pp-links">
        <li>
          <a className="fz10 grayText ts03 text-capitalize" href="/terms-and-conditions">
            Terms and conditions
          </a>
        </li>
        {/* <li>
          <a className="fz10 grayText ts03 text-capitalize" href="#">
            help and Support
          </a>
        </li> */}
        <li>
          <a className="fz10 grayText ts03 text-capitalize" href="/privacy">
            Privacy Policy
          </a>
        </li>
      </ul>
    </>
  );
}
