import React from 'react'

export default function AuthFooterRight() {
  return (
    <div className="auth-features__bottom">
    <ul className="list-inline">
      <li>
        <img className="amazon-logo" src="/images/logos/logo-amazon.svg" alt="logo-amazon"/>
      </li>
      <li>
        <img src="/images/logos/logo-instacart.svg" alt="logo-instacart"/>
      </li>
      <li>
        <img src="/images/logos/logo-duolingo.svg" alt="logo-duolingo"/>
      </li>
      <li>
        <img src="/images/logos/logo-sony.svg" alt="logo-sony"/>
      </li>
      <li>
        <img src="/images/logos/logo-hulu.svg" alt="logo-hulu"/>
      </li>
      <li>
        <img src="/images/logos/logo-disnep.svg" alt="logo-disnep"/>
      </li>
    </ul>
  </div>
  )
}
