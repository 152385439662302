import React, { useState, useEffect } from "react";
import TopNavBar from "../../../layouts/topNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import GooglePlacesAutocomplete from "../../../reusable_components/googlePlacesAutoComplete";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../../redux/Authentication/auth.action";
import PhoneInput from "react-phone-number-input";
import flags from 'country-flag-icons/react/3x2'

export default function OrgBasicInformation1() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const store = useSelector(state => state);
  const userDetail = store?.AuthManager?.userDetail?.userdetail;

  const [contactNo, setContactNo] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [addressFromLatLng, setAddressFromLatLng] = useState("");
  const [detail, setDetail] = useState(null);

  useEffect(()=>{
    dispatch(getUserDetail())
  },[])

  useEffect(()=>{
    if(userDetail?.name && userDetail?.company ){
      setDetail({...userDetail})
    }
  },[userDetail])

  const fetchLocation = (e) => {
    // const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const apiKey = "AIzaSyBGMUJe2XUHhIZKXIrq4btVXK2s3zeqLLM";
    e.preventDefault();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
          axios
            .get(apiUrl)
            .then((response) => {
              if (response.data.status === "OK") {
                setAddressFromLatLng(
                  response.data.results[0].formatted_address
                );
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        },
        (error) => {
          if (error.code === 1) {
            retryFetchLocation();
          } else {
            console.error("Error fetching location:", error.message);
          }
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  };

  const retryFetchLocation = () => {
    const apiKey = "AIzaSyBGMUJe2XUHhIZKXIrq4btVXK2s3zeqLLM";
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
        axios
          .get(apiUrl)
          .then((response) => {
            if (response.data.status === "OK") {
              setAddressFromLatLng(response.data.results[0].formatted_address);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      },
      (error) => {
        console.error("Error fetching location:", error.message);
      }
    );
  };

  const handleSkip = () => {
    navigate("/org-detailed-information");
  };
  const handleNext = () => {
    const data1 = {
      ...state?.data,
      contact: contactNo ? contactNo : "NA",
      url: companyUrl ? companyUrl : "NA",
      location: addressFromLatLng ? addressFromLatLng : "NA",
    };
    navigate("/org-detailed-information", { state: { data1 } });
  };
  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <aside>
            <div className="blurred-widget-block">
              <div className="inner-div-sidebar">
                <div className="profile-status">
                  <div className="inner-ps d-flex justify-content-center align-items-center">
                    <img src="/images/icons/icon-circle.svg" alt="cirle" />
                    <span className="tilte fw600 fz14">Profile Status</span>
                    <span className="tilte-percentage fw400 fz14 ms-4">
                      30%
                    </span>
                  </div>
                </div>
                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img
                        src="/images/icons/icon-checked.svg"
                        alt="un-checked"
                      />
                      <span className="inner-title">Basic Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal
                      assistance and sharing
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="green-theme-block">
              <img
                src="/images/illustrations/image-coffee-together.svg"
                alt=""
                className="mx-auto"
              />

              <h2 className="mb-0 font-secondary fw600 text-white fz16">
                Let’s have coffee together
              </h2>

              <p className="mb-0 fz10">
                In need of any sort of assistance? We are here to help you!
              </p>

              <button className="text-uppercase fw600 fz10 bdrs10 btn btn-solid btn-solid--green">
                <span className="lh-1">Let’s chat!</span>
              </button>
            </div>
          </aside>
          <section className="body-profile2">
            <div className="title">
              <img
                className="d-inline-flex"
                src="/images/icons/icon-left-awrrow.svg"
                alt=""
              />
              <span className="h-title-teal">Organization</span>
              <span className="h-title-black"> - Basic Information</span>
            </div>
            <div className="body-context height-adjust-context">
              <div className="btn-wrapper">
                <button className="custom-btn btn-light" onClick={handleSkip}>
                  skip{">"}
                </button>
                <button className="custom-btn btn-dark" onClick={handleNext}>
                  Next
                </button>
              </div>
              <div className="inner-body-context">
                <h6 className="heading-profiles">Company Information</h6>
                <p className="details-grey">
                  Please select the basic information about you so we may know
                  you better
                </p>
                <img
                  className="mx-auto mt-2"
                  src="/images/horizontal-line.svg"
                  alt="line"
                />
                <form className="docur-form">
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Company Contact no -
                    </label>
                     <div className="blurred-widget-block">
                      <div className="input-wrapper p-0">
                        <div className="flag-dd">
                          <PhoneInput
                            international
                            defaultCountry="IN"
                            value={detail?.company?.contact ? detail?.company?.contact?.toString() : contactNo}
                            onChange={setContactNo}
                            style={{
                              width: "380px",
                              height : "70%"
                          }}
                          flags={flags}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Company URL -{" "}
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder="www.company.com"
                          value={detail?.company?.url ? detail?.company?.url : companyUrl}
                          onChange={(e) => setCompanyUrl(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Company Location -{" "}
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper position-relative">
                        <button className="btn-black">
                          <img
                            src="/images/icons/icon-location.svg"
                            alt="location"
                            onClick={fetchLocation}
                          />
                        </button>
                        <GooglePlacesAutocomplete
                          addressFromLatLng={addressFromLatLng}
                          setAddressFromLatLng={setAddressFromLatLng}
                          location ={detail?.company?.location ? detail?.company?.location : ""}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <!-- stepper below --> */}
              <div className="stepper position-relative">
                <img
                  className="stepper-divider"
                  src="/images/icons/icon-stepper-divider.svg"
                  alt="divider"
                />
                <a href="" className="active-stepper-steps bg-c-green">
                  <div className="rotate-text">Company basic details</div>
                </a>
                <a href="" className="stepper-steps bg-c-green">
                  <div className="rotate-text">contact information</div>
                </a>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}
