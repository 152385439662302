import {  legacy_createStore as createStore, applyMiddleware, compose, combineReducers } from 'redux';
import authReducer from './Authentication/auth.reducer';
import { thunk } from 'redux-thunk';
import profileReducer from './Profile/profile.reducer';
import docReducer from './Documents/doc.reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    AuthManager: authReducer,
    ProfileManager: profileReducer,
    DocumentsManager: docReducer,
    
  });

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);

