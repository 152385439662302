import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../../assets_landing/styles/landing.css";
import { ToastContainer, toast } from "react-toastify";

export default function ThankYouPage() {
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `gtag('event', 'conversion', {'send_to': 'AW-16608198606/25CbCJHx97wZEM7_s-89'});`;
    document.head.appendChild(script);

    return () => {
        document.head.removeChild(script);
    };
}, []);

  const sectionRefs = {
    freedom: useRef(null),
    commitment: useRef(null),
    workflow: useRef(null),
    happiness: useRef(null),
    api: useRef(null),
    home: useRef(null),
    contact: useRef(null),
  };

  const scrollToSection = (section) => {
    setActiveLink(section);
    const ref = sectionRefs[section];
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <header className="headerSec">
        <div className="container">
          <nav>
            <a href="/" className="headerSec__logo">
              <img
                className="replaceWithSVG"
                src="/landing_images/logo.svg"
                alt="logo"
              />
            </a>

            <div className="techBorder pt-0 d-none d-lg-block">
              <span className="techBorder__leftTop"></span>
              <span className="techBorder__leftBot"></span>
              <span className="techBorder__leftDot"></span>
              <span className="techBorder__rightTop"></span>
              <span className="techBorder__rightBot"></span>
              <span className="techBorder__rightDot"></span>
              <ul>
                <li>
                  <Link
                    className={activeLink === "home" ? "active" : ""}
                    to="/#home"
                    onClick={() => scrollToSection("home")}
                  >
                    HOME
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "freedom" ? "active" : ""}
                    to="/#freedom"
                    onClick={() => scrollToSection("freedom")}
                  >
                    FREEDOM
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "commitment" ? "active" : ""}
                    to="/#commitment"
                    onClick={() => scrollToSection("commitment")}
                  >
                    COMMITMENTS
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "workflow" ? "active" : ""}
                    to="#workflow"
                    onClick={() => scrollToSection("workflow")}
                  >
                    WORK FLOW
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "happiness" ? "active" : ""}
                    to="#happiness"
                    onClick={() => scrollToSection("happiness")}
                  >
                    HAPPINESS
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "api" ? "active" : ""}
                    to="#api"
                    onClick={() => scrollToSection("api")}
                  >
                    API
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeLink === "contact" ? "active" : ""}
                    to="#contact"
                    onClick={() => scrollToSection("contact")}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
              <ToastContainer />
            </div>

            <div className="headerSec__buttons">
              <Link
                className="headerSec__buttons--green d-none d-lg-flex"
                to="/signIn"
              >
                LOGIN IN
              </Link>
              {/* <Link className="d-none d-lg-flex" to="/signIn">VERIFY NOW</Link> */}
              <Link
                className="d-lg-none menu-btn menu-btn--hamburger me-3"
              // to="javascript:;"
              >
                <img
                  className="menu-btn__icon1"
                  src="/landing_images/menu-btn.svg"
                  alt="menu"
                />
              </Link>
            </div>
          </nav>
        </div>
      </header>

      <div id="smooth-wrapper">
        <div id="smooth-content" className="position-relative">
          <div className="landing">
            <div style={{ paddingTop: "calc(100px* var(--resp))" }} className="clientsSec--carousel">
              <section className="storiesSec section">
                <div className="container">
                  <div className="row">
                    <div className="col-md-5">
                      <img
                        className="storiesSec__img"
                        src="/landing_images/stories/img1.svg"
                        alt="img1"
                      />
                    </div>
                    <div className="col-md-7">
                      <h2 className="section-head1 mt-3">
                        Thank you for inquiring about Docur
                      </h2>
                      <p className="section-desc">
                        Our quantum-proof decentralized solution for secure document management and record-keeping.
                      </p>
                      <p className="m-0">
                        Our team will contact you shortly to understand your needs and explore how our products can benefit your organization.
                      </p>
                      <p className="m-0">
                        We're committed to providing exceptional solutions that drive innovation and success.</p>
                      <p>
                        Stay tuned for a transformative journey with Docur.
                      </p>
                      <Link
                        className="headerSec__buttons--green d-none d-lg-flex"
                        to="/"
                      >
                        Go to Home
                      </Link>
                    </div>
                  </div>
                </div>

                <img
                  className="storiesSec__deco1"
                  src="/landing_images/stories/deco1.svg"
                  alt="decoration"
                />
              </section>
            </div>

            {/* <!-- .landing --> */}
          </div>
          {/* <!-- #smooth-content  --> */}
        </div>
        {/* <!-- #smooth-wrapper --> */}
      </div>
    </>
  )
}