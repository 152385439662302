import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_SUCCESS,
  AUTH_OTP_SUCCESS,
  AUTH_OTP_FAILURE,
  AUTH_GET_USER_DETAIL_SUCCESS,
  AUTH_GET_USER_DETAIL_FAILURE,
  ALL_USERS_FAILURE,
  ALL_USERS_SUCCESS,
  ALL_USERS_OPEN_SUCCESS,
  ALL_USERS_OPEN_FAILURE,
} from "./auth.actionTypes";

const initialState = {
  data: {
    isAuthenticated: false,
    otpSent: false,
    token: "",
    user: "",
    message: "",
  },
  userDetail: {},
  allUsers: null,
  allUsersOpen: null,
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case AUTH_OTP_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          otpSent: true,
          message: "",
          user: payload.email,
        },
      };
    case AUTH_OTP_FAILURE:
      return { ...state, data: { ...state.data, message: payload.message } };
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          isAuthenticated: true,
          token: payload.token,
          message: "",
        },
      };
    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          message: payload.message,
        },
      };

    case AUTH_GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: payload,
      };
    case AUTH_GET_USER_DETAIL_FAILURE:
      return {
        ...state,
        message: "",
      };

      case ALL_USERS_SUCCESS:
        return {
          ...state,
          allUsers: payload,
        };
      case ALL_USERS_FAILURE:
        return {
          ...state,
          message: "",
        };

        case ALL_USERS_OPEN_SUCCESS:
          return {
            ...state,
            allUsersOpen: payload,
          };
        case ALL_USERS_OPEN_FAILURE:
          return {
            ...state,
            message: "",
          };

    default:
      return state;
  }
}
