export const DOC_UPLOAD_SUCCESS = "doc/DOC_UPLOAD_SUCCESS";
export const DOC_UPLOAD_FAILURE = "doc/DOC_UPLOAD_FAILURE";
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_FAILURE = 'CREATE_FOLDER_FAILURE';
export const GET_FOLDER_SUCCESS = 'GET_FOLDER_SUCCESS';
export const GET_FOLDER_FAILURE = 'GET_FOLDER_FAILURE';
export const MOVE_FILE_TO_FOLDER_SUCCESS = 'MOVE_FILE_TO_FOLDER_SUCCESS';
export const MOVE_FILE_TO_FOLDER_FAILURE = 'MOVE_FILE_TO_FOLDER_FAILURE';
export const SHARE_DOCUMENT_SUCCESS = 'SHARE_DOCUMENT_SUCCESS';
export const SHARE_DOCUMENT_FAILURE = 'SHARE_DOCUMENT_FAILURE';
export const GET_SHARE_DOCUMENT_SUCCESS = 'GET_SHARE_DOCUMENT_SUCCESS';
export const GET_SHARE_DOCUMENT_FAILURE = 'GET_SHARE_DOCUMENT_FAILURE';
export const PATCH_DOC_UPLOAD_SUCCESS = "doc/PATCH_DOC_UPLOAD_SUCCESS";
export const PATCH_DOC_UPLOAD_FAILURE = "doc/PATCH_DOC_UPLOAD_FAILURE";
export const EDIT_FOLDER_SUCCESS = 'EDIT_FOLDER_SUCCESS';
export const EDIT_FOLDER_FAILURE = 'EDIT_FOLDER_FAILURE';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_FAILURE = 'DELETE_FOLDER_FAILURE';