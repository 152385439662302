import React, { useState, useEffect } from "react";
import TopNavBar from "../../../layouts/topNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../../redux/Authentication/auth.action";

export default function OrgDetailedInformation1() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const userDetail = store?.AuthManager?.userDetail?.userdetail;
  const [detail, setDetail] = useState(null);

  const [mainProduct, setMainProduct] = useState("");
  const [companyType, setCompanyType] = useState(1);
  const [workingEmployee, setWorkingEmployee] = useState(null);
  const [checkbox, setCheckbox] = useState(null);

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  useEffect(() => {
    if (userDetail?.name && userDetail?.company) {
      setDetail({ ...userDetail });
    }
  }, [userDetail]);

  // Function to handle selection change
  const handleSelectChange = (event) => {
    const selectedValue = parseInt(event.target.textContent.trim(), 10);
    setWorkingEmployee(selectedValue);
  };

  // Function to handle checkbox selection
  // const handleCheckboxChange = (event) => {
  //   const { id, checked } = event.target;
  //   if (id === "product-based") {
  //     setCompanyType(checked ? 2 : 1);
  //   } else if (id === "service-based"){
  //     setCompanyType(checked ? 1 : 2);
  //   }else{
  //     setCompanyType(checked ? 1 : 2);
  //   }
  // };

  const handleCheckboxChange = async (event) => {
    const { id, checked } = event.target;
    if (id === "product-based") {
      setCheckbox((prevState) => ({
        ...prevState,
        productBased: checked,
      }));
    } else if (id === "service-based") {
      setCheckbox((prevState) => ({
        ...prevState,
        serviceBased: checked,
      }));
    }

    if (checkbox?.productBased && checkbox?.serviceBased) {
      await setCompanyType(3);
    } else if (checkbox?.productBased) {
      await setCompanyType(1);
    } else if (checkbox?.serviceBased) {
      await setCompanyType(2);
    }
  };

  const handleSkip = () => {
    navigate("/org-preview");
  };
  const handleNext = () => {
    const data2 = {
      ...state?.orgData,
      product: mainProduct ? mainProduct : "NA",
      industry: companyType ? companyType : "NA",
      total_emp: workingEmployee ? workingEmployee : "NA",
    };
    navigate("/org-preview", {
      state: { orgData: data2, userData: state?.userData },
    });
  };

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <aside>
            <div className="blurred-widget-block">
              <div className="inner-div-sidebar">
                <div className="profile-status">
                  <div className="inner-ps d-flex justify-content-center align-items-center">
                    <img src="/images/icons/icon-circle.svg" alt="cirle" />
                    <span className="tilte fw600 fz14">Profile Status</span>
                    <span className="tilte-percentage fw400 fz14 ms-4">
                      30%
                    </span>
                  </div>
                </div>
                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img
                        src="/images/icons/icon-checked.svg"
                        alt="un-checked"
                      />
                      <span className="inner-title">Basic Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal
                      assistance and sharing
                    </p>
                  </div>
                </div>

                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img
                        src="/images/icons/icon-checked.svg"
                        alt="un-checked"
                      />
                      <span className="inner-title">Detailed Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal
                      assistance and sharing
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="green-theme-block">
              <img
                src="/images/illustrations/image-coffee-together.svg"
                alt=""
                className="mx-auto"
              />

              <h2 className="mb-0 font-secondary fw600 text-white fz16">
                Let’s have coffee together
              </h2>

              <p className="mb-0 fz10">
                In need of any sort of assistance? We are here to help you!
              </p>

              <button className="text-uppercase fw600 fz10 bdrs10 btn btn-solid btn-solid--green">
                <span className="lh-1">Let’s chat!</span>
              </button>
            </div>
          </aside>
          <section className="body-profile2">
            <div className="title">
              <img
                className="d-inline-flex"
                src="/images/icons/icon-left-awrrow.svg"
                alt=""
              />
              <span className="h-title-teal">Organization</span>
              <span className="h-title-black"> - Detailed Information</span>
            </div>
            <div className="body-context height-adjust-context">
              <div className="btn-wrapper">
                <button className="custom-btn btn-light" onClick={handleSkip}>
                  skip {">"}
                </button>
                <button className="custom-btn btn-dark" onClick={handleNext}>
                  Next
                </button>
              </div>
              <div className="inner-body-context">
                <h6 className="heading-profiles">Tell us more</h6>
                <p className="details-grey">
                  Please select the basic information about you so we may know
                  you better
                </p>
                <img
                  className="mx-auto mt-2"
                  src="/images/horizontal-line.svg"
                  alt="line"
                />
                <form className="docur-form">
                  {/* <div className="form-row">
                  <label className="form-level d-flex align-items-center"
                    >Your Name -
                  </label>
                  <div className="blurred-widget-block">
                    <div className="input-wrapper">
                      <input type="text" placeholder="john slay" />
                    </div>
                  </div>
                </div> */}

                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Company Type -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper p-0">
                        <div className="options-wrapper-checkbox">
                          <div className="lh-1 checkbox-wrapper custom-checkbox">
                            <input
                              id="product-based"
                              type="checkbox"
                              disabled={
                                detail?.company?.industry === 2 ? true : false
                              }
                              checked={
                                detail?.company?.industry === 1
                                  ? true
                                  : detail?.company?.industry === 3
                                  ? true
                                  : companyType.productBased
                              }
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor="product-based">
                              <span></span>
                              <div>
                                <b className="checkbox-label">Products Based</b>
                              </div>
                            </label>
                          </div>
                          <div className="lh-1 checkbox-wrapper custom-checkbox">
                            <input
                              id="service-based"
                              type="checkbox"
                              disabled={
                                detail?.company?.industry === 1 ? true : false
                              }
                              checked={
                                detail?.company?.industry === 2
                                  ? true
                                  : detail?.company?.industry === 3
                                  ? true
                                  : companyType.productBased
                              }
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor="service-based">
                              <span></span>
                              <div>
                                <b className="checkbox-label">Service Based</b>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Main Product/Service Offered -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder="Separate with (,) comma"
                          value={
                            detail?.company?.product
                              ? detail?.company?.product
                              : mainProduct
                          }
                          onChange={(e) => setMainProduct(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Working Employee -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <div className="dropdown dropdown-inputs">
                          <a
                            className="position-relative form-control form-control--link border-0"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="fw400 fz12 grayText fst-italic">
                              {detail?.company?.total_emp
                                ? detail?.company?.total_emp
                                : workingEmployee || "Select"}
                            </span>
                            <div className="btn-fab-dropdown">
                              <img
                                src="/images/icons/icon-dropdown-arrow.svg"
                                alt=""
                              />
                            </div>
                          </a>

                          <div className="w-100 dropdown-menu">
                            <ul className="list-inline mb-0 product-type-list">
                              <li>
                                <a
                                  // href="#"
                                  className="typr-pair"
                                  onClick={handleSelectChange}
                                >
                                  <span className="fz14"> 100 - 500</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  // href="#"
                                  className="typr-pair active"
                                  onClick={handleSelectChange}
                                >
                                  <span className="fz14"> 500 - 1000</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  // href="#"
                                  className="typr-pair"
                                  onClick={handleSelectChange}
                                >
                                  <span className="fz14">1000 - 2000</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="typr-pair"
                                  onClick={handleSelectChange}
                                >
                                  <span className="fz14"> 2000 - 5000</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <!-- stepper below --> */}
              <div className="stepper position-relative">
                <img
                  className="stepper-divider"
                  src="/images/icons/icon-stepper-divider.svg"
                  alt="divider"
                />
                <div className="active-stepper-steps bg-c-green">
                  <div className="rotate-text">
                    wants to know about yourself
                  </div>
                </div>
                <div className="stepper-steps bg-c-green">
                  <div className="rotate-text">Few more things</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}
