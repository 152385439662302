export const ADD_INDV_PROFILE_SUCCESS  = "profile/ADD_INDV_PROFILE_SUCCESS";
export const ADD_INDV_PROFILE_FAILURE  = "profile/ADD_INDV_PROFILE_FAILURE";
export const ADD_ORG_PROFILE_SUCCESS  = "profile/ADD_BOTH_PROFILE_SUCCESS";
export const ADD_ORG_PROFILE_FAILURE  = "profile/ADD_BOTH_PROFILE_FAILURE";
export const ADD_BOTH_PROFILE_SUCCESS  = "profile/ADD_BOTH_PROFILE_SUCCESS";
export const ADD_BOTH_PROFILE_FAILURE  = "profile/ADD_BOTH_PROFILE_FAILURE";
export const ADD_INDV_PROFILE_IN_ORG_SUCCESS  = "profile/ADD_INDV_PROFILE_IN_ORG_SUCCESS";
export const ADD_INDV_PROFILE_IN_ORG_FAILURE  = "profile/ADD_INDV_PROFILE_IN_ORG_FAILURE";


