import React, { useState, useEffect } from "react";
import TopNavBar from "../../layouts/topNavBar";
import SideNavBar from "../../layouts/sideNavBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allSharedDocuments,
  deleteFolder,
  getAllDocuments,
  getAllFolders,
  moveFileToFolder,
  shareDocument,
} from "../../redux/Documents/doc.action";
import {
  capitalizeFirstLetter,
  convertTime,
  formatDate,
  formatHour,
  formatMinute,
  truncateString,
  truncateString1,
  truncateURL,
} from "../../services/utils";
import BarGraph from "../../charts/barGraph";
import NewFolder from "./newFolder";
import { ToastContainer } from "react-toastify";
import { HASH_URL, IMAGE_PROXY_URL } from "../../services/Api";
import EditFolderName from "./editFoldernName";

export default function MyDocuments() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [myDocs, setMyDocs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(new Set());
  const [chartArray, setChartArray] = useState([]);
  const [folderData, setFolderData] = useState([]);
  const [folderArray, setFolderArray] = useState([]); //display folder items
  const [selectAll, setSelectAll] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedEmails, setSelectedEmails] = useState("");
  const [hoveredFolder, setHoveredFolder] = useState(null);
  const [clickedFolder, setClickedFolder] = useState(0);
  const [selectedFolderName, setSelectedFolderName] = useState("");
  const [clickCreate, setClickCreate] = useState(false);
  const [clickEdit, setClickEdit] = useState(false);
  const [folderId, setFolderId] = useState(null);

  const store = useSelector((state) => state);
  const allDocuments = store?.DocumentsManager?.myDocuments;
  const allFolders = store?.DocumentsManager?.allFolders;
  const allSharedDocs = store?.DocumentsManager?.allSharedDocs;

  useEffect(() => {
    dispatch(getAllDocuments());
    dispatch(allSharedDocuments());
    dispatch(getAllFolders());
  }, []);

  useEffect(() => {
    if (allDocuments) {
      setFolderData(allDocuments);
      const docs = allDocuments.reverse();
      setMyDocs(docs);
      setSearchResults(docs);
    }
  }, [allDocuments]);

  console.log("alldocuments ---------->69",allDocuments )

  // set total file count in every folder
  useEffect(() => {
    if (folderData) {
      const updatedArr1 = allFolders?.map((obj) => {
        obj.totalFiles = 0;
        folderData?.forEach((item) => {
          if (item?.folder?.id === obj?.id) {
            obj.totalFiles++;
          }
        });
        return obj;
      });
      updatedArr1 && setFolderArray(updatedArr1);
    }
  }, [folderData]);

  // handle search event
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const results = performSearch(value);
    setMyDocs(results);
  };

  function performSearch(value) {
    return searchResults?.filter(
      (el) =>
        el?.id?.toString()?.includes(value) ||
        truncateURL(el?.file?.toLowerCase())?.includes(value.toLowerCase())
    );
  }

  const handleFileSelect = (fileId) => {
    const updatedSelectedFiles = new Set(selectedFiles);
    if (updatedSelectedFiles.has(fileId)) {
      updatedSelectedFiles.delete(fileId);
    } else {
      updatedSelectedFiles.add(fileId);
    }
    setSelectedFiles(updatedSelectedFiles);

    // Check if all checkboxes are selected
    const areAllSelected = myDocs.every((doc) =>
      updatedSelectedFiles.has(doc.id)
    );
    setSelectAll(areAllSelected);
  };

  const handleDownload = async () => {
    if (selectedFiles.size === 0) {
      console.log("Please select at least one file to download.");
    } else {
      for (const fileId of selectedFiles) {
        const selectedFile = myDocs.find((doc) => doc.id === fileId);
        if (selectedFile) {
          try {
            const response = await fetch(selectedFile.file);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement("a");
            downloadLink.href = blobUrl;
            downloadLink.setAttribute(
              "download",
              selectedFile.file.split("/").pop()
            );
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(blobUrl);
          } catch (error) {
            console.error("Error downloading file:", error);
          }
        }
      }
    }
  };

  const handleSelectAll = () => {
    const updatedSelectedFiles = new Set(selectedFiles);
    if (!selectAll) {
      myDocs.forEach((doc) => updatedSelectedFiles.add(doc.id));
    } else {
      myDocs.forEach((doc) => updatedSelectedFiles.delete(doc.id));
    }
    setSelectedFiles(updatedSelectedFiles);
    setSelectAll(!selectAll);
  };

  const handleNavigate = () => {
    navigate("/upload-document");
  };

  //handle chart data
  useEffect(() => {
    if (allDocuments) {
      const currentDate = new Date();
      const filteredArray = allDocuments.filter((obj) => {
        const documentDate = new Date(obj.created_at);
        return documentDate < currentDate;
      });

      const countsByMonth = {};
      filteredArray.forEach((obj) => {
        const month = new Date(obj.created_at).getMonth();
        countsByMonth[month] = (countsByMonth[month] || 0) + 1;
      });

      const dataArray = Array.from(
        { length: 12 },
        (_, i) => countsByMonth[i] || 0
      );

      setChartArray(dataArray);
    }
  }, [allDocuments]);

  const data = chartArray ? chartArray : Array.fill(0);

  // handle drag and drop functionality
  const handleDragStart = (event, id) => {
    event.dataTransfer.setData("id", id.toString());
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    setHoveredFolder(index);
  };

  const handleDragLeave = () => {
    setHoveredFolder(null);
  };

  const handleDrop = async (event, folderName) => {
    event.preventDefault();
    const fileId = parseInt(event.dataTransfer.getData("id"));

    const folder = allFolders.find((folder) => folder.name === folderName);
    if (!folder) {
      return;
    }
    const folderId = folder.id;

    const newMyDocs = myDocs.map((item) => {
      if (item.id === fileId) {
        return { ...item, folder: folderName };
      }
      return item;
    });
    setMyDocs(newMyDocs);
    dispatch(moveFileToFolder(fileId, folderId));
  };

  // handle share document
  const handleOpenShareModal = () => {
    setEmail("");
    setSelectedEmails([]);
  };

  const openShareFolder =()=>{
    setEmail("");
    setSelectedEmails([]);
  }

  const handleAddEmail = (e) => {
    e.preventDefault();
    const array = [];
    array.push(email);
    if (array?.length) {
      setSelectedEmails((prevEmails) => [...prevEmails, ...array]);
      setEmail("");
    }
  };

  const handleShareDocument = () => {
    let setArray = Array.from(selectedFiles);
    let firstElementString = setArray.length > 0 ? setArray[0].toString() : ""; // Convert first element to string
    const payLoad = {
      file_id: firstElementString,
      email: selectedEmails,
    };
    dispatch(shareDocument(payLoad));
    setSelectedFiles(new Set());
  };

  // Default folder containing all documents
  let defaultFolder = {
    name: "All Docs",
    totalFiles: allDocuments?.length,
  };

  const mergedFolderArray = [defaultFolder, ...folderArray];

  const handleFolderClick = (index, name) => {
    setClickedFolder(index);
    setSelectedFolderName(name);
    filterFolderData(name);
  };

  const filterFolderData = (folderName) => {
    const result = searchResults
      ?.filter((item) => item?.folder !== null)
      ?.filter((item1) => item1?.folder?.name === folderName);
    if (folderName === "All Docs") {
      setMyDocs(searchResults);
    } else {
      setMyDocs(result);
    }
  };

  const handleEdit =(id)=>{
    setFolderId(id)
    setClickEdit(true)
  }

  const handleDelete =(id)=>{
    dispatch(deleteFolder(id)) 
    setTimeout(()=>{
      dispatch(getAllDocuments());
      dispatch(getAllFolders());
    },1000)
  }

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />
      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <SideNavBar />
          <section className="mt-0 body-profile2">
            <div className="w-100 line-ch-row">
              <div className="blurred-widget-block bg-white position-relative line-ch-row__chart-box">
                <img
                  className="top-left"
                  src="/images/bg-particles/dashboard-chart-left-top-knot.svg"
                  alt=""
                />
                <img
                  className="bottom-right"
                  src="/images/bg-particles/dashboard-chart-right-bottom-knot.svg"
                  alt=""
                />

                <div className="widget-title-top">
                  <div className="widget-title-top__left">
                    <h4 className="mb-0 lh1-5 fw600 fz16 font-secondary">
                      My Documents
                    </h4>
                    <p className="mb-0 fz12 grayText">
                      Total Documents Uploaded:
                      <span className="fw600 greenThemeText">
                        {" "}
                        {allDocuments?.length}
                      </span>
                    </p>
                  </div>
                  <div className="widget-title-top__right">
                    {/* <ul className="mb-0 list-inline anchor-tag-list">
                      <li>
                        <div className="lightgreenThemeText">
                          <a href="javascript:void(0);">
                            <img
                              src="/images/icons/icon-cross-green-light.svg"
                              alt=""
                            />
                          </a>
                          <span>Yearly</span>
                        </div>
                      </li>
                      <li>
                        <div className="lightgreenThemeText">
                          <a href="javascript:void(0);">
                            <img
                              src="/images/icons/icon-cross-green-light.svg"
                              alt=""
                            />
                          </a>
                          <span>Shared Documents</span>
                        </div>
                      </li>
                      <li>
                        <div className="lightgreenThemeText">
                          <a href="javascript:void(0);">
                            <img
                              src="/images/icons/icon-cross-green-light.svg"
                              alt=""
                            />
                          </a>
                          <span>Verified documents</span>
                        </div>
                      </li>
                    </ul> */}

                    {/* <a href="#" className="position-relative filter-reverse">
                      <img src="/images/icons/icon-filter.svg" alt="" />
                      <div className="position-absolute rounded-circle dot"></div>
                    </a>

                    <a href="#" className="calendar">
                      <img src="/images/icons/icon-table-calender.svg" alt="" />
                    </a> */}
                  </div>
                </div>

                <div className="chart-stats">
                  <div className="num num--green greenThemeText">
                    <span className="lh1 fz28 font-tertiary">
                      {allDocuments?.length ? allDocuments?.length : "00"}
                    </span>
                    <p className="mb-0 text-capitalize fz12 grayText">
                      Uploaded Documents
                    </p>
                  </div>
                  <div className="num num--red">
                    <span className="lh1 fz28 font-tertiary">
                      {allSharedDocs?.length ? allSharedDocs?.length : "00"}
                    </span>
                    <p className="mb-0 text-capitalize fz12 grayText">
                      Shared Documents
                    </p>
                  </div>
                </div>

                <div className="w-100 h-100 position-relative bar-chart-area">
                  {/* <canvas id="barChartRounded"></canvas> */}
                  <BarGraph data={data} />
                  <div className="custom-tooltip"></div>
                </div>
              </div>

              <div className="p-0 theme-feature-widget background-none border-0 side-cards-container">
                <div className="blur-2 shadow-34-05 dark-theme-block">
                  <div className="dark-theme-block__inner">
                    <h4 className="font-secondary text-capitalize fw800 fz20 mb-0 text-white">
                      Upload and verify your <br />
                      documents Anytime!
                    </h4>

                    <p className="mb-1 neutral-para fz12 grayText">
                      Tips and Tricks with real life projects and case studies.
                    </p>

                    <button
                      className="ps-3 w-100 border-0 blurred-widget-block bdrs10 bg-white position-relative upload-docs"
                      onClick={handleNavigate}
                    >
                      <div className="btn btn-solid btn-solid--green bdrs10">
                        <img
                          src="/images/icons/icon-upload-cloud-white.svg"
                          alt=""
                        />
                      </div>
                      <span className="ps-3 ms-3 text-uppercase fw600 fz10 greenThemeText">
                        Upload your documents
                      </span>
                    </button>
                  </div>
                </div>

                <div className="mt-3 blur-2 shadow-34-05 light-green-theme-block">
                  <div className="light-green-theme-block__inner">
                    <h4 className="font-secondary text-capitalize fw800 fz20 mb-0">
                      Create Folder to <br /> categories your files!
                    </h4>

                    <p className="mb-1 neutral-para fz12 grayText">
                      Tips and Tricks with real life projects and case studies.
                    </p>

                    <button
                      className="w-100 blurred-widget-block bdrs10 bg-white position-relative upload-docs"
                      data-bs-toggle="modal"
                      data-bs-target="#create-folder"
                      onClick={() => setClickCreate(true)}
                    >
                      <div className="btn btn-solid btn-solid--dark bdrs10">
                        <img
                          src="/images/icons/icon-create-folder-white.svg"
                          alt=""
                        />
                      </div>
                      <span className="text-uppercase fw600 fz10 text-start">
                        Create new folder
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 my-folder-wrapper">
              <h6 className="m-0 fw600 fz16 primaryDarkText font-secondary">
                My Folders
              </h6>
              <div className="mb-3 fw400 fz12 grayText">
                Total Folders :
                <span className="fz12 fw700"> {allFolders?.length}</span>
              </div>
              <div>
                <div className="w-10 justify-content-start folder-flex-div">
                  {mergedFolderArray &&
                    mergedFolderArray.map((item, index) => (
                      <div
                        className="folder-img-wrapper"
                        key={index}
                        onClick={() => handleFolderClick(index, item?.name)}
                        onMouseEnter={() => setHoveredFolder(index)}
                        onMouseLeave={() => setHoveredFolder(null)}
                      >
                        <img
                          className="folder-bg"
                          src="/images/light-grey-folder.svg"
                          alt="light-grey-folder.svg"
                          style={{
                            // background: clickedFolder === index ? 'var(--text-dark)' : 'initial',
                            background:
                              clickedFolder === index ? "#36baa4" : "initial",
                            borderRadius: "5px",
                            padding: "3px",
                          }}
                        />

                        <button
                          class=""
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {index !== 0 && (
                            <img
                              className="menu-img"
                              src="/images/icons/icon-menu.svg"
                              alt="icons/icon-menu.svg"
                            />
                          )}
                        </button>

                        <div class="dropdown-menu blurred-widget-block bdr-gray blur-7  dropdown-custom">
                          <ul class="p-0 list-inline td-action">
                            {/* <li>
                              <a class="td-action__link active">
                                <span>
                                  <svg
                                    class="stroke"
                                    width="16"
                                    height="12"
                                    viewBox="0 0 16 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M9.90099"
                                    />
                                  </svg>
                                </span>
                                <strong class="fz12 fw400"  data-bs-toggle="modal"
                      data-bs-target="#sharewith02" onClick={openShareFolder}>Share</strong>
                              </a>
                            </li> */}
                            <li>
                              <a class="td-action__link">
                                <span>
                                  <svg
                                    class="fill"
                                    width="12"
                                    height="16"
                                    viewBox="0 0 12 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path />
                                  </svg>
                                </span>
                                <strong class="fz12 fw400"  onClick={() => handleDelete(item?.id)}>Delete</strong>
                              </a>
                            </li>

                            <li>
                              <a class="td-action__link">
                                <span>
                                  <svg
                                    width="14"
                                    height="15"
                                    viewBox="0 0 14 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d=""
                                      class="stroke"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d=""
                                      class="stroke"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path d="M12.6667" class="fill" />
                                  </svg>
                                </span>
                       
                                <strong class="fz12 fw400"
                                        //  className="w-100 blurred-widget-block bdrs10 bg-white position-relative upload-docs"
                                         data-bs-toggle="modal"
                                         data-bs-target="#edit-folder"
                                         onClick={() => handleEdit(item?.id)}
                                >Edit Name</strong>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <p
                          className="m-0 fw500 fz12 bt-title"
                          style={{
                            color:
                              hoveredFolder === index || clickedFolder === index
                                ? "teal"
                                : "gray",
                            fontSize: hoveredFolder === index ? "12px" : "11px",
                          }}
                          onDrop={(e) => handleDrop(e, item?.name)}
                          onDragOver={(e) => handleDragOver(e, index)}
                          onDragLeave={() => handleDragLeave()}
                        >
                          {truncateString1(capitalizeFirstLetter(item?.name))}
                        </p>
                        <div className="mt-1 fw400 fz12 text-center">
                          Total Items:
                          <span className="fw500 fz12 greenThemeText">
                            {" "}
                            {item?.totalFiles}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="w-100 blurred-widget-block">
              <div className="d-flex custom-tabletop p-3">
                <div className="fw600 fz16 primaryDarkText font-secondary d-flex flex-column justify-content-center">
                  {selectedFolderName
                    ? selectedFolderName?.toUpperCase()
                    : "All DOCS"}
                  {/* My Document Listing */}
                  <br />
                  <p className="m-0 fw400 fz12 grayText font-primary">
                    <span className="fw400 fz12">
                      Avg Rate :
                      <span className="fw600 greenThemeText">2.98%</span>
                    </span>
                  </p>
                </div>
                <div className="d-flex">
                  <div className="blur-2 form-group form-group--search">
                    <div className="bdrs12 fab fab--search">
                      <img
                        src="/images/icons/icon-search-pastel-green.svg"
                        alt="Search"
                      />
                    </div>

                    <input
                      type="text"
                      className="border-0 bdrs12 fz14 form-control"
                      placeholder="Search your document here..."
                      value={searchTerm}
                      onChange={handleSearch}
                    />

                    <ul className="m-0 lh-1 list-inline icons">
                      <li>
                        {/* <a href="#" className="custom-width">
                          <span className="dot"></span>
                          <img
                            src="/images/icons/icon-reset.svg"
                            alt="icon-reset"
                          />
                        </a> */}
                      </li>
                      <li>
                        {/* <a href="#" className="custom-width">
                          <img
                            src="/images/icons/icon-calender-checked.svg"
                            alt=""
                          />
                        </a> */}
                      </li>
                    </ul>
                  </div>
                  <div className="ms-3 p-3 table-top-btn-grp bdrs10">
                    <button>
                      <img
                        src="/images/icons/icon-delete.svg"
                        alt="icon-delete"
                      />
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#sharewith01"
                      onClick={handleOpenShareModal}
                    >
                      <img
                        src="/images/icons/icon-forward.svg"
                        alt="icon-forward"
                      />
                    </button>
                    <button onClick={handleDownload}>
                      <img
                        src="/images/icons/icon-download-light.svg"
                        alt="icon download"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {/* <!-- table --> */}
              <div className="px-3 table-responsive">
                <table className="custom-dataTable dashboard-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="lh-1 d-inline-block checkbox-wrapper">
                          <input
                            id="all-00"
                            name="select-all"
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                          <label for="all-00">
                            <span></span>
                            <div>
                              <b className="fw400 fz12 grayText">All</b>
                            </div>
                          </label>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <span className="fw400 fz12 greenThemeText">#</span>
                          <span className="fw400 fz12">ID</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-emailbox.svg"
                            alt="icon-emailbox.svg"
                          />
                          <span className="fw400 fz12 grayText">Name</span>
                        </div>
                      </th>
                      {/* <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-verfication.svg"
                            alt="icon-verfication.svg"
                          />
                          <span className="fw400 fz12 grayText">
                            Verification
                          </span>
                        </div>
                      </th> */}
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-verfication.svg"
                            alt="icon-verfication.svg"
                          />
                          <span className="fw400 fz12 grayText">Hash ID</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-green-profile.svg"
                            alt="icon-verfication.svg"
                          />
                          <span className="fw400 fz12 grayText">Doc Owner</span>
                        </div>
                      </th>
                      {/* <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-calender.svg"
                            alt="icon-calender.svg"
                          />
                          <span className="fw400 fz12 grayText">Folder</span>
                        </div>
                      </th> */}
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-calender.svg"
                            alt="icon-calender.svg"
                          />
                          <span className="fw400 fz12 grayText">Date</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-calender.svg"
                            alt="icon-calender.svg"
                          />
                          <span className="fw400 fz12 grayText">Type</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-statusx.svg"
                            alt="icon-status.svg"
                          />
                          <span className="fw400 fz12 grayText">Status</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/icons/icon-flag.svg"
                            alt="icon-flag.svg"
                          />
                          <span className="fw400 fz12 grayText">Action</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {myDocs &&
                      myDocs?.map((item) => (
                        <tr key={item?.id}>
                          <td>
                            <div className="lh-1 d-inline-block checkbox-wrapper">
                              <input
                                id={`select-${item.id}`}
                                name={`select-${item.id}`}
                                type="checkbox"
                                checked={selectedFiles.has(item.id)}
                                onChange={() => handleFileSelect(item.id)}
                              />
                              <label htmlFor={`select-${item.id}`}>
                                <span></span>
                              </label>
                            </div>
                          </td>
                          <td className="fz12 fw400 primaryDarkText">
                            {item?.id}
                          </td>
                          <td
                            className="fz12 fw400 primaryDarkText"
                            draggable
                            onDragStart={(e) => handleDragStart(e, item.id)}
                          >
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${IMAGE_PROXY_URL}${item?.file}`,
                                  "_blank"
                                );
                              }}
                              className="fz12 fw400 greenThemeText fst-italic"
                              target="_blank"
                              rel="noopener noreferrer" // Recommended for security reasons
                            >
                              {truncateString(
                                item?.file?.substring(
                                  item?.file?.lastIndexOf("/") + 1
                                )
                              )}
                            </a>{" "}
                          </td>
                          {/* {!item?.id ? ( //replace id with document key verified
                            <td>
                              <div className="verification-status">
                                <img
                                  src="/images/icons/icon-verified.svg"
                                  alt=""
                                />
                                <span className="text-uppercase fw500 fz10 greenThemeText">
                                  Verified
                                </span>
                              </div>
                            </td>
                          ) : (
                            <td className="fz12 fw400 primaryDarkText">
                              <div className="pending-div">
                                <img
                                  src="/images/icons/icon-pending.svg"
                                  alt="pending"
                                />
                                <span>Pending</span>
                              </div>
                            </td>
                          )} */}
                          <td>
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${HASH_URL}/${item?.mc_hash}`,
                                  "_blank"
                                );
                              }}
                              className="fz12 fw400 greenThemeText fst-italic"
                              style={{
                                fontSize: "11px",
                                transition: "font-size 0.2s",
                              }}
                              target="_blank"
                              rel="noopener noreferrer" // Recommended for security reasons
                            >
                              <span
                                style={{ fontSize: "11px" }}
                                onMouseOver={(e) =>
                                  (e.currentTarget.style.fontSize = "12px")
                                }
                                onMouseOut={(e) =>
                                  (e.currentTarget.style.fontSize = "11px")
                                }
                              >
                                {item?.mc_hash
                                  ? truncateString(item?.mc_hash)
                                  : "NA"}
                              </span>
                            </a>
                          </td>
                          <td className="fz12 fw400 primaryDarkText">
                            <span>{item?.about}</span>
                          </td>
                          {/* <td className="fz12 fw400 primaryDarkText">
                            <span>
                              {item?.folder?.name ? item?.folder?.name : "NA"}
                            </span>
                          </td> */}
                          <td
                            className="fz12 fw400 primaryDarkText"
                            onDragOver={(e) => handleDragOver(e)}
                            onDrop={(e) => handleDrop(e, item.file)}
                          >
                            {formatDate(item?.created_at)}
                            <br />
                            {/* <span className="lightGrey">
                              {formatHour(item?.created_at)} :{" "}
                              {formatMinute(item?.created_at)}{" "}
                              {formatHour(item?.created_at) >= 12
                                ? "PM"
                                : "AM"}
                            </span> */}
                                   <span className="lightGrey">
                                 {convertTime(item?.created_at)}
                                </span>
                          </td>
                          <td className="fz12 fw400 primaryDarkText">
                            <div className="p-instructions">
                              {item?.file?.split(".")?.pop()?.toLowerCase() ===
                              "jpg" ? (
                                <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                                  .{item?.file?.split(".").pop()}
                                </span>
                              ) : item?.file
                                  ?.split(".")
                                  .pop()
                                  ?.toLowerCase() === "pdf" ? (
                                <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--png">
                                  .{item?.file?.split(".").pop()}
                                </span>
                              ) : (
                                <span className="text-uppercase text-center p-instructions__format fz10 fw600 text-white p-instructions__format--docx">
                                  .{item?.file?.split(".").pop()}
                                </span>
                              )}
                            </div>
                          </td>
                          <td className="fz12 fw400 primaryDarkText">
                            {item?.permission === "Private" ? (
                              <div className="status-div">
                                {" "}
                                <img
                                  src="/images/icons/icon-user-red.svg"
                                  alt=""
                                />
                                {item?.permission}
                              </div>
                            ) : (
                              <div className="status-div">
                                <img
                                  src="/images/icons/icon-public.svg"
                                  alt="public"
                                />
                                {item?.permission}
                              </div>
                            )}
                          </td>
                          <td>
                            {allSharedDocs &&
                              (() => {
                                let count = 0;
                                allSharedDocs.forEach((el) => {
                                  if (el?.file?.id === item.id) {
                                    count++;
                                  }
                                });
                                return (
                                  <div className="table-actions p-0">
                                    <div className="table-action-innerdiv">
                                      <span>{count > 99 ? "99+" : count}</span>
                                      <img
                                        src="/images/icons/icon-grey-eye.svg"
                                        alt="eye"
                                      />
                                    </div>
                                    {/* <img
                                      src="/images/icons/icon-menu.svg"
                                      alt="icon-menu"
                                    /> */}
                                  </div>
                                );
                              })()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </article>
      <ToastContainer />
      <NewFolder props={{ clickCreate, setClickCreate }} />
      <EditFolderName props={{ clickEdit, setClickEdit, folderId }} />

      {/* Shared Documents */}
      <div
        className="modal fade"
        id="sharewith01"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered model-custom-centered">
          <div className="modal-content mydoc1-modal-wrapper width40rem">
            <div className="mydoc1-modal-content">
              <img
                className="orange-wave"
                src="/images/orange-wave.svg"
                alt=""
              />
              <img className="blue-wave" src="/images/blue-wave.svg" alt="" />
              <div className="cut-icon-wrapper">
                <button
                  className="btn p-0 rounded-circle border-0 shadow-none cut-icon"
                  data-bs-dismiss="modal"
                >
                  <img src="/images/icons/icon-cross-red.svg" alt="cut" />
                </button>
              </div>

              <div className="mydoc1-modal-header">
                <h3>Share with</h3>
              </div>
              <p className="details custom-details-width">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut.
              </p>
              <div className="modal-form">
                <div className="dropdown custom-model-dropdown">
                  <button
                    className="justify-content-between ps-3 fz14 btn dropdown-toggle shadow-15-11-08 bdrs12 btn-solid btn-solid--dark"
                    type="button"
                    // data-bs-toggle="dropdown"
                    // aria-expanded="false"
                    onClick={handleAddEmail}
                  >
                    {/* Can Share */}
                    Add Email
                    <i className="fa fa-angle-down"></i>
                  </button>
                  {/* <div className="border-0 bdrs10 mt-1 dropdown-menu dropdown-menu--modal shadow-15-11-08">
                    <a href="#" className="dropdown-item">
                      A
                    </a>
                    <a href="#" className="dropdown-item">
                      B
                    </a>
                    <a href="#" className="dropdown-item">
                      C
                    </a>
                    <a href="#" className="dropdown-item">
                      D
                    </a>
                  </div> */}
                </div>
                <input
                  className="me-3 fz14 fw400 folder-input custom-input-width"
                  type="text"
                  placeholder="Write email address to sen invitation link..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="w-100 mt-3 d-flex justify-content-between">
                <span className="grayText fz12 fw400">
                  User can edit or share document..
                </span>
                <span className="greenThemeText fz14 fw500">
                  {" "}
                  <img
                    className="d-inline"
                    src="/images/icons/icon-pin.svg"
                    alt="icon-pin.svg"
                  />{" "}
                  Copy Direct sharing link
                </span>
              </div>

              <div className="w-100 mx-3 modal-table-wrapper">
                <h3 className="fw600 fz16 table-heading mb-0">
                  Invitation Will Sent to
                </h3>
                <p className="fw400 fz12 primaryText m-0 total-inv">
                  Total invitations :
                  <span className="fw600 fz12 greenThemeText">
                    {selectedEmails?.length}
                  </span>
                </p>
                {selectedEmails &&
                  selectedEmails?.map((item) => (
                    <div className="d-flex justify-content-between mt-3 px-2">
                      <div className="fw500 fz12">
                        <img
                          className="d-inline"
                          src="/images/icons/icon-profile.svg"
                          alt="icon-profile.svg"
                        />
                        {item}
                      </div>
                      {/* <div className="fz12 fw400 grayText">
   <img
     className="d-inline"
     src="/images/icons/icon-emailbox.svg"
     alt="email"
   />
   <span>Owner</span>
 </div> */}
                    </div>
                  ))}

                <div></div>
                {/* <table className="custom-dataTable mt-3">
                  <thead>
                    <tr>
                      <th>
                        <div className="fz12 fw400 grayText">
                          <img
                            src="/images/icons/icon-emailbox.svg"
                            alt="email"
                          />
                          <span>Access Given</span>
                        </div>
                      </th>
                      <th>
                        <div className="fz12 fw400 grayText">
                          <img
                            src="/images/icons/icon-calender.svg"
                            alt="calendar"
                          />
                          <span>Privacy</span>
                        </div>
                      </th>
                      <th>
                        <div className="fz12 fw400 grayText">
                          <img src="/images/icons/icon-flag.svg" alt="flag" />
                          <span>Action</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src="/images/icons/icon-profile.svg"
                          alt="icon-profile.svg"
                        />
                        emn.jay@gmail.com
                      </td>
                      <td>
                        <img
                          src="/images/icons/icon-red-pencil.svg"
                          alt="icon-charector-profile.svg"
                        />
                        Can Edit
                      </td>
                      <td>
                        <div className="table-actions p-0">
                          <img
                            className="ms-4"
                            src="/images/icons/icon-menu.svg"
                            alt=""
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </div>
              <button
                className="create-btn-dark"
                data-bs-dismiss={selectedEmails?.length ? "modal" : ""}
                onClick={handleShareDocument}
              >
                <span>Send Invitation Link</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Shared Folder */}
      <div
        className="modal fade"
        id="sharewith02"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered model-custom-centered">
          <div className="modal-content mydoc1-modal-wrapper width40rem">
            <div className="mydoc1-modal-content">
              <img
                className="orange-wave"
                src="/images/orange-wave.svg"
                alt=""
              />
              <img className="blue-wave" src="/images/blue-wave.svg" alt="" />
              <div className="cut-icon-wrapper">
                <button
                  className="btn p-0 rounded-circle border-0 shadow-none cut-icon"
                  data-bs-dismiss="modal"
                >
                  <img src="/images/icons/icon-cross-red.svg" alt="cut" />
                </button>
              </div>

              <div className="mydoc1-modal-header">
                <h3>Share folder with</h3>
              </div>
              <p className="details custom-details-width">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut.
              </p>
              <div className="modal-form">
                <div className="dropdown custom-model-dropdown">
                  <button
                    className="justify-content-between ps-3 fz14 btn dropdown-toggle shadow-15-11-08 bdrs12 btn-solid btn-solid--dark"
                    type="button"
                    // data-bs-toggle="dropdown"
                    // aria-expanded="false"
                    onClick={handleAddEmail}
                  >
                    {/* Can Share */}
                    Add Email
                    <i className="fa fa-angle-down"></i>
                  </button>
                </div>
                <input
                  className="me-3 fz14 fw400 folder-input custom-input-width"
                  type="text"
                  placeholder="Write email address to sen invitation link..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="w-100 mt-3 d-flex justify-content-between">
                <span className="grayText fz12 fw400">
                  User can edit or share document..
                </span>
                <span className="greenThemeText fz14 fw500">
                  {" "}
                  <img
                    className="d-inline"
                    src="/images/icons/icon-pin.svg"
                    alt="icon-pin.svg"
                  />{" "}
                  Copy Direct sharing link
                </span>
              </div>

              <div className="w-100 mx-3 modal-table-wrapper">
                <h3 className="fw600 fz16 table-heading mb-0">
                  Invitation Will Sent to
                </h3>
                <p className="fw400 fz12 primaryText m-0 total-inv">
                  Total invitations :
                  <span className="fw600 fz12 greenThemeText">
                    {selectedEmails?.length}
                  </span>
                </p>
                {selectedEmails &&
                  selectedEmails?.map((item) => (
                    <div className="d-flex justify-content-between mt-3 px-2">
                      <div className="fw500 fz12">
                        <img
                          className="d-inline"
                          src="/images/icons/icon-profile.svg"
                          alt="icon-profile.svg"
                        />
                        {item}
                      </div>
                    </div>
                  ))}
                <div></div>
              </div>
              <button
                className="create-btn-dark"
                data-bs-dismiss={selectedEmails?.length ? "modal" : ""}
                onClick={handleShareDocument}
              >
                <span>Send Invitation Link</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
