import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        // Destroy previous chart instance if exists
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }

        // Define gradients
        const gradientYellow = ctx.createLinearGradient(0, 0, 0, 380);
        gradientYellow.addColorStop(0, "rgba(249, 218, 140,0.005)");
        gradientYellow.addColorStop(0.6, "white");
        gradientYellow.addColorStop(1, "white");

        const gradientGreen = ctx.createLinearGradient(0, 0, 0, 400);
        gradientGreen.addColorStop(0, "rgba(210, 244, 234,0.55)");
        gradientGreen.addColorStop(0.65, "white");
        gradientGreen.addColorStop(1, "white");

        const gradientRed = ctx.createLinearGradient(0, 0, 0, 400);
        gradientRed.addColorStop(0, "rgba(251, 214, 208,0.41)");
        gradientRed.addColorStop(0.75, "white");
        gradientRed.addColorStop(1, "white");

        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            datasets: [
              {
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                borderColor: "#FFBD1B",
                borderWidth: 1,
                tension: 0.4,
                fill: true,
                backgroundColor: gradientYellow,
                order: 3,
                pointRadius: 0,
                hitRadius: 20,
                onHoverPointRadius: 0,
              },
              {
                data:  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                backgroundColor: gradientGreen,
                borderColor: "#20C997",
                borderWidth: 1,
                tension: 0.4,
                order: 2,
                pointRadius: 0,
                hitRadius: 20,
                onHoverPointRadius: 0,
              },
              {
                data: data,
                fill: true,
                backgroundColor: gradientRed,
                borderColor: "#FFC4BB",
                borderWidth: 1,
                tension: 0.4,
                order: 1,
                pointRadius: 0,
                hitRadius: 20,
                onHoverPointRadius: 0,
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              easing: "easeInOutQuad",
              duration: 520,
            },
            scales: {
              x: {
                border: {
                  display: false,
                },
                grid: {
                  display: true,
                  color: "rgba(40, 40, 40, 0.05)",
                },
                ticks: {
                  color: "#797C8C",
                },
              },
              y: {
                border: {
                  display: false,
                },
                grid: {
                  display: true,
                  color: "rgba(40, 40, 40, 0.05)",
                },
                ticks: {
                  color: "#797C8C",
                  stepSize: 100,
                },
              },
            },
          },
        });
      }
    }

    // Cleanup function
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas id="multiLineFillChart" ref={chartRef} />;
};

export default LineChart;
