import {
  DELETE_FOLDER_FAILURE,
  DELETE_FOLDER_SUCCESS,
  DOC_UPLOAD_FAILURE,
  DOC_UPLOAD_SUCCESS,
  EDIT_FOLDER_FAILURE,
  EDIT_FOLDER_SUCCESS,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_SUCCESS,
  GET_FOLDER_FAILURE,
  GET_FOLDER_SUCCESS,
  GET_SHARE_DOCUMENT_FAILURE,
  GET_SHARE_DOCUMENT_SUCCESS,
  MOVE_FILE_TO_FOLDER_FAILURE,
  MOVE_FILE_TO_FOLDER_SUCCESS,
  PATCH_DOC_UPLOAD_FAILURE,
  PATCH_DOC_UPLOAD_SUCCESS,
  SHARE_DOCUMENT_FAILURE,
  SHARE_DOCUMENT_SUCCESS,
} from "./doc.actionTypes";

const initialState = {
  document: null,
  resetDocument: null,
  myDocuments: null,
  allFolders: null,
  sharedFile: null,
  allSharedDocs: null,
  patchDocs: null,
  resetFolder: null,
  deletedDocs: null
};

export default function docReducer(state = initialState, { type, payload }) {
  switch (type) {
    case DOC_UPLOAD_SUCCESS:
      return {
        ...state,
        document: payload,
      };
    case DOC_UPLOAD_FAILURE:
      return {
        ...state,
        message: "",
      };

    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        myDocuments: payload,
      };
    case GET_DOCUMENT_FAILURE:
      return {
        ...state,
        message: "",
      };

    case GET_FOLDER_SUCCESS:
      return {
        ...state,
        allFolders: payload,
      };
    case GET_FOLDER_FAILURE:
      return {
        ...state,
        message: "",
      };

    case MOVE_FILE_TO_FOLDER_SUCCESS:
      return {
        ...state,
        movedFile: payload,
      };
    case MOVE_FILE_TO_FOLDER_FAILURE:
      return {
        ...state,
        message: "",
      };

    case SHARE_DOCUMENT_SUCCESS:
      return {
        ...state,
        sharedFile: payload,
      };
    case SHARE_DOCUMENT_FAILURE:
      return {
        ...state,
        message: "",
      };

    case GET_SHARE_DOCUMENT_SUCCESS:
      return {
        ...state,
        allSharedDocs: payload,
      };
    case GET_SHARE_DOCUMENT_FAILURE:
      return {
        ...state,
        message: "",
      };

    case PATCH_DOC_UPLOAD_SUCCESS:
      return {
        ...state,
        patchDocs: payload,
      };
    case PATCH_DOC_UPLOAD_FAILURE:
      return {
        ...state,
        message: "",
      };
      case EDIT_FOLDER_SUCCESS:
        return {
          ...state,
          patchDocs: payload,
        };
      case EDIT_FOLDER_FAILURE:
        return {
          ...state,
          message: "",
        };

      case DELETE_FOLDER_SUCCESS:
        return {
          ...state,
          deletedDocs: payload,
        };
      case DELETE_FOLDER_FAILURE:
        return {
          ...state,
          message: "",
        };

    default:
      return state;
  }
}