import axios from "axios";
import { GET_ALL_USERS, GET_ALL_USERS_OPEN, GET_USER_DETAIL, POST_LOGIN, POST_SEND_OTP } from "../../services/Api";
import { ALL_USERS_FAILURE, ALL_USERS_OPEN_FAILURE, ALL_USERS_OPEN_SUCCESS, ALL_USERS_SUCCESS, AUTH_GET_USER_DETAIL_FAILURE, AUTH_GET_USER_DETAIL_SUCCESS, AUTH_LOGIN_FAILURE, AUTH_LOGIN_SUCCESS, AUTH_OTP_FAILURE, AUTH_OTP_SUCCESS } from "./auth.actionTypes";
import { toast } from "react-toastify";

export const allUsersOpen = () => async (dispatch) => {
 
  try {
    let resp = await axios.get(`${GET_ALL_USERS_OPEN}`);
    let data = resp?.data;
    if (data) {
      dispatch({
        type: ALL_USERS_OPEN_SUCCESS,
        payload: data,
      });

    }
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: ALL_USERS_OPEN_FAILURE,
    });
  }
};

export const authGetOTP = (data) => async (dispatch) => {
    try {
      const res = await axios.post(POST_SEND_OTP, data);
     
      if (res.data) {
        
        dispatch({
          type: AUTH_OTP_SUCCESS,
          payload: {
            email: data?.email,
          },
        });
      } else {
        toast.error("Please enter correct emailId", {
          autoClose: 1000,
        })
        dispatch({
          type: AUTH_OTP_FAILURE,
          payload: {
            message: res?.data?.msg,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: AUTH_LOGIN_FAILURE,
        payload: {
          message: error,
        },
      });
    }
  };

export const authLogin = (data) => async (dispatch) => {
    try {
    const resp =  await axios.post(`${POST_LOGIN}`, data)
       
        if (resp?.data?.msg?.includes("OTP did't match")) {
        toast.error("Please enter correct otp", {
          autoClose: 1000,
        })
          
          dispatch({
            type: AUTH_LOGIN_FAILURE,
            payload: {
              message: resp?.data?.msg,
            },
          });
        } else {
            const token = resp?.data?.access;
          localStorage.setItem("token", JSON.stringify(token));
          dispatch(getUserDetail(token));
          dispatch({
            type: AUTH_LOGIN_SUCCESS,
            payload: {
              token: resp?.data?.access,
            },
          });
        }
    } catch (err) {
      console.log(err);
    }
  };

  export const getUserDetail = (token) => async (dispatch) => {
    const token1 = JSON.parse(localStorage.getItem("token")) || null;
    const headers = {
      Authorization: `Bearer ${token ? token : token1 }`,
      "Content-Type": "application/json",
    };
    try {
      let resp = await axios.get(`${GET_USER_DETAIL}`, { headers });
      let data = resp?.data;
      if (data) {
        dispatch({
          type: AUTH_GET_USER_DETAIL_SUCCESS,
          payload: data,
        });
  
      }
    } catch (err) {
      console.log({ Error: err });
      dispatch({
        type: AUTH_GET_USER_DETAIL_FAILURE,
      });
    }
  };

  export const allUsers = () => async (dispatch) => {
    const token = JSON.parse(localStorage.getItem("token")) || null;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      let resp = await axios.get(`${GET_ALL_USERS}`, { headers });
      let data = resp?.data;
      if (data) {
        dispatch({
          type: ALL_USERS_SUCCESS,
          payload: data,
        });
  
      }
    } catch (err) {
      console.log({ Error: err });
      dispatch({
        type: ALL_USERS_FAILURE,
      });
    }
  };

