import React, { useState, useEffect } from "react";
import TopNavBar from "../../../layouts/topNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { addIndvProfile } from "../../../redux/Profile/profile.action";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../../redux/Authentication/auth.action";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import CountryList from "react-select-country-list";
import flags from "country-flag-icons/react/3x2";

export default function IndvBasicInformation1() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const userDetail = store?.AuthManager?.userDetail?.userdetail;

  const [mailId, setMailId] = useState("");
  const [mobile, setMobile] = useState("");
  const [alternateNumber, setAlernateNumber] = useState("");
  const [residence, setResidence] = useState("");
  const [detail, setDetail] = useState(null);

  // Fetch country options
  const countryOptions = CountryList().getData();

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  useEffect(() => {
    if (userDetail?.name && userDetail?.company === null) {
      setDetail({ ...userDetail });
    }
  }, [userDetail]);

  const handleValueChange = (residence) => {
    setResidence(residence);
  };

  const handleSubmit = () => {
    const payLoad = {
      ...state.data,
      contact_email: mailId,
      mobile: mobile,
      mobile2: alternateNumber,
      country: residence,
    };
    dispatch(addIndvProfile(payLoad));
    setTimeout(() => {
      navigate("/dashboard");
    }, 2000);
  };
  const handleBack = () => {
    navigate("/Indv-basic-information");
  };

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <aside>
            <div className="blurred-widget-block">
              <div className="inner-div-sidebar">
                <div className="profile-status">
                  <div className="inner-ps d-flex justify-content-center align-items-center">
                    <img src="/images/icons/icon-circle.svg" alt="cirle" />
                    <span className="tilte fw600 fz14">Profile Status</span>
                    <span className="tilte-percentage fw400 fz14 ms-4">
                      30%
                    </span>
                  </div>
                </div>
                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img src="/images/icons/icon-checked.svg" alt="checked" />
                      <span className="inner-title">Basic Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal
                      assistance and sharing
                    </p>
                  </div>
                </div>

                <div className="white-box-unchecked">
                  <div className="white-box-inner">
                    {/* <img src="/images/icons/icon-unchecked.svg" alt="un-checked" /> */}
                    <img src="/images/icons/icon-checked.svg" alt="checked" />
                    <span className="inner-title">Detailed Information</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="green-theme-block">
              <img
                src="/images/illustrations/image-coffee-together.svg"
                alt="image-coffee-together"
                className="mx-auto"
              />

              <h2 className="mb-0 font-secondary fw600 text-white fz16">
                Let’s have coffee together
              </h2>

              <p className="mb-0 fz10">
                In need of any sort of assistance? We are here to help you!
              </p>

              <button className="text-uppercase fw600 fz10 bdrs10 btn btn-solid btn-solid--green">
                <span className="lh-1">Let’s chat!</span>
              </button>
            </div>
          </aside>
          <section className="body-profile2">
            <div className="title" onClick={handleBack}>
              <img
                className="d-inline-flex"
                src="/images/icons/icon-left-awrrow.svg"
                alt="left-awrrow"
              />
              <span className="h-title-teal">Individual</span>
              <span className="h-title-black"> - Detailed Information</span>
            </div>
            <div className="body-context height-adjust-context">
              {detail ? (
                <div className="btn-wrapper">
                  <button
                    className="custom-btn btn-dark"
                    disabled
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              ) : (
                <div className="btn-wrapper">
                  <button
                    className="custom-btn btn-dark"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              )}
              <div className="inner-body-context">
                <h6 className="heading-profiles">Contact Information</h6>
                <p className="details-grey">
                  I’m the only one who will use docurs for personal assistance
                  and sharing
                </p>
                <img
                  className="mx-auto mt-2"
                  src="/images/horizontal-line.svg"
                  alt="horizontal-line"
                />
                <form className="docur-form">
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      How to contact you?
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="email"
                          value={
                            detail?.contact_email
                              ? detail?.contact_email
                              : mailId
                          }
                          placeholder="Enter mail id"
                          onChange={(e) => setMailId(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Residence Country -
                    </label>
                    <div className="">
                      <div className="input-wrapper p-0">
                        <div className="flag-dd">
                          <Select
                            options={countryOptions}
                            value={
                              detail && detail?.country
                                ? countryOptions?.find(
                                    (option) => option.label === detail?.country
                                  )
                                : residence
                            }
                            onChange={handleValueChange}
                            isSearchable={true}
                            placeholder="Select a country..."
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: "380px",
                                minHeight: "45px",
                                border: "none",
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Where to call you?
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper p-0">
                        <div className="flag-dd">
                          <PhoneInput
                            international
                            defaultCountry="IN"
                            value={detail?.mobile ? detail?.mobile : mobile}
                            onChange={setMobile}
                            style={{
                              width: "380px",
                              height: "70%",
                            }}
                            flags={flags}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Alternate number -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper p-0">
                        <div className="flag-dd">
                          <PhoneInput
                            international
                            defaultCountry="IN"
                            containerStyle={{ padding: "600px !important" }}
                            value={
                              detail?.mobile2
                                ? detail?.mobile2
                                : alternateNumber
                            }
                            onChange={setAlernateNumber}
                            style={{
                              width: "380px",
                              height: "70%",
                            }}
                            flags={flags}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <!-- stepper below --> */}
              <div className="stepper position-relative">
                <img
                  className="stepper-divider"
                  src="/images/icons/icon-stepper-divider.svg"
                  alt="icon-stepper-divider"
                />
                <div className="active-stepper-steps bg-c-green">
                  <div className="rotate-text">Personal information</div>
                </div>
                <div className="stepper-steps bg-c-green">
                  <div className="rotate-text">contact information</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}
