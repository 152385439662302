import React, { useState } from 'react'
import TopNavBar from '../../../layouts/topNavBar'
import { useLocation, useNavigate } from 'react-router-dom';

export default function Summary() {
  const navigate = useNavigate();
  const {state} = useLocation();

  const [companyType, setCompanyType] = useState("")
  const [mainProduct, setMainPoduct] = useState("")
  const [workingEmployee, setWorkingEmployee] = useState("")

  const handleSkip =()=>{
    navigate("/both-preview")
  }
  const handleNext =()=>{
    const data3 = {...state.companyData,
      industry: companyType,
      product : mainProduct,
      total_emp : workingEmployee,
    }
    navigate("/both-preview", {state:{userData: state.userData, companyData: data3}})
  }
  
  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <aside>
            <div className="blurred-widget-block">
              <div className="inner-div-sidebar">
                <div className="profile-status">
                  <div className="inner-ps d-flex justify-content-center align-items-center">
                    <img src="/images/icons/icon-circle.svg" alt="cirle" />
                    <span className="tilte fw600 fz14">Profile Status</span>
                    <span className="tilte-percentage fw400 fz14 ms-4">
                      30%
                    </span>
                  </div>
                </div>
                <div className="white-box-unchecked">
                  <div className="white-box-inner">
                    <img src="/images/icons/icon-checked.svg" alt="checked" />
                    <span className="inner-title">Your Information</span>
                  </div>
                </div>
                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img src="/images/icons/icon-checked.svg" alt="checked" />
                      <span className="inner-title">Company Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal
                      assistance and sharing
                    </p>
                  </div>
                </div>
                <div className="white-box-unchecked">
                  <div className="white-box-inner">
                    <img
                      src="/images/icons/icon-unchecked.svg"
                      alt="un-checked"
                    />
                    <span className="inner-title">Summary</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="green-theme-block">
              <img
                src="/images/illustrations/image-coffee-together.svg"
                alt="image-coffee-together"
                className="mx-auto"
              />

              <h2 className="mb-0 font-secondary fw600 text-white fz16">
                Let’s have coffee together
              </h2>

              <p className="mb-0 fz10">
                In need of any sort of assistance? We are here to help you!
              </p>

              <button className="text-uppercase fw600 fz10 bdrs10 btn btn-solid btn-solid--green">
                <span className="lh-1">Let’s chat!</span>
              </button>
            </div>
          </aside>
          <section className="body-profile2">
            <div className="title">
              <img
                className="d-inline-flex"
                src="/images/icons/icon-left-awrrow.svg"
                alt="icon-left-awrrow"
              />
              <span className="h-title-teal">Both</span>
              <span className="h-title-black"> - Company Information</span>
            </div>
            <div className="body-context height-adjust-context">
              <div className="btn-wrapper">
                <button className="custom-btn btn-light" onClick={handleSkip}>
                  skip {">"}
                </button>
                <button className="custom-btn btn-dark" onClick={handleNext}>
                  Next
                </button>
              </div>
              <div className="inner-body-context">
                <h6 className="heading-profiles">Summary</h6>
                <p className="details-grey">
                  Please select the basic information about you so we may know
                  you better
                </p>
                <img
                  className="mx-auto mt-2"
                  src="/images/horizontal-line.svg"
                  alt="horizontal-line"
                />
                <form className="docur-form">
                  {/* <div className="form-row">
                    <label className="form-level d-flex align-items-center"
                      >Your Name -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input type="text" placeholder="john slay" onChange={(e)=>setName(e.target.value)} />
                      </div>
                    </div>
                  </div> */}

                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Company Type -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper p-0">
                        <div className="options-wrapper-checkbox">
                          <div className="lh-1 checkbox-wrapper custom-checkbox">
                            <input
                              id="product-based"
                              type="checkbox"
                              checked={companyType === "product"}
                              name="product"
                              onChange={(e) => setCompanyType(e.target.name)}
                            />
                            <label for="product-based">
                              <span></span>
                              <div>
                                <b className="checkbox-label">Products Based</b>
                              </div>
                            </label>
                          </div>
                          <div className="lh-1 checkbox-wrapper custom-checkbox">
                            <input
                              id="service-based"
                              type="checkbox"
                              checked={companyType === "service"}
                              name="service"
                              onChange={(e) => setCompanyType(e.target.name)}
                            />
                            <label for="service-based">
                              <span></span>
                              <div>
                                <b className="checkbox-label">Service Based</b>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Main Product/Service Offered -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder="Separate with (,) comma"
                          onChange={(e) => setMainPoduct(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Working Employee -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <div className="dropdown dropdown-inputs">
                          <a
                            className="position-relative form-control form-control--link border-0"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="fw400 fz12 grayText fst-italic">
                              1000 - 5000
                            </span>
                            <div className="btn-fab-dropdown">
                              {/* <img
                                src="/images/icons/icon-dropdown-arrow.svg"
                                alt="icon-dropdown-arrow"
                              /> */}
                            </div>
                          </a>

                          <div className="w-100 dropdown-menu">
                            <ul className="list-inline mb-0 product-type-list">
                              <li>
                                <a href="#" className="typr-pair">
                                  <span className="fz14">A</span>
                                </a>
                              </li>
                              <li>
                                <a href="#" className="typr-pair active">
                                  <span className="fz14">B</span>
                                </a>
                              </li>
                              <li>
                                <a href="#" className="typr-pair">
                                  <span className="fz14">C</span>
                                </a>
                              </li>
                              <li>
                                <a href="#" className="typr-pair">
                                  <span className="fz14">D</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <!-- stepper below --> */}
              <a href="" className="stepper single-step bg-c-green">
                <div className="rotate-text">Company data</div>
              </a>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}
