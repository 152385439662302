import React, { useEffect, useRef } from "react";

const GooglePlacesAutocomplete = ({
  addressFromLatLng,
  setAddressFromLatLng,
  location
}) => {
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    const apiKey = "AIzaSyBGMUJe2XUHhIZKXIrq4btVXK2s3zeqLLM";
    // const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.defer = true;
      script.async = true;

      script.onload = initializeAutocomplete;
      script.onerror = () => {
        console.error("Failed to load Google Maps API script");
      };

      document.head.appendChild(script);
    };

    const initializeAutocomplete = () => {
      try {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          document.getElementById("location-input")
        );

        autoCompleteRef.current.addListener("place_changed", handlePlaceSelect);
      } catch (error) {
        console.error("Error initializing Google Places Autocomplete:", error);
      }
    };

    if (!window.google || !window.google.maps || !window.google.maps.places) {
      loadGoogleMapsScript();
    } else {
      initializeAutocomplete();
    }

    return () => {
      if (autoCompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(autoCompleteRef.current);
      }
    };
  }, []);

  const handlePlaceSelect = () => {
    const place = autoCompleteRef.current.getPlace();

    if (place?.geometry) {
      setAddressFromLatLng(place.formatted_address);
    }
  };

  const handleInputChange = (event) => {
    setAddressFromLatLng(event.target.value);
  };

  return (
    <>
      <input
        id="location-input"
        type="text"
        placeholder="Enter a location here"
        className="w-100 h-100 border-0 bdrs8 shadow-15-11-08 bg-white fz14 form-control"
        value={location ? location : addressFromLatLng}
        onChange={handleInputChange}
      />
    </>
  );
};

export default GooglePlacesAutocomplete;
