import React, { useState } from "react";
import TopNavBar from "../../../layouts/topNavBar";
import { useLocation, useNavigate } from "react-router-dom";

export default function AboutYourJob() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [jobTitle, setJobTitle] = useState("");
  const [residence, setResidence] = useState("");
  const [more, setMore] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const dropdownOptions = ['Option 1', 'Option 2', 'Option 3'];

  const handleSkip = () => {
    navigate("/about-company");
  };

  const handleCompanyNameChange = (event) => {
    setJobTitle(event.target.value);
    const filtered = dropdownOptions.filter(option => option.toLowerCase().includes(event.target.value.toLowerCase()));
    setFilteredOptions(filtered);
  };

  const handleOptionSelect = (option) => {
    setJobTitle(option);
  };

  const handleNext = () => {
    const data1 = {
      ...state.data,
      designation: jobTitle,
      country: residence,
      about: more,
    };
    navigate("/about-company", { state: { data1 } });
  };
  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <aside>
            <div className="blurred-widget-block">
              <div className="inner-div-sidebar">
                <div className="profile-status">
                  <div className="inner-ps d-flex justify-content-center align-items-center">
                    <img
                      src="/images/icons/icon-circle.svg"
                      alt="icon-circle"
                    />
                    <span className="tilte fw600 fz14">Profile Status</span>
                    <span className="tilte-percentage fw400 fz14 ms-4">
                      30%
                    </span>
                  </div>
                </div>

                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img
                        src="/images/icons/icon-checked.svg"
                        alt="icon-checked"
                      />
                      <span className="inner-title">Your Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal
                      assistance and sharing
                    </p>
                  </div>
                </div>
                <div className="white-box-unchecked">
                  <div className="white-box-inner">
                    <img
                      src="/images/icons/icon-unchecked.svg"
                      alt="un-checked"
                    />
                    <span className="inner-title">Company Information</span>
                  </div>
                </div>

                <div className="white-box-unchecked">
                  <div className="white-box-inner">
                    <img
                      src="/images/icons/icon-unchecked.svg"
                      alt="un-checked"
                    />
                    <span className="inner-title">Summary</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="green-theme-block">
              <img
                src="/images/illustrations/image-coffee-together.svg"
                alt="image-coffee-together"
                className="mx-auto"
              />

              <h2 className="mb-0 font-secondary fw600 text-white fz16">
                Let’s have coffee together
              </h2>

              <p className="mb-0 fz10">
                In need of any sort of assistance? We are here to help you!
              </p>

              <button className="text-uppercase fw600 fz10 bdrs10 btn btn-solid btn-solid--green">
                <span className="lh-1">Let’s chat!</span>
              </button>
            </div>
          </aside>
          <section className="body-profile2">
            <div className="title">
              <img
                className="d-inline-flex"
                src="/images/icons/icon-left-awrrow.svg"
                alt="icon-left-awrrow"
              />
              <span className="h-title-teal">Both</span>
              <span className="h-title-black"> - Your Introduction</span>
            </div>
            <div className="body-context height-adjust-context">
              <div className="btn-wrapper">
                <button className="custom-btn btn-light" onClick={handleSkip}>
                  skip {">"}
                </button>
                <button className="custom-btn btn-dark" onClick={handleNext}>
                  Next
                </button>
              </div>
              <div className="inner-body-context">
                <h6 className="heading-profiles">About Your Job</h6>
                <p className="details-grey">
                  Please select the basic information about you so we may know
                  you better
                </p>
                <img
                  className="mx-auto mt-2"
                  src="/images/horizontal-line.svg"
                  alt="horizontal-line"
                />
                <form className="docur-form">
                   <form className="docur-form">
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center"
                      >Job Title -
                    </label>
                    <div className="blurred-widget-block">
                    <div className="input-wrapper">
                        <input
                          type="text"
                          value={jobTitle}
                          onChange={handleCompanyNameChange}
                          placeholder="select company or add manually.."
                        />
                        <div className="btn-fab-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                          <img src="/images/icons/icon-dropdown-arrow.svg" alt="" />
                        </div>

                        <div className="w-100 dropdown-menu">
                          <ul className="list-inline mb-0 product-type-list">
                            {filteredOptions.map((option, index) => (
                              <li key={index}>
                                <a href="#" className="typr-pair" onClick={() => handleOptionSelect(option)}>
                                  <span className="fz14">{option}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">Residence Country -</label>
                    <div className="blurred-widget-block">
  
                      <div className="input-wrapper p-0">
                        <div className="flag-dd">
  
                          <div className="dropdown">
                            <button className="border-0 p-0 btn btn-secondary dropdown-toggle flag-dd__ff" type="button"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <div className="border-0 flag-dd__ff-inner">
                                <img src="/images/icons/icon-flag-india-lg-rounded.svg" alt=""/>
                              </div>
                              <b className="arrow">
                                <img src="/images/icons/icon-dropdown-line-arrow.svg" alt=""/>
                              </b>
                            </button>
                            <ul className="border-0 dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  <img src="/images/icons/icon-flag-india-lg-rounded.svg" alt=""/>
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  <img src="/images/icons/icon-flag-india-lg-rounded.svg" alt=""/>
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  <img src="/images/icons/icon-flag-india-lg-rounded.svg" alt=""/>
                                </a>
                              </li>
  
                            </ul>
                          </div>
  
                          <span className="fw500 fz14 grayText ps-2 dd-value">INDIA</span>
  
                          <input type="text" className="form-control shadow-none fz14 ps-0 fw500 bg-transparent" placeholder="" value={residence} onChange={(e) => setResidence(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <label className="form-level d-flex align-items-center"
                      >Tell something about you -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper textarea-input">
                        <textarea rows="5" cols="55" className="border-0 fz14 fw400 ps-2 fst-italic grayText"  value={more}
            onChange={(e)=>setMore(e.target.value)} > my name is...</textarea>
                      </div>
                    </div>
                  </div>
                  
                </form>
                  
                
                </form>
              </div>
              {/* <!-- stepper below --> */}
              <div className="stepper position-relative">
                <img
                  className="stepper-divider"
                  src="/images/icons/icon-stepper-divider.svg"
                  alt="divider"
                />
                <a href="" className="active-stepper-steps bg-c-green">
                  <div className="rotate-text">
                    wants to know about yourself
                  </div>
                </a>
                <a href="" className="stepper-steps bg-c-green">
                  <div className="rotate-text">Few more things</div>
                </a>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}
