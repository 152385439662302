import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';

const BarGraph = ({ data }) => {
  useEffect(() => {
    let chartInstance = null;

    const ctx = document.getElementById('barGraphCanvas').getContext('2d');

    if (chartInstance) {
      chartInstance.destroy();
    }

    chartInstance = new Chart(ctx, {
      type: 'bar',
      data: {
        labels:   [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        datasets: [
          {
            data: data,
            borderWidth: 0,
            backgroundColor: "#BEEDFD",
            borderRadius: 100,
            hoverBackgroundColor: "#36B8A3",
            borderSkipped: false,
          },
        ],
      },
      options: {
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        categoryPercentage: 0.175,
        maintainAspectRatio: false,
        animation: {
          easing: "easeInOutQuad",
          duration: 520,
        },
        scales: {
          x: {
            stacked: true,
            border: {
              display: false,
            },
            grid: {
              display: true,
              color: "rgba(40, 40, 40, 0.05)",
            },
            ticks: {
              color: "#797C8C",
            },
          },
          y: {
            border: {
              display: false,
            },
            grid: {
              display: true,
              color: "rgba(40, 40, 40, 0.05)",
            },
            ticks: {
              color: "#797C8C",
              stepSize: 10,
            },
          },
        },
      },
    });

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data]);

  return <canvas id="barGraphCanvas" />;
};

export default BarGraph;
