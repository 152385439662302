import React, {useState, useEffect} from 'react'
import TopNavBar from '../../../layouts/topNavBar'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addIndvProfile, addIndvProfileInOrg, addOrgProfile } from '../../../redux/Profile/profile.action';
import { ToastContainer, toast } from 'react-toastify';
import { getUserDetail } from '../../../redux/Authentication/auth.action';
import { formatDateandTime } from '../../../services/utils';

export default function OrgPreview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {state} = useLocation();
  const store = useSelector(state => state);
  const userDetail = store?.AuthManager?.userDetail?.userdetail;
  const [detail, setDetail] = useState(null);

  const orgData = state?.orgData;
  const userData = state?.userData;

  useEffect(()=>{
    dispatch(getUserDetail())
  },[])

  useEffect(()=>{
    if(userDetail?.name && userDetail?.company ){
      setDetail({...userDetail})
    }
  },[userDetail])

  const handleSave =()=>{
    dispatch(addOrgProfile(orgData, userData))
    setTimeout(()=>{
      navigate("/dashboard");  
    },2000) 
  }

  return (
    <main className="page-layout page-layout--dashboard">
 <TopNavBar />
    <article className="w-100 px-0 content-layout">
      <div className="mt-4 content-layout__inner">
        <aside className="mt-0 no-nav-feature-block">

          <div className="shadow-34-05 blur-2 theme-feature-widget">

            <img src="/images/illustrations/theme-feature-illustration.svg" alt="" className="theme-feature-illustration"/>

            <h4 className="font-secondary text-capitalize fw800 fz20 text-white mb-0">Need to verify bulk docs?</h4>

            <p className="neutral-para fz12">Learn how to create beautiful scenes
              in illustrator. Tips and Tricks with real
              life projects and case studies.</p>

            <button className="w-100 border-0 blurred-widget-block bdrs10 bg-white position-relative upload-docs">
              <input className="p-0" type="file"/>

              <div className="btn btn-solid btn-solid--green bdrs10">
                <img src="/images/icons/icon-upload-cloud-white.svg" alt="upload-cloud-white"/>
              </div>
              <span className="text-uppercase fw600 fz10 greenThemeText">Upload your documents</span>

            </button>

            <p className="mt-1 mb-0 neutral-para fz12 opacity-100">
              Join the network of happy clients
            </p>

            <ul className="list-inline mb-0 avatars">
              <li>
                <img src="/images/avatars/avatar01.png" alt="avatar01"/>
              </li>
              <li>
                <img src="/images/avatars/avatar02.png" alt="avatar02"/>
              </li>
              <li>
                <img src="/images/avatars/avatar03.png" alt="avatar03"/>
              </li>
              <li>
                <img src="/images/avatars/avatar04.png" alt="avatar04"/>
              </li>
              <li>
                <img src="/images/avatars/avatar05.png" alt="avatar05"/>
              </li>
            </ul>
          </div>

          <div className="blur-2 shadow-34-05 light-green-theme-block">
            <div className="light-green-theme-block__inner">
              <h4 className="font-secondary text-capitalize fw800 fz20 mb-0">Create Folder to <br /> categories your files!
              </h4>

              <p className="mb-1 neutral-para fz12 grayText">
                Tips and Tricks with real life projects and case studies.
              </p>

              <button className="w-100 blurred-widget-block bdrs10 bg-white position-relative upload-docs">
                <div className="btn btn-solid btn-solid--dark bdrs10">
                  <img src="/images/icons/icon-create-folder-white.svg" alt="create-folder-white"/>
                </div>
                <span className="text-uppercase fw600 fz10 text-start">Create new folder</span>
              </button>
            </div>
          </div>
        </aside>
        <div className="body-profile">
          <div className="blur-2 w-100 profile-nav greetings">
            <div className="user-info">
              <div className="user-info__dp">
                <div className="bg-white user-info__dp-inner">
                  <span className="bdrs12 fw700 fz34 text-white initials">L</span>
                  {/* <!-- <span className="bdrs12 user-image"></span> --> */}
                </div>
              </div>

              <div className="user-info__name">
                <h6 className="mb-1 fz16 fw500 primaryText">
                  <b className="fw600">Hi</b>, {detail?.name}!
                </h6>
                <h4 className="fz26 lh-1 mb-1 fw400">Welcome Onboard!</h4>
                <p className="mb-0 fz12 grayText">
                  <img src="/images/icons/icon-clock-line.svg" alt="clock-line"/>
                  <span>{detail?.created_at ? formatDateandTime(detail?.created_at) : "NA"}</span>
                </p>
              </div>
            </div>

            <div className="happy-img">
              <span><img src="/images/Happy to see you.svg" alt="happy" /></span>
              <img src="/images/happy.png" alt="happy" />
            </div>
            <img className="position-absolute wave2" src="/images/orange-wave-2.svg" alt="wave2" />
            <img className="position-absolute red-square" src="/images/red-sqaure-tilt.svg" alt="red-sqaure-tilt" />
            <img className="position-absolute red-star" src="/images/red-star.svg" alt="red-star" />
            <img className="position-absolute black-cross" src="/images/black-cross.svg" alt="black-cross" />
            <img className="position-absolute black-minus" src="/images/black-minus.svg" alt="black-minus" />
          </div>

          <div className="mt-4 blur-2 shadow-34-05 bdrs10 bdr-gray access-block">
            <div className="fz26 greenThemeText access-ques">
              Ready to give access to others?
            </div>

            <button className="w-100 blurred-widget-block bdrs10 bg-white position-relative upload-docs">
              <div className="btn btn-solid btn-solid--green bdrs10">
                <img src="/images/icons/icon-user-white.svg" alt="user-white"/>
              </div>
              <span className="text-uppercase fw600 fz10 greenThemeText text-start">Add New Member or company
                employee</span>
            </button>
          </div>  
          <div className="preview-blocks">
            <div className="blurred-widget-block bdr-gray preview-blocks__right">
              <div className="fz12 fw500 shadow-34-05 blur-2 preview-title-bar">
                Company Information
              </div>

              <ul className="list-unstyled preview-info">
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">Company Name -</div>
                    <div className="grayText fz14 value">{detail?.company?.name ? detail?.company?.name : orgData?.name ? orgData?.name : "NA" }</div>
                  </div>
                </li>
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">registration no -</div>
                    <div className="grayText fz14 value">{detail?.company?.reg_no ? detail?.company?.reg_no : orgData?.reg_no ? orgData?.reg_no : "NA" }</div>
                  </div>
                </li>
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">Contact no -</div>
                    <div className="grayText fz14 value value--masked">
                      <button className="btn bg-transparent toggleNums" type="button" id="">
                        {/* <img src="/images/icons/icon-eye.svg" alt="eye"/> */}
                      </button>
                      <div>
                        {/* <span>091 989</span> */}
                        <span className="numbers">{detail?.company?.contact ? detail?.company?.contact : orgData?.contact ? orgData?.contact : "NA" } </span>
                        {/* <b className="fw400 masked-nums">*** *** ***</b> */}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">company type -</div>
                    <div className="grayText fz14 value value--tags">
                      { orgData?.industry === "service" ?  <div className="greenThemeText fz14 tag-type">Service based</div> : <div className="greenThemeText fz14 tag-type">Product based</div>  }
                     
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">gst no -</div>
                    <div className="grayText fz14 value">{detail?.company?.gst ? detail?.company?.gst : orgData?.gst ? orgData?.gst : "NA" }</div>
                  </div>
                </li>
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">url -</div>
                    <div className="fz14 value">
                      <a href="#" className="greenThemeText fst-italic">{detail?.company?.url ? detail?.company?.url : orgData?.url ? orgData?.url : "NA" }</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">location -</div>
                    <div className="fz14 value value--withImg">
                      <img src="/images/icons/icon-location-pin.svg" alt="location-pin"/>
                      <span>{detail?.company?.location ? detail?.company?.location :  orgData?.location ? orgData?.location : "NA" }</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">working employee -</div>
                    <div className="grayText fz14 value">{detail?.company?.total_emp ? detail?.company?.total_emp : orgData?.total_emp ? orgData?.total_emp : "NA" }</div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="blurred-widget-block bdr-gray preview-blocks__right">
              <div className="fz12 fw500 shadow-34-05 blur-2 preview-title-bar">
                User Information
              </div>

              <ul className="list-unstyled preview-info">
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">Name -</div>
                    <div className="grayText fz14 value">{detail?.name ? detail?.name : userData?.name ? userData?.name : "NA" }</div>
                  </div>
                </li>
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">Designation -</div>
                    <div className="grayText fz14 value">{userData?.designation == 1 ? "Admin" : detail?.designation == 1 ?  "Admin" : "NA" }</div>
                  </div>
                </li>
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">Contact no -</div>
                    <div className="grayText fz14 value value--masked">
                      <button className="btn bg-transparent toggleNums" type="button" id="">
                        {/* <img src="/images/icons/icon-eye.svg" alt=""/> */}
                      </button>
                      <div>
                        {/* <span>091 989</span> */}
                        {/* <span className="numbers" style={{"display": "none"}}> */}
                        <span className="numbers">
                          {detail?.mobile ? detail?.mobile :  userData?.contact ? userData?.contact : "NA" } </span>
                        {/* <b className="fw400 masked-nums">*** *** ***</b> */}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info-pair">
                    <div className="text-uppercase fz10 fw600 mb-1 label">email address -</div>
                    <div className="grayText fz14 value">{detail?.contact_email ? detail?.contact_email : userData?.contact_email ? userData?.contact_email : "NA" }</div>
                  </div>
                </li>
              </ul>
              <button className="blurred-widget-block bdrs10 position-relative upload-docs">
                <div className="btn btn-solid btn-solid--dark bdrs10">
                  <img src="/images/icons/icon-user-white.svg" alt="user-white"/>
                </div>
                <span className="text-uppercase fw600 fz10 text-start">Add Member</span>
              </button>
              {/* <div className="btn-wrapper">
                <button className="custom-btn btn-dark" onClick={handleSave} >Save</button>
              </div> */}
              {detail ?  <div className="btn-wrapper">
                <button className="custom-btn btn-dark" disabled onClick={handleSave} >Save</button>
              </div> :  <div className="btn-wrapper">
                <button className="custom-btn btn-dark" onClick={handleSave} >Save</button>
              </div> }
            </div>
          </div>
        </div>
      </div>
     <ToastContainer />
    </article>
  </main>
  )
}
