import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserDetail } from "../redux/Authentication/auth.action";
import { formatDateandTime } from "../services/utils";
import { IMAGE_PROXY_URL } from "../services/Api";

export default function TopNavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);

  const store = useSelector((state) => state);
  const userData = store?.AuthManager?.userDetail;
  const profileImage = store?.AuthManager?.userDetail?.userdetail?.image;
  const companyData = store?.AuthManager?.userDetail?.userdetail?.company?.name;

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  useEffect(() => {
    if (profileImage) {
      setImage(`${IMAGE_PROXY_URL}${profileImage}`);
    }
  }, [profileImage]);

  const handleLogo = () => {
    navigate("/dashboard");
  };

  const handleProfile = () => {
    if (userData?.userdetail?.profile_type === "individual") {
      navigate("/Indv-basic-information");
    } else if (companyData) {
      navigate("/org-basic-information");
    } else {
      navigate("/profile");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/signIn");
  };

  return (
    <header className="header">
      <div className="header__left" onClick={handleLogo}>
        <img
          className="mx-auto"
          src="/images/logos/logo-dark.svg"
          alt="Docur Logo"
        />
        <p className="fz12 mb-0 header__subtitle">
          TAG LINE FOR DOCUR WILL BE HERE
        </p>
      </div>

      <div className="header__right">
        <div className="user-info">
          <div className="dropdown">
            <button
              className="border-0 dropdown-toggle user-info__dp"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {image ? (
                <div className="bg-white user-info__dp-inner">
                  <img className="bdrs12 user-image" src={image} />
                </div>
              ) : (
                <div className="bg-white user-info__dp-inner">
                  <span className="bdrs12 fw700 fz34 text-white initials">
                    U
                  </span>
                </div>
              )}
              <b className="bg-white arrow">
                <img
                  src="/images/icons/icon-dropdown-line-arrow.svg"
                  alt="dropdown-line-arrow"
                />
              </b>
            </button>

            <ul className="border-0 dropdown-menu">
              <li>
                <a className="fz12 dropdown-item" onClick={handleProfile}>
                  Profile
                </a>
              </li>
              {/* <li>
                <a className="fz12 dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a className="fz12 dropdown-item" href="#">
                  Something else here
                </a>
              </li> */}
            </ul>
          </div>

          <div className="user-info__name">
            <h6 className="mb-1 fz16 fw500 primaryText">
              {userData?.userdetail?.name ? userData?.userdetail?.name : "User"}
              !
            </h6>
            <p className="mb-0 fz12 grayText">
              <img src="/images/icons/icon-clock-line.svg" alt="clock-line" />
              <span>
                {userData?.userdetail?.created_at
                  ? formatDateandTime(userData?.userdetail?.created_at)
                  : "NA"}
              </span>
            </p>
          </div>
        </div>

        <div className="form-group form-group--search">
          <div className="bdrs12 fab fab--search">
            <img
              src="/images/icons/icon-search-pastel-green.svg"
              alt="Search"
            />
          </div>

          <input
            type="text"
            className="border-0 bdrs12 fz14 form-control"
            placeholder="Search your document here..."
          />
          <ul className="m-0 lh-1 list-inline icons">
            <li>
              <a href="#" className="">
                <span className="dot"></span>
                <img
                  src="/images/icons/icon-bell-header.svg"
                  alt="bell-header"
                />
              </a>
            </li>
            <li>
              <a
                className=""
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
              >
                <img src="/images/icons/icon-logout.svg" alt="logout" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
