import React, { useEffect, useState } from "react";
import AuthFooterLeft from "../../layouts/authLayouts/authFooterLeft";
import AuthFooterRight from "../../layouts/authLayouts/authFooterRight";
import AuthContentsRight from "../../layouts/authLayouts/authContentsRight";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authLogin, getUserDetail } from "../../redux/Authentication/auth.action";
import { ToastContainer, toast } from "react-toastify";
import { IMAGE_PROXY_URL } from "../../services/Api";
import { getAllDocuments } from "../../redux/Documents/doc.action";

export default function SignInOtp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otpValues, setOtpValues] = useState(["", "", "", "", ""]);
  const [clicked, setClicked] = useState(false);
  const store = useSelector((state)=>state);
  const [errorToastShown, setErrorToastShown] = useState(false);
  const userEmail = store.AuthManager.data.user;
  const userDetail = store.AuthManager.userDetail?.userdetail;
  const allDocuments = store?.DocumentsManager?.myDocuments;
  const userDetails = store?.AuthManager?.allUsersOpen;
  let userEmail1 = localStorage.getItem("userEmail1");

  useEffect(()=>{
    dispatch(getAllDocuments());
    dispatch(getUserDetail());
  },[])

  const handleInputChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;

    if (value && index < otpValues.length - 1) {
      document.getElementById(`otpInput${index + 1}`).focus();
    }
    setOtpValues(newOtpValues);
  };

  const handleBackClick = (index, value, event) => {
    if (event.key === "Backspace") {
      event.preventDefault();

      if (index > 0) {
        const previousInput = document.getElementById(`otpInput${index - 1}`);
        if (previousInput) {
          previousInput.removeAttribute("disabled");
          previousInput.focus();
        }
      }
      let newOtpValues = [...otpValues];
      newOtpValues[index] = "";
      setOtpValues(newOtpValues);
    } 
    else if (value !== "") {
      let newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);
    }
  };

  const path = window.location.pathname;

  const handleNextClick = (e) => {
    e.preventDefault();
  
    if (otpValues.some(value => value === "")) {
      toast.error("Please fill all OTP fields", {
        autoClose: 1000,
      });
      return;
    }
  
    if (path !== "/signIn-otp") {
      const replacedPath = path.replace('/signIn-otp/signIn', '');
        const file = `${IMAGE_PROXY_URL}${replacedPath}`
        window.open(file, '_self')
    } else {
      setClicked(true);
      const enteredOtp = otpValues.join("");
      const payLoad = { email: userEmail, otp: enteredOtp };
      dispatch(authLogin(payLoad));
    }
  };

  if (clicked) {
    const search = userDetails?.some((item) => item?.email === userEmail1);
    if (search && store.AuthManager.data.isAuthenticated === true || userDetail?.length !== 0 ) {
        setTimeout(()=>{
          navigate("/dashboard")
        },500)
    } if (search && store?.AuthManager.data.isAuthenticated === true || userDetail?.length === 0){
      navigate("/profile")
    }
    else {
      if (!errorToastShown) {
        toast.error("Please Sign-up first", {
          autoClose: 1000,
        })
        navigate("/signUp")
      }
    }
  }

  return (
    <main className="auth-layout">
      <div className="auth-layout__left">
        <div className="auth-form-area">
          <img
            src="/images/logos/logo-dark.svg"
            alt="logo-dark"
            className="logo"
          />
          <form action="#" enctype="multipart/form-data">
            <h1 className="mb-3 font-secondary fw700 text-capitalize fz26">
              Sign In
            </h1>
            <div className="bdrs10 form-group form-group--otp">
              {otpValues.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  className="border-0 fw600 fz16 form-control"
                  id={`otpInput${index}`}
                  value={value}
                  maxLength="1"
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleBackClick(index, e.target.value, e)}
                />
              ))}
            </div>
            <em className="fw400 fz12 grayText">
              An OTP was sent to your email address “
              <strong className="fw600">{userEmail}</strong>”
            </em>

            <button
              className="fz10 fw600 w-100 text-uppercase btn btn-solid btn-solid--dark"
              onClick={handleNextClick}
            >
              <span>Next</span>
            </button>

            <div className="primaryDarkText fz12 fw500 or">OR</div>

            <div className="grayText fz12 mx-auto alternative">
              <span className="fw500 fst-italic">Don’t have an account?</span>
              <Link
                to="/signUp"
                className="fw600 fz12 greenThemeText text-uppercase ms-1"
              >
                SIGN UP
              </Link>
            </div>
          </form>
          <AuthFooterLeft />
        </div>
      </div>
      <div className="auth-layout__right">
        <div className="auth-features">
          <AuthContentsRight />
          <AuthFooterRight />
        </div>
      </div>
      <ToastContainer />
    </main>
  );
}
