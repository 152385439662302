import React, { useState, useEffect } from "react";
import AuthFooterRight from "../../layouts/authLayouts/authFooterRight";
import AuthFooterLeft from "../../layouts/authLayouts/authFooterLeft";
import AuthContentsRight from "../../layouts/authLayouts/authContentsRight";
import { Link, useNavigate } from "react-router-dom";
import {
  allUsersOpen,
  authLogin,
} from "../../redux/Authentication/auth.action";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignUpOtp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otpValues, setOtpValues] = useState(["", "", "", "", ""]);
  const [clicked, setClicked] = useState(false);
  const [errorToastShown, setErrorToastShown] = useState(false);
  const store = useSelector((state) => state);
  const userDetails = store?.AuthManager?.allUsersOpen;
  let userEmail = localStorage.getItem("userEmail1");

  useEffect(() => {
    dispatch(allUsersOpen());
  }, []);

  const handleInputChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;

    if (value && index < otpValues.length - 1) {
      document.getElementById(`otpInput${index + 1}`).focus();
    }
    setOtpValues(newOtpValues);
  };

  const handleBackClick = (index, value, event) => {
    if (event.key === "Backspace") {
      event.preventDefault();

      if (index > 0) {
        const previousInput = document.getElementById(`otpInput${index - 1}`);
        if (previousInput) {
          previousInput.removeAttribute("disabled");
          previousInput.focus();
        }
      }
      let newOtpValues = [...otpValues];
      newOtpValues[index] = "";
      setOtpValues(newOtpValues);
    } else if (value !== "") {
      let newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);
    }
  };

  const handleNextClick = (e) => {
    e.preventDefault();
  
    if (otpValues.some(value => value === "")) {
      toast.error("Please fill all OTP fields", {
        autoClose: 1000,
      });
      return;
    }
    setClicked(true);
    const enteredOtp = otpValues.join("");
    const payLoad = { email: userEmail, otp: enteredOtp };
    dispatch(authLogin(payLoad));
  };

  if (clicked) {
    const search = userDetails?.some((item) => item?.email === userEmail);
    if (search) {
      if (!errorToastShown) {
        setErrorToastShown(true);
        toast.error("Account existed...please sign-in", {
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate("/signIn");
        }, 3000);
      }
    } else {
      if (!errorToastShown) {
        setErrorToastShown(true);
        toast.success("Account successfully created", {
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate("/profile");
        }, 2000);
      }
    }
  }

  return (
    <main className="auth-layout">
      <div className="auth-layout__left">
        <div className="auth-form-area">
          <img
            src="/images/logos/logo-dark.svg"
            alt="logo-dark"
            className="logo"
          />
          <form action="#">
            <h1 className="mb-3 font-secondary fw700 text-capitalize fz26">
              Sign Up
            </h1>
            <div className="bdrs10 form-group form-group--otp">
              {otpValues.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  className="border-0 fw600 fz16 form-control"
                  id={`otpInput${index}`}
                  value={value}
                   maxLength="1"
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleBackClick(index, e.target.value, e)}
                />
              ))}
            </div>
            <em className="fw400 fz12 grayText">
              An OTP was sent to your email address “
              <strong className="fw600">{userEmail}</strong>”
            </em>
            <button
              className="fz10 fw600 w-100 text-uppercase btn btn-solid btn-solid--dark"
              onClick={handleNextClick}
            >
              <span>Next</span>
            </button>
            <div className="primaryDarkText fz12 fw500 or">OR</div>
            <div className="grayText fz12 mx-auto alternative">
              <span className="fw500 fst-italic">
                Already have an account?{" "}
              </span>
              <Link
                to="/"
                className="fw600 fz12 greenThemeText text-uppercase ms-1"
              >
                SIGN IN
              </Link>
            </div>
          </form>
          <AuthFooterLeft />
        </div>
      </div>
      <div className="auth-layout__right">
        <div className="auth-features">
          <AuthContentsRight />
          <AuthFooterRight />
        </div>
      </div>
      <ToastContainer />
    </main>
  );
}
