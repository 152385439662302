import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets_landing/styles/landing.css";
import { ToastContainer, toast } from "react-toastify";

export default function Privacy() {
  const navigate = useNavigate();
  const handleBackButton = (e) => {
    e.preventDefault();
    navigate("/SignIn");
  };
  return (
    <>
      <div
        style={{ paddingTop: "calc(50px* var(--resp))", paddingBottom: "20px" }}
      >
        <section>
          <div className="container">
            <section>
              <div className="container">
                <p onClick={handleBackButton}>
                  <img
                    className="left-arrow-long d-none d-md-block"
                    src="/images/menu-arrow-left.svg"
                    alt=""
                    style={{ width: "40px", height: "12px", cursor: "pointer" }}
                  />
                </p>
                <br />
                <div>
                  <h2>Privacy Policy</h2> <br />
                  <>
                    <h5> 1. Information Collection and Use </h5>
                    We collect various types of information to provide and
                    improve our services to you.
                    <br />
                    <br />
                    <h6>1.1 Information You Provide</h6>
                    • Account Information: When you create an account, we
                    collect basic information such as your name, email address,
                    phone number, and billing details. This information is
                    encrypted and stored in a cloud secured encrypted digital
                    storage.
                    <br />
                    • Contact Information: When you upload or provide phone
                    numbers and other contact details for sending SMS or voice
                    messages, we store this information securely in encrypted
                    form.
                    <br />
                    <br />
                    <h6>1.2 Information We Collect Automatically</h6>
                    • Log Data: We collect log data, including the dates and
                    times of access to our service, message delivery status, and
                    response logs.
                    <br />
                    <br />
                    <h5>2. How We Use Your Information</h5>
                    We use your information for several reasons, including:
                    <br />
                    • To Provide the Services: We use your information to fulfil
                    your requests, process your transactions, and deliver the
                    services you use.
                    <br />
                    • To Communicate with You: We may use your information to
                    respond to your inquiries, provide customer service support,
                    send you important information about the services, and send
                    you marketing communications (with your consent) via
                    different channels, including but not limited to SMS, Email,
                    WhatsApp, and Voice.
                    <br />
                    • To Personalize Your Experience: We may use your
                    information to personalize your experience with the
                    services, such as tailoring content and recommendations to
                    your interests.
                    <br />
                    • To Improve Our Services: We may use your information to
                    improve the functionality, performance, and security of the
                    services.
                    <br />
                    • To Comply with Legal Obligations: We may use your
                    information to comply with applicable laws and regulations.
                    <br />
                    <br />
                    <h5>3. Your Choices</h5>
                    We respect your right to control your information. You can
                    choose:
                    <br />
                    • To Opt Out of Marketing Communications: You can
                    unsubscribe from our marketing emails by clicking the
                    "unsubscribe" link at the bottom of any marketing email you
                    receive from us.
                    <br />
                    • To Access and Update Your Information: You can access and
                    update your information through your account settings on the
                    services.
                    <br />
                    <br />
                    <h5>4. Data Retention</h5>
                    We will retain your information for a short period to
                    provide the services, comply with our legal obligations,
                    resolve disputes, and enforce our agreements.
                    <br />
                    <br />
                    <h5>5. Security</h5>
                    We take appropriate steps to protect your information from
                    unauthorized access, disclosure, alteration, or destruction.
                    <br />
                    However, no internet transmission or electronic storage is
                    completely secure.
                    <br />
                    We implement industry-standard security measures to
                    safeguard your data.
                    <br />
                    <br />
                    <h5>6. Changes to this Privacy Policy</h5>
                    We may update this Privacy Policy from time to time without
                    pre-informing.
                    <br />
                    <br />
                    <h5>Contact Us</h5>
                    If you have any questions about this Privacy Policy, please
                    contact us at <Link to="">info@digilion.in</Link> or call on
                    91-9899648886.
                    <br />
                  </>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
}
