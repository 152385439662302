import React, { useState, useEffect } from "react";
import TopNavBar from "../../../layouts/topNavBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../../redux/Authentication/auth.action";

export default function OrgBasicInformation() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const store = useSelector(state => state);
  const userDetail = store?.AuthManager?.userDetail?.userdetail;

  const [companyName, setCompanyName] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [registrationNo, setRegistrationNo] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const dropdownOptions = ['Option 1', 'Option 2', 'Option 3'];
  const [detail, setDetail] = useState(null);

  useEffect(()=>{
    dispatch(getUserDetail())
  },[])

  useEffect(()=>{
    if(userDetail?.name && userDetail?.company ){
      setDetail({...userDetail})
    }
  },[userDetail])

  const handleSkip = () => {
    navigate("/org-basic-information1");
  };
  const handleNext = () => {
    const data = {
      name: companyName ? companyName : "NA" ,
      gst: gstNo ? gstNo : "NA",
      reg_no: registrationNo ? registrationNo : "NA",
    };
    navigate("/org-basic-information1", { state: { data } });
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
    const filtered = dropdownOptions.filter(option => option.toLowerCase().includes(event.target.value.toLowerCase()));
    setFilteredOptions(filtered);
  };

  const handleOptionSelect = (option) => {
    setCompanyName(option);
  };

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <aside>
            <div className="blurred-widget-block">
              <div className="inner-div-sidebar">
                <div className="profile-status">
                  <div className="inner-ps d-flex justify-content-center align-items-center">
                    <img src="/images/icons/icon-circle.svg" alt="cirle" />
                    <span className="tilte fw600 fz14">Profile Status</span>
                    <span className="tilte-percentage fw400 fz14 ms-4">30%</span>
                  </div>
                </div>
                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img src="/images/icons/icon-checked.svg" alt="un-checked" />
                      <span className="inner-title">Basic Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal assistance and sharing
                    </p>
                  </div>
                </div>

                <div className="white-box-unchecked">
                  <div className="white-box-inner">
                    <img src="/images/icons/icon-unchecked.svg" alt="un-checked" />
                    <span className="inner-title">Detailed Information</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="green-theme-block">
              <img src="/images/illustrations/image-coffee-together.svg" alt="" className="mx-auto" />

              <h2 className="mb-0 font-secondary fw600 text-white fz16">
                Let’s have coffee together
              </h2>

              <p className="mb-0 fz10">
                In need of any sort of assistance? We are here to help you!
              </p>

              <button className="text-uppercase fw600 fz10 bdrs10 btn btn-solid btn-solid--green">
                <span className="lh-1">Let’s chat!</span>
              </button>
            </div>
          </aside>
          <section className="body-profile2">
            <div className="title">
              <img className="d-inline-flex" src="/images/icons/icon-left-awrrow.svg" alt="" />
              <span className="h-title-teal">Organization</span><span className="h-title-black"> - Basic Information</span>
            </div>
            <div className="body-context height-adjust-context">
              <div className="btn-wrapper">
                <button className="custom-btn btn-light" onClick={handleSkip}>skip {">"}</button>
                <button className="custom-btn btn-dark" onClick={handleNext}>Next</button>
              </div>
              <div className="inner-body-context">
                <h6 className="heading-profiles">Company Information</h6>
                <p className="details-grey">
                  Please select the basic information about you so we may know you better
                </p>
                <div className="d-flex">
                  <img className="mx-auto mt-2" src="/images/horizontal-line.svg" alt="line" />
                </div>

                <form className="docur-form">
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">Company Name - </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          value={ detail?.company?.name? detail?.company?.name : companyName}
                          onChange={handleCompanyNameChange}
                          placeholder="select company or add manually.."
                        />
                        <div className="btn-fab-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                          <img src="/images/icons/icon-dropdown-arrow.svg" alt="" />
                        </div>

                        <div className="w-100 dropdown-menu">
                          <ul className="list-inline mb-0 product-type-list">
                            {filteredOptions.map((option, index) => (
                              <li key={index}>
                                <a href="#" className="typr-pair" onClick={() => handleOptionSelect(option)}>
                                  <span className="fz14">{option}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">Company GST No - </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input type="text" placeholder="#90769" value={detail?.company?.gst? detail?.company?.gst : gstNo} onChange={(e) => setGstNo(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">Company Registration No - </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input type="text" placeholder="#90769" value={detail?.company?.reg_no ? detail?.company?.reg_no :registrationNo} onChange={(e) => setRegistrationNo(e.target.value)} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="stepper position-relative">
                <img
                  className="stepper-divider"
                  src="/images/icons/icon-stepper-divider.svg"
                  alt="divider"
                />
                <a href="" className="active-stepper-steps bg-c-green">
                  <div className="rotate-text">Company basic details</div>
                </a>
                <a href="" className="stepper-steps bg-grey">
                  <div className="rotate-text">contact information</div>
                </a>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}
