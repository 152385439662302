import React from "react";
import TopNavBar from "../../layouts/topNavBar";
import SideNavBar from "../../layouts/sideNavBar";
import { Link } from "react-router-dom";

export default function ProfileMainPage() {
  return (
    <main className="position-relative page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <SideNavBar />
          <div className="body-profile">
            <div className="blur-2 w-100 profile-nav greetings">
              <div className="user-info">
                <div className="user-info__dp">
                  <div className="bg-white user-info__dp-inner">
                    <span className="bdrs12 fw700 fz34 text-white initials">
                      L
                    </span>
                    {/* <!-- <span className="bdrs12 user-image"></span> --> */}
                  </div>
                </div>

                <div className="user-info__name">
                  <h6 className="mb-1 fz16 fw500 primaryText">
                    <b className="fw600">Hi</b>, User!
                  </h6>
                  <h4 className="fz26 lh-1 mb-1 fw400">Welcome Onboard!</h4>
                </div>
              </div>

              <div className="happy-img">
                <span>
                  <img src="/images/Happy to see you.svg" alt="happy" />
                </span>
                <img src="/images/happy.png" alt="happy" />
              </div>
              <img
                className="position-absolute wave2"
                src="/images/orange-wave-2.svg"
                alt="wave2"
              />
              <img
                className="position-absolute red-square"
                src="/images/red-sqaure-tilt.svg"
                alt="sqaure-tilt"
              />
              <img
                className="position-absolute red-star"
                src="/images/red-star.svg"
                alt="red-star"
              />
              <img
                className="position-absolute black-cross"
                src="/images/black-cross.svg"
                alt="black-cross"
              />
              <img
                className="position-absolute black-minus"
                src="/images/black-minus.svg"
                alt="black-minus"
              />
            </div>
            <div className="body-content">
              <h6 className="heading-b">Tell us about you!</h6>
              <p className="details">
                Please select the basic information about you so we may know you
                better
              </p>
              <div className="cards-wrapper">
                <div className="cards">
                  <Link to="/Indv-basic-information">
                    <div className="cards-img-wrapper">
                      <img
                        src="/images/individual-img.svg"
                        alt="individual-img"
                      />
                    </div>
                    <div className="cards-context">
                      <h6 className="cards-heading">Individual</h6>
                      <p className="cards-details">
                        I’m the only one who will use docurs for personal
                        assistance and sharing
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="cards">
                  <Link to="/org-basic-information">
                    <div className="cards-img-wrapper">
                      <img src="/images/organization.svg" alt="organization" />
                    </div>
                    <div className="cards-context">
                      <h6 className="cards-heading">Organization</h6>
                      <p className="cards-details">
                        I’m the only one who will use docurs for personal
                        assistance and sharing
                      </p>
                    </div>
                  </Link>
                </div>
                {/* <div className="cards">
                  <Link to="/your-information">
                    <div className="cards-img-wrapper">
                      <img src="/images/both.svg" alt="both" />
                    </div>
                    <div className="cards-context">
                      <h6 className="cards-heading">Both</h6>
                      <p className="cards-details">
                        I’m the only one who will use docurs for personal
                        assistance and sharing
                      </p>
                    </div>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </article>

      <div className="spiral-bottom-corner">
        <img src="/images/bg-particles/profile-si-bg.svg" alt="profile-si-bg" />
      </div>
    </main>
  );
}
