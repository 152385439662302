import React, { useState } from "react";
import TopNavBar from "../../../layouts/topNavBar";
import { useNavigate } from "react-router-dom";

export default function YourInformation() {
  const navigate = useNavigate();

  const [profileImage, setProfileImage] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactNo, setContactNo] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSkip = () => {
    navigate("/about-your-job");
  };
  const handleNext = () => {
    const data = {
      image: profileImage,
      name: name,
      gender: gender,
      contact_email: contactEmail,
      mobile: contactNo,
    };
    navigate("/about-your-job", { state: { data } });
  };

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <aside>
            <div className="blurred-widget-block">
              <div className="inner-div-sidebar">
                <div className="profile-status">
                  <div className="inner-ps d-flex justify-content-center align-items-center">
                    <div className="text-center">
                      <img src="/images/icons/icon-circle.svg" alt="cirle" />
                    </div>
                    <span className="tilte fw600 fz14">Profile Status</span>
                    <span className="tilte-percentage fw400 fz14 ms-4">
                      30%
                    </span>
                  </div>
                </div>
                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img src="/images/icons/icon-checked.svg" alt="checked" />
                      <span className="inner-title">Your Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal
                      assistance and sharing
                    </p>
                  </div>
                </div>

                <div className="white-box-unchecked">
                  <div className="white-box-inner">
                    <img
                      src="/images/icons/icon-unchecked.svg"
                      alt="un-checked"
                    />
                    <span className="inner-title">Company Information</span>
                  </div>
                </div>
                <div className="white-box-unchecked">
                  <div className="white-box-inner">
                    <img
                      src="/images/icons/icon-unchecked.svg"
                      alt="un-checked"
                    />
                    <span className="inner-title">Summary</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="green-theme-block">
              <img
                src="/images/illustrations/image-coffee-together.svg"
                alt="image-coffee-together"
                className="mx-auto"
              />

              <h2 className="mb-0 font-secondary fw600 text-white fz16">
                Let’s have coffee together
              </h2>

              <p className="mb-0 fz10">
                In need of any sort of assistance? We are here to help you!
              </p>

              <button className="text-uppercase fw600 fz10 bdrs10 btn btn-solid btn-solid--green">
                <span className="lh-1">Let’s chat!</span>
              </button>
            </div>
          </aside>
          <section className="body-profile2">
            <div className="title">
              <img
                className="d-inline-flex"
                src="/images/icons/icon-left-awrrow.svg"
                alt="left-awrrow"
              />
              <span className="h-title-teal">Both</span>
              <span className="h-title-black"> - Your Introduction</span>
            </div>
            <div className="body-context height-adjust-context">
              <div className="btn-wrapper">
                <button className="custom-btn btn-light" onClick={handleSkip}>
                  skip {">"}
                </button>
                <button className="custom-btn btn-dark" onClick={handleNext}>
                  Next
                </button>
              </div>
              
              <div className="inner-body-context">
                <h6 className="heading-profiles">Your Introduction</h6>
                <p className="details-grey">
                  Please select the basic information about you so we may know
                  you better
                </p>
                <img
                  className="mx-auto mt-2"
                  src="/images/horizontal-line.svg"
                  alt="horizontal-line"
                />
                <form className="docur-form">
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Upload Your Photo -
                    </label>
                    <div className="p-2 w-100 blurred-widget-block bdr-gray">
                      <div className="bg-white upload-photo-comp">
                        <button className="position-relative bg-transparent upload-photo-btn">
                          <input
                            className="p-0"
                            type="file"
                            onChange={handleImageChange}
                          />

                          <div className="btn btn-solid">
                            <img
                              src="/images/user-dp-bg.svg"
                              alt="user-dp-bg"
                            />
                          </div>
                          <span className="text-capitalize fw500 fz14 greenThemeText">
                            Upload Profile Photo
                          </span>
                        </button>

                        <div className="p-instructions">
                          <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--png">
                            .PNG
                          </span>
                          <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                            .JPG
                          </span>
                          <span className="p-instructions__format fz10 fw500 p-instructions__format--10mb">
                            {">"}10 MB
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      What to call you?
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder="John Slay"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Your Gender -
                    </label>

                    <div className="bdr-gray bg-w-a49 shadow-34-05 bdrs10 options-wrapper-radio justify-content-around">
                      <div className="lh-1 d-inline-block radio-wrapper">
                        <input
                          id="choice1-1"
                          type="radio"
                          name="gender"
                          onChange={(e) => setGender(e.target.name)}
                        />
                        <label for="choice1-1">
                          <span></span>
                          <div>
                            <b className="fw400 fz14 grayText">Male</b>
                          </div>
                        </label>
                      </div>
                      <div className="lh-1 d-inline-block radio-wrapper">
                        <input
                          id="choice1-2"
                          type="radio"
                          name="gender"
                          onChange={(e) => setGender(e.target.name)}
                        />
                        <label for="choice1-2">
                          <span></span>
                          <div>
                            <b className="fw400 fz14 grayText">Female</b>
                          </div>
                        </label>
                      </div> 
                    </div>
                  </div> */}
                   <div className="form-row">
                    <label className="form-level d-flex align-items-center"
                      >Your Gender -</label
                    >
                    <div className="blurred-widget-block">
                      <div className="input-wrapper p-0">
                        <div className="options-wrapper">
                          <label className="radio-button">
                            <input
                              type="radio"
                              className="radio-button__input"
                              id="choice1-1"
                              name="gender"
                          onChange={(e) => setGender(e.target.name)}
                            />
                            <span className="radio-button__control"></span>
                            <span className="radio-button__label">Male</span>
                          </label>
                          <label className="radio-button">
                            <input
                              type="radio"
                              className="radio-button__input"
                              id="choice1-2"
                              name="gender"
                              onChange={(e) => setGender(e.target.name)}
                            />
                            <span className="radio-button__control"></span>
                            <span className="radio-button__label">Female</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      How to contact you?
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder="example@company.com"
                          onChange={(e) => setContactEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Where to call you?
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper p-0">
                        <div className="flag-dd">
                          <div className="dropdown">
                            <button
                              className="border-0 p-0 btn btn-secondary dropdown-toggle flag-dd__ff"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <div className="border-0 flag-dd__ff-inner">
                                <img
                                  src="/images/icons/icon-flag-india-lg-rounded.svg"
                                  alt="icon-flag-india-lg-rounded"
                                />
                              </div>
                              <b className="arrow">
                                <img
                                  src="/images/icons/icon-dropdown-line-arrow.svg"
                                  alt="icon-dropdown-line-arrow.svg"
                                />
                              </b>
                            </button>
                            <ul className="border-0 dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  <img
                                    src="/images/icons/icon-flag-india-lg-rounded.svg"
                                    alt="icon-flag-india-lg-rounded"
                                  />
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  <img
                                    src="/images/icons/icon-flag-india-lg-rounded.svg"
                                    alt="icon-flag-india-lg-rounded"
                                  />
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  <img
                                    src="/images/icons/icon-flag-india-lg-rounded.svg"
                                    alt="icon-flag-india-lg-rounded"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>

                          <span className="fw500 fz14 grayText ps-2 fst-italic dd-value">
                            +91
                          </span>

                          <input
                            type="text"
                            className="form-control shadow-none fz14 ps-0 fw500 bg-transparent"
                            placeholder="0800 900 790"
                            onChange={(e) => setContactNo(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <!-- stepper below --> */}
              <div className="stepper position-relative">
                <img
                  className="stepper-divider"
                  src="/images/icons/icon-stepper-divider.svg"
                  alt="icon-stepper-divider"
                />
                <a href="" className="active-stepper-steps bg-c-green">
                  <div className="rotate-text">
                    wants to know about yourself
                  </div>
                </a>
                <a href="" className="stepper-steps bg-grey">
                  <div className="rotate-text">Few more things</div>
                </a>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}
