import React,{useState, useEffect } from 'react'
import { Link, useLocation} from 'react-router-dom';

export default function SideNavBar() {

  let { pathname } = useLocation();
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
 switch(true){
  case overviewPages.includes(pathname):
    setActivePage("overview");
    break;
  case myDocumentsPages.includes(pathname):
    setActivePage("myDocuments");
    break;
  case sharedDocuments.includes(pathname):
    setActivePage("sharedDocuments");
    break;  

  default:
    break;
 }
  }, [pathname]);

  const overviewPages = ["/dashboard", "/upload-document", "/document-privacy", "/set-document-privacy", "/document-preview"];
  const myDocumentsPages = ["/documents"];
  const sharedDocuments = ["/shared-documents"];

  return (
    <aside className='mt-0'>
    <nav className="list-inline blurred-widget-block navigation">
      <li>
        <Link to="/dashboard"
            className={`nav-links ${
              activePage === "overview" ? "nav-links--active" : ""
            }`} >
          <div className="nav-links__inner">
            <span>
              <img src="/images/icons/icon-overview-nav-link.svg" alt="overview-nav-link"/>
            </span>
            <strong className="fz12">
              Overview
            </strong>
          </div>
        </Link>
      </li>
      <li>
        <Link to="/documents"  className={`nav-links ${
              activePage === "myDocuments" ? "nav-links--active" : ""
            }`}>
          <div className="nav-links__inner">
            <span>
              <img src="/images/icons/icon-my-documents-nav-link.svg" alt="y-documents-nav-link"/>
            </span>
            <strong className="fz12">
              My Documents
            </strong>
          </div>
        </Link>
      </li>
      <li>
        <Link to="/shared-documents"  className={`nav-links ${
              activePage === "sharedDocuments" ? "nav-links--active" : ""
            }`}>
          <div className="nav-links__inner">
            <span>
              <img src="/images/icons/icon-shared-documents-nav-link.svg" alt="shared-documents-nav-link"/>
            </span>
            <strong className="fz12">
              Shared Documents
            </strong>
          </div>
        </Link>
      </li>
      <li>
        <a href="" className="nav-links">
          <div className="nav-links__inner">
            <span>
              <img src="/images/icons/icon-verification-nav-link.svg" alt="verification-nav-link" />
            </span>
            <strong className="fz12">
              Verification
            </strong>
          </div>
        </a>
      </li>
      <li>
        <a href="" className="nav-links">
          <div className="nav-links__inner">
            <span>
              <img src="/images/icons/icon-report-analytics-nav-link.svg" alt="report-analytics-nav-link"/>
            </span>
            <strong className="fz12">
              Report Analytics
            </strong>
          </div>
        </a>
      </li>
      {/* <li>
        <a href="" className="nav-links">
          <div className="nav-links__inner">
            <span>
              <img src="/images/icons/icon-knowledge-nav-link.svg" alt="knowledge-nav-link"/>
            </span>
            <strong className="fz12">
              Zero Knowledge Proof
            </strong>
          </div>
        </a>
      </li> */}
      {/* <li>
        <a href="" className="nav-links">
          <div className="nav-links__inner">
            <span>
              <img src="/images/icons/icon-api-integration-nav-link.svg" alt="api-integration-nav-link" />
            </span>
            <strong className="fz12">
              API Integration
            </strong>
          </div>
        </a>
      </li> */}
    </nav>

    <div className="green-theme-block">
      <img src="/images/illustrations/image-coffee-together.svg" alt="image-coffee-together" className="mx-auto" />

      <h2 className="mb-0 font-secondary fw600 text-white fz16">Let’s have coffee together</h2>

        <p className="mb-0 fz10">
          In need of any sort of assistance?
          We are here to help
          you!
        </p>

        <button className="text-uppercase fw600 fz10 bdrs10 btn btn-solid btn-solid--green">
          <span className="lh-1">Let’s chat!</span>
        </button>

    </div>
  </aside>
  )
}
