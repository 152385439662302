import React, { useState, useEffect } from "react";
import AuthFooterRight from "../../layouts/authLayouts/authFooterRight";
import AuthContentsRight from "../../layouts/authLayouts/authContentsRight";
import AuthFooterLeft from "../../layouts/authLayouts/authFooterLeft";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authGetOTP } from "../../redux/Authentication/auth.action";
import { ToastContainer, toast } from "react-toastify";

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState();

  useEffect(() => {
    localStorage.removeItem("userEmail1");
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNext();
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email) || email === "") {
      toast.error('Empty or Invalid email format', {
        autoClose: 1000,
      });
      return;
    }
    localStorage.setItem("userEmail1", email);
    const payLoad = { email: email };
    dispatch(authGetOTP(payLoad));
    setEmail("");
    navigate("/signUp-otp");
  };

  return (
    <main className="auth-layout">
      <div className="auth-layout__left">
        <div className="auth-form-area">
          <img
            src="/images/logos/logo-dark.svg"
            alt="logo-dark"
            className="logo"
          />

          <form action="#">
            <h1 className="mb-3 font-secondary fw700 text-capitalize fz26">
              Sign Up
            </h1>

            <div className="form-group form-group--email">
              <div className="bdrs12 fab fab--search">
                <img
                  src="/images/icons/icon-email-envelope.svg"
                  alt="email-envelope"
                />
              </div>

              <input
                type="text"
                className="border-0 bdrs12 fz14 form-control"
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
                onKeyUp={handleKeyPress}
              />
            </div>

            <em className="fw400 fz12 grayText">
              We need your email address to send OTP for registration.
            </em>

            <button
              className="fz10 fw600 w-100 text-uppercase btn btn-solid btn-solid--dark"
              onClick={handleNext}
            >
              <span>Next</span>
            </button>

            <div className="primaryDarkText fz12 fw500 or">OR</div>

            <div className="grayText fz12 mx-auto alternative">
              <span className="fw500 fst-italic">
                Already have an account?{" "}
              </span>
              <Link
                to="/signIn"
                className="fw600 fz12 greenThemeText text-uppercase ms-1"
              >
                SIGN IN
              </Link>
            </div>
            <ToastContainer />
          </form>
          <AuthFooterLeft />
        </div>
      </div>
      <div className="auth-layout__right">
        <div className="auth-features">
          <AuthContentsRight />
          <AuthFooterRight />
        </div>
      </div>
    </main>
  );
}
