import React, { useState, useEffect } from "react";
import TopNavBar from "../../../layouts/topNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserDetail } from "../../../redux/Authentication/auth.action";
import { useDispatch, useSelector } from "react-redux";
import { getFileType } from "../../../services/utils";
import { IMAGE_PROXY_URL } from "../../../services/Api";
import PhoneInput from "react-phone-number-input";
import flags from 'country-flag-icons/react/3x2'

export default function OrgDetailedInformation() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const userDetail = store?.AuthManager?.userDetail?.userdetail;
  const [detail, setDetail] = useState(null);
  const [image, setImage] = useState(null);

  const [name, setName] = useState("");
  const [designation, setDesignation] = useState(1);
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  // const [profileImage, setProfileImage] = useState("");
  const [file, setFile] = useState(null);

  const [filteredOptions, setFilteredOptions] = useState([]);
  const dropdownOptions = ["Option 1", "Option 2", "Option 3"];

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  useEffect(() => {
    if (userDetail?.name && userDetail?.company) {
      setDetail({ ...userDetail });
    }
  }, [userDetail]);

  useEffect(() => {
    if (userDetail?.image) {
      setImage(`${IMAGE_PROXY_URL}${userDetail?.image}`);
    }
  }, [userDetail?.image]);

  const handleSelectDesignation = (event) => {
    setDesignation(event.target.value);
    const filtered = dropdownOptions.filter((option) =>
      option.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleOptionSelect = (option) => {
    setDesignation(option);
  };

  const handleSkip = () => {
    navigate("/org-detailed-information1");
  };
  const handleNext = () => {
    const userData = {
      name: name ? name : "NA",
      designation: designation ? designation : "NA",
      contact: contact ? contact : "NA",
      contact_email: email ? email : "NA",
      image: file ? file : "NA",
    };
    navigate("/org-detailed-information1", {
      state: { userData: userData, orgData: state?.data1 },
    });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const imageUrl = reader.result;

        try {
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const convertedFile = new File([blob], file.name, {
            type: blob.type,
          });
          setFile(convertedFile);
          setImage(imageUrl);
        } catch (error) {
          console.error("Error converting image URL to file:", error);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <aside>
            <div className="blurred-widget-block">
              <div className="inner-div-sidebar">
                <div className="profile-status">
                  <div className="inner-ps d-flex justify-content-center align-items-center">
                    <img src="/images/icons/icon-circle.svg" alt="cirle" />
                    <span className="tilte fw600 fz14">Profile Status</span>
                    <span className="tilte-percentage fw400 fz14 ms-4">
                      30%
                    </span>
                  </div>
                </div>
                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img
                        src="/images/icons/icon-checked.svg"
                        alt="un-checked"
                      />
                      <span className="inner-title">Basic Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal
                      assistance and sharing
                    </p>
                  </div>
                </div>
                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img
                        src="/images/icons/icon-checked.svg"
                        alt="un-checked"
                      />
                      <span className="inner-title">Detailed Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal
                      assistance and sharing
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="green-theme-block">
              <img
                src="/images/illustrations/image-coffee-together.svg"
                alt=""
                className="mx-auto"
              />

              <h2 className="mb-0 font-secondary fw600 text-white fz16">
                Let’s have coffee together
              </h2>

              <p className="mb-0 fz10">
                In need of any sort of assistance? We are here to help you!
              </p>

              <button className="text-uppercase fw600 fz10 bdrs10 btn btn-solid btn-solid--green">
                <span className="lh-1">Let’s chat!</span>
              </button>
            </div>
          </aside>
          <section className="body-profile2">
            <div className="title">
              <img
                className="d-inline-flex"
                src="/images/icons/icon-left-awrrow.svg"
                alt=""
              />
              <span className="h-title-teal">Organization</span>
              <span className="h-title-black"> - Detailed Information</span>
            </div>
            <div className="body-context height-adjust-context">
              <div className="btn-wrapper">
                <button className="custom-btn btn-light" onClick={handleSkip}>
                  skip {">"}
                </button>
                <button className="custom-btn btn-dark" onClick={handleNext}>
                  Next
                </button>
              </div>
              <div className="inner-body-context">
                <h6 className="heading-profiles">Your introduction</h6>
                <p className="details-grey">
                  Please select the basic information about you so we may know
                  you better
                </p>
                <img
                  className="mx-auto mt-2"
                  src="/images/horizontal-line.svg"
                  alt="line"
                />
                <form className="docur-form">
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Upload Your Photo -
                    </label>
                    <div className="p-2 w-100 blurred-widget-block bdr-gray">
                      <div className="bg-white upload-photo-comp">
                        <button className="position-relative bg-transparent upload-photo-btn">
                          <input
                            className="p-0"
                            type="file"
                            onChange={handleImageChange}
                          />

                          {detail?.image || image ? (
                            <div className="btn btn-solid">
                              <img src={image} alt="user" />
                            </div>
                          ) : (
                            <div className="btn btn-solid">
                              <img
                                src="/images/user-dp-bg.svg"
                                alt="user-dp-bg"
                              />
                            </div>
                          )}
                          <span className="text-capitalize fw500 fz14 greenThemeText">
                            {file?.name
                              ? file?.name
                              : detail?.image
                              ? detail?.image
                              : "Upload Profile Photo"}
                          </span>
                        </button>
                        <div className="p-instructions">
                          {getFileType(file?.name) === "png" ? (
                            <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--png">
                              .PNG
                            </span>
                          ) : getFileType(file?.name) === "jpg" ? (
                            <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                              .JPG
                            </span>
                          ) : getFileType(file?.name) === "jpeg" ? (
                            <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                              .JPG
                            </span>
                          ) : (
                            ""
                          )}

                          <span className="p-instructions__format fz10 fw500 p-instructions__format--10mb">
                            {"<"}10 MB
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Your Name -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder="john slay"
                          value={detail?.name ? detail?.name : name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-row">
                    <label className="form-level d-flex align-items-center"
                      >Your Designation -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <div className="dropdown dropdown-inputs">
                          <a
                            className="position-relative form-control--link form-control border-0"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="fw400 fz12 grayText fst-italic">
                                Admin
                            </span>
                            <div className="btn-fab-dropdown">
                              <img
                                src="/images/icons/icon-dropdown-arrow.svg"
                                alt=""
                              />
                            </div>
                          </a>

                          <div className="w-100 dropdown-menu">
                            <ul className="list-inline mb-0 product-type-list">
                              <li>
                                <a href="#" className="typr-pair">
                                  <span className="fz14">Admin</span>
                                </a>
                              </li>
                              <li>
                                <a href="#" className="typr-pair active">
                                  <span className="fz14">Super Admin</span>
                                </a>
                              </li>
                              <li>
                                <a href="#" className="typr-pair">
                                  <span className="fz14">HR</span>
                                </a>
                              </li>
                              <li>
                                <a href="#" className="typr-pair">
                                  <span className="fz14">Operator</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Your Designation -{" "}
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          value={designation == 1 ? "Admin" : ""}
                          onChange={handleSelectDesignation}
                          placeholder="select designation or add manually.."
                        />
                        <div
                          className="btn-fab-dropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="/images/icons/icon-dropdown-arrow.svg"
                            alt=""
                          />
                        </div>

                        <div className="w-100 dropdown-menu">
                          <ul className="list-inline mb-0 product-type-list">
                            {filteredOptions.map((option, index) => (
                              <li key={index}>
                                <a
                                  href="#"
                                  className="typr-pair"
                                  onClick={() => handleOptionSelect(option)}
                                >
                                  <span className="fz14">{option}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Your contact number -
                    </label>
                      <div className="blurred-widget-block">
                      <div className="input-wrapper p-0">
                        <div className="flag-dd">
                          <PhoneInput
                            international
                            defaultCountry="IN"
                            value={detail?.mobile ? detail?.mobile : contact}
                            onChange={setContact}
                            style={{
                              width: "380px",
                              height : "70%"
                          }}
                          flags={flags}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Your email address -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder="example.company.com"
                          value={
                            detail?.contact_email
                              ? detail?.contact_email
                              : email
                          }
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <!-- stepper below --> */}
              <div className="stepper position-relative">
                <img
                  className="stepper-divider"
                  src="/images/icons/icon-stepper-divider.svg"
                  alt="divider"
                />
                <div className="active-stepper-steps bg-c-green">
                  <div className="rotate-text">
                    wants to know about yourself
                  </div>
                </div>
                <div className="stepper-steps bg-grey">
                  <div className="rotate-text">Few more things</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}
