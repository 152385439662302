import { ADD_BOTH_PROFILE_FAILURE, ADD_BOTH_PROFILE_SUCCESS, ADD_INDV_PROFILE_FAILURE, ADD_INDV_PROFILE_IN_ORG_FAILURE, ADD_INDV_PROFILE_IN_ORG_SUCCESS, ADD_INDV_PROFILE_SUCCESS, ADD_ORG_PROFILE_FAILURE, ADD_ORG_PROFILE_SUCCESS } from "./profile.actionTypes";

const initialState = {
  indvProfile: null,
  bothProfile: null,
};

export default function profileReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_INDV_PROFILE_SUCCESS:
      return {
        ...state,
        indvProfile: payload,
      };
    case ADD_INDV_PROFILE_FAILURE:
      return state;

      case ADD_ORG_PROFILE_SUCCESS:
        return {
          ...state,
          indvProfile: payload,
        };
      case ADD_ORG_PROFILE_FAILURE:
        return state;

    case ADD_BOTH_PROFILE_SUCCESS:
      return {
        ...state,
        bothProfile: payload,
      };
    case ADD_BOTH_PROFILE_FAILURE:
      return state;

      case ADD_INDV_PROFILE_IN_ORG_SUCCESS:
        return {
          ...state,
          indvProfileInOrg: payload,
        };
      case ADD_INDV_PROFILE_IN_ORG_FAILURE:
        return state;

    default:
      return state;
  }
}
