import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthFooterRight from "../../layouts/authLayouts/authFooterRight";
import AuthFooterLeft from "../../layouts/authLayouts/authFooterLeft";
import AuthContentsRight from "../../layouts/authLayouts/authContentsRight";
import { Link, useNavigate } from "react-router-dom";
import { allUsersOpen, authGetOTP } from "../../redux/Authentication/auth.action";
import { useDispatch, useSelector } from "react-redux";

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const store = useSelector((state) => state);
  const userDetails = store?.AuthManager?.allUsersOpen;

  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    dispatch(allUsersOpen());
  }, []);

  useEffect(() => {
    const storedUsername = localStorage.getItem('userEmail');
    if (storedUsername) {
      setEmail(storedUsername);
      setRememberMe(true);
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNext(e);
    }
  };

  const path = window.location.pathname;
  
  const handleNext =(e)=>{
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email) || email === "") {
      toast.error('Empty or Invalid email format', {
        autoClose: 1000,
      }
      );
      return;
    }

    if(path !== "/signIn"){
      rememberMe ? localStorage.setItem('userEmail', email): localStorage.removeItem('userEmail');
      const payLoad = { email: email };
      dispatch(authGetOTP(payLoad));
      navigate(`/signIn-otp${path}`)

    }else{
      const search = userDetails?.some((item) => item?.email === email);
      if(search){
        rememberMe ? localStorage.setItem('userEmail', email): localStorage.removeItem('userEmail');
        const payLoad = { email: email };
        dispatch(authGetOTP(payLoad));
        navigate("/signIn-otp")
      }else{
        toast.error("Please sign-up first!", {
          autoClose: 1000,
        })
      }
    
    }

  }

  return (
    <main className="auth-layout">
      <div className="auth-layout__left">
        <div className="auth-form-area">
          <img src="/images/logos/logo-dark.svg" alt="" className="logo" />
          {/* <form action="#"> */}
          <form >
            <h1 className="mb-3 font-secondary fw700 text-capitalize fz26">
              Sign In
            </h1>
            <div className="form-group form-group--email">
              <div className="bdrs12 fab fab--search">
                <img src="/images/icons/icon-email-envelope.svg" alt="Search" />
              </div>
              <input
                type="text"
                className="border-0 bdrs12 fz14 form-control"
                placeholder="Email Address"
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                onKeyUp={handleKeyPress}
              />
            </div>

            <div className="lh-1 checkbox-wrapper">
              <input id="remember" type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
              <label for="remember">
                <span></span>
                <div>
                  <b className="fw400 fz12 fst-italic grayText">Remember Me</b>
                </div>
              </label>
            </div>

            <button className="fz10 fw600 w-100 text-uppercase btn btn-solid btn-solid--dark" onClick={handleNext}>
              <span>Next</span>
            </button>
            <div className="primaryDarkText fz12 fw500 or">OR</div>
            <div className="grayText fz12 mx-auto alternative">
              <span className="fw500 fst-italic">Don’t have an account?</span>
              <Link
                to="/signUp"
                className="fw600 fz12 greenThemeText text-uppercase ms-1"
              >
                SIGN UP
              </Link>
            </div>
          </form>
          <AuthFooterLeft />
        </div>
      </div>
      <div className="auth-layout__right">
        <div className="auth-features">
          <AuthContentsRight />
          <AuthFooterRight />
        </div>
      </div>  
      <ToastContainer />
    </main>
  );
}
