import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets_landing/styles/landing.css";
import { ToastContainer, toast } from "react-toastify";

export default function TermsAndConditions() {
  const navigate = useNavigate();
  const handleBackButton = (e) => {
    e.preventDefault();
    navigate("/SignIn");
  };
  return (
    <>
      <div
        style={{ paddingTop: "calc(50px* var(--resp))", paddingBottom: "20px" }}
      >
        <section>
          <div className="container">
            <section>
              <div className="container">
                <p onClick={handleBackButton}>
                  <img
                    className="left-arrow-long d-none d-md-block"
                    src="/images/menu-arrow-left.svg"
                    alt=""
                    style={{ width: "40px", height: "12px", cursor: "pointer" }}
                  />
                </p>
                <br />
                <div>
                  <h1>Terms & Conditions </h1>
                  <br />
                  <h2>Introduction</h2>
                  Welcome to DOCUR! These Terms and Conditions ("Terms") govern
                  your use of our Blockchain-Based Document Sharing Platform. By
                  accessing or using DOCUR, you agree to comply with and be
                  bound by these Terms. Please read them carefully.
                  <br />
                  <br />
                  <h5>1. Acceptance of Terms </h5>
                  By using DOCUR, you agree to these Terms and our Privacy
                  Policy. If you do not agree, please do not use our platform.
                  <br /><br />
                  <h5>2. Definitions</h5>
                  - "Platform" refers to DOCUR and all its features and
                  services. <br />
                  - "User" or "You" refers to any individual or entity using
                  DOCUR.
                  <br />- "We", "Us", and "Our" refer to the operators of DOCUR.{" "}
                  <br />
                  <br />
                  <h5>3. Eligibility </h5>
                  You must be at least 18 years old to use DOCUR. By using our
                  platform, you represent and warrant that you meet this
                  requirement. <br /><br />
                  <h5> 4. Account Registration </h5>
                  To access certain features, you may need to create an account.
                  You agree to provide accurate information and keep it updated.
                  You are responsible for maintaining the confidentiality of
                  your account credentials and for all activities under your
                  account. <br />
                  <br />
                  <h5> 5. Features and Services</h5>
                  DOCUR offers various features designed to enhance document
                  sharing and management: <br />
                  - Secure Document Storage <br />
                  - Blockchain-based Verification <br />
                  - Confidential Data Sharing <br />
                  - Immutable Records <br />
                  - Access Control <br />
                  - Audit Trail <br />
                  - Real-time Collaboration <br />
                  - Version Control <br />
                  - Data Encryption <br />
                  - Compliance Tools <br />
                  - Analytics and Insights <br />
                  These features are subject to change, and we reserve the right
                  to modify, suspend, or discontinue any aspect of DOCUR at any
                  time without notice. <br />
                  <br />
                  <h5>6. User Responsibilities</h5>
                  You agree to use DOCUR for lawful purposes and in compliance
                  with all applicable laws and regulations. <br />
                  You must not: <br />
                  - Use DOCUR for any fraudulent or malicious activity. <br />
                  - Interfere with or disrupt DOCUR's operation. <br />
                  - Attempt to gain unauthorized access to DOCUR or other users'
                  accounts. <br />
                  - Share documents that infringe on intellectual property
                  rights or contain illegal content. <br />
                  <br />
                  <h5>7. Data Security</h5>
                  We take data security seriously and implement measures to
                  protect your information. However, we cannot guarantee
                  absolute security. You acknowledge that you provide your data
                  at your own risk. <br />
                  <br />
                  <h5>8. Intellectual Property</h5>
                  All content and features on DOCUR, including text, graphics,
                  logos, and software, are the property of DOCUR or its
                  licensors and are protected by intellectual property laws. You
                  may not use, reproduce, or distribute any content without our
                  express written permission. <br />
                  <br />
                  <h5>9. Termination</h5>
                  We reserve the right to suspend or terminate your access to
                  DOCUR at our discretion, without notice, for conduct that we
                  believe violates these Terms or is harmful to other users or
                  the platform. <br />
                  <br />
                  <h5>10. Limitation of Liability</h5>
                  DOCUR is provided "as is" and "as available." We do not
                  warrant that the platform will be uninterrupted or error-free.
                  To the maximum extent permitted by law, we disclaim all
                  warranties and liabilities. <br />
                  <br />
                  <h5>11. Indemnification</h5>
                  You agree to indemnify and hold harmless DOCUR, its
                  affiliates, and their respective officers, directors,
                  employees, and agents from any claims, liabilities, damages,
                  and expenses arising from your use of the platform or
                  violation of these Terms. <br />
                  <br />
                  <h5>12. Governing Law </h5>
                  These Terms are governed by and construed in accordance with
                  the laws of the jurisdiction in which DOCUR operates, without
                  regard to its conflict of law principles.
                  <br />
                  <br />
                  <h5>13. Changes to Terms</h5>
                  We may update these Terms from time to time. We will notify
                  you of any significant changes, and your continued use of
                  DOCUR after such changes constitutes your acceptance of the
                  new Terms. <br />
                  <br />
                  <h5> 14. Contact Us </h5>
                  If you have any questions or concerns about these Terms,
                  please contact us at <Link to="">[info@digilion.in] </Link>
                  By using DOCUR, you acknowledge that you have read,
                  understood, and agree to be bound by these Terms and
                  Conditions.
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
}
