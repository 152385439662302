import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineChart = ({ data1, data2, }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d');
    if (ctx) {
      const gradientRed = ctx.createLinearGradient(0, 0, 900, 0);
      gradientRed.addColorStop(0, "rgba(255, 113, 105,0.2)");
      gradientRed.addColorStop(0.5, "rgba(255, 113, 105,1)");
      gradientRed.addColorStop(1, "rgba(255, 113, 105,0.12)");

      const gradientGreen = ctx.createLinearGradient(0, 0, 900, 0);
      gradientGreen.addColorStop(0, "rgba(54, 184, 163,0.12)");
      gradientGreen.addColorStop(0.5, "rgba(54, 184, 163,1)");
      gradientGreen.addColorStop(1, "rgba(54, 184, 163,0.08)");

      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              data: data1,
              fill: false,
              borderColor: gradientRed,
              backgroundColor: "#FF7169",
              borderWidth: 2,
              tension: 0.4,
              order: 1,
              pointBackgroundColor: "white",
              pointBorderWidth: 1,
              pointRadius: 5,
              hitRadius: 50,
              pointHoverRadius: 6,
              pointHoverBackgroundColor: "#FF7169",
              pointHoverBorderColor: "white",
              pointHoverBorderWidth: 2,
            },
            {
              data: data2,
              fill: false,
              borderColor: gradientGreen,
              backgroundColor: "#36B8A3",
              borderWidth: 2,
              tension: 0.4,
              order: 2,
              pointBackgroundColor: "white",
              pointBorderWidth: 1,
              pointRadius: 5,
              hitRadius: 50,
              pointHoverRadius: 6,
              pointHoverBackgroundColor: "#36B8A3",
              pointHoverBorderColor: "white",
              pointHoverBorderWidth: 2,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            easing: "easeInOutQuad",
            duration: 520,
          },
          scales: {
            x: {
              border: {
                display: false,
              },
              grid: {
                display: true,
                color: "rgba(40, 40, 40, 0.05)",
              },
              ticks: {
                color: "#797C8C",
              },
            },
            y: {
              border: {
                display: false,
              },
              grid: {
                display: true,
                color: "rgba(40, 40, 40, 0.05)",
              },
              ticks: {
                color: "#797C8C",
                stepSize: 10,
              },
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data1, data2]);

  return <canvas id="twoLineChart" ref={chartRef} />;
};

export default LineChart;
