import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFolder, editFolder } from "../../redux/Documents/doc.action";
import { ToastContainer, toast } from "react-toastify";

export default function EditFolderName({props}) {
  const clicked = props?.clickEdit;
  const setClicked = props?.setClickEdit;
  const id = props?.folderId;

  const dispatch = useDispatch();

  const [folderName, setFolderName] = useState("");
  const [privacy, setPrivacy] = useState("");

  const handleChange = (e) => {
    setFolderName(e.target.value);
  };

  const handleCreate = () => {
    if (folderName) {
      const payLoad = { company: null, name: folderName, detail: null };
      dispatch(editFolder(payLoad, id));
    } else {
      toast.error("please write folder name", {
        autoClose: 1000,
      });
    }
  };

  const handleSelect = (e) => {
    setClicked(false);
    setPrivacy(e.target.name);
  };

  return (
    <div
      className="modal fade"
      id="edit-folder"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered model-custom-centered">
        <div className="modal-content mydoc1-modal-wrapper">
          <div className="mydoc1-modal-content">
            <img className="orange-wave" src="/images/orange-wave.svg" alt="" />
            <img className="blue-wave" src="/images/blue-wave.svg" alt="" />
            <button
              className="btn p-0 rounded-circle border-0 shadow-none cut-icon"
              data-bs-dismiss="modal"
            >
              <img src="/images/icons/icon-cross-red.svg" alt="cut" />
            </button>

            <div className="mydoc1-modal-header">
              <h3>Edit folder name</h3>
            </div>
            <p className="details custom-details-width">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut.
            </p>
            <div className="modal-form">
              <input
                className="fz14 me-3 folder-input"
                type="text"
                placeholder="Name your folder"
                onChange={handleChange}
              />

              <div className="dropdown flex-grow-1">
                <button
                  className="justify-content-between ps-3 w-100 fz14 btn shadow-15-11-08 bdrs12 dropdown-toggle btn-solid btn-solid--lightgrey"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {clicked ? "Set Privacy" : privacy}
                  <i className="fa fa-angle-down"></i>
                </button>
                <div className="border-0 bdrs10 mt-1 dropdown-menu dropdown-menu--modal shadow-15-11-08">
                  <a
                    className="dropdown-item fz16"
                    name="private"
                    onClick={(e) => handleSelect(e)}
                  >
                    Private
                  </a>
                  <a
                    className="dropdown-item fz16"
                    name="public"
                    onClick={(e) => handleSelect(e)}
                  >
                    Public
                  </a>
                </div>
              </div>
            </div>

            <button
              type="submit"
              data-bs-dismiss={folderName ? "modal" : ""}
              className="create-btn-dark"
              onClick={handleCreate}
            >
              <span>CREATE </span>
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
