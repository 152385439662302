import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_FOLDER_FAILURE,
  CREATE_FOLDER_SUCCESS,
  DELETE_FOLDER_FAILURE,
  DELETE_FOLDER_SUCCESS,
  DOC_UPLOAD_FAILURE,
  DOC_UPLOAD_SUCCESS,
  EDIT_FOLDER_FAILURE,
  EDIT_FOLDER_SUCCESS,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_SUCCESS,
  GET_FOLDER_FAILURE,
  GET_FOLDER_SUCCESS,
  GET_SHARE_DOCUMENT_FAILURE,
  GET_SHARE_DOCUMENT_SUCCESS,
  MOVE_FILE_TO_FOLDER_FAILURE,
  MOVE_FILE_TO_FOLDER_SUCCESS,
  PATCH_DOC_UPLOAD_FAILURE,
  PATCH_DOC_UPLOAD_SUCCESS,
  SHARE_DOCUMENT_FAILURE,
  SHARE_DOCUMENT_SUCCESS,
} from "./doc.actionTypes";
import {
  GET_CREATED_FOLDER,
  GET_DOCUMENTS,
  GET_SHARE_DOCUMENT,
  PATCH_ADD_FILE_TO_FOLDER,
  POST_CREATE_FOLDER,
  POST_SHARE_DOCUMENT,
  POST_UPLOAD_DOCUMENTS,
} from "../../services/Api";

export const uploadDocument = (data) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;

  const formData = new FormData();
  formData.append("file", data?.file);
  formData.append("permission", data?.permission);
  formData.append("about", data?.about);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const res = await axios.post(POST_UPLOAD_DOCUMENTS, formData, { headers });
    if (res.status === 201 && res.data.file !== "") {
      toast.success("File successfully uploaded",{
        autoClose: 1000,
      });
      dispatch({
        type: DOC_UPLOAD_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllDocuments());
    } else {
      toast.error("Please upload file again", {
        autoClose: 1000,
      });
      dispatch({
        type: DOC_UPLOAD_FAILURE,
        payload: {
          message: res?.data?.msg,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: DOC_UPLOAD_FAILURE,
      payload: {
        message: error,
      },
    });
  }
};

export const getAllDocuments = (token) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let resp = await axios.get(`${GET_DOCUMENTS}`, { headers });
    let data = resp?.data;
    if (data) {
      dispatch({
        type: GET_DOCUMENT_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: GET_DOCUMENT_FAILURE,
    });
  }
};

export const createFolder = (data) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.post(POST_CREATE_FOLDER, data, { headers });
    if (res.status === 201 && res.data) {
      toast.success("Folder successfully created", {
        autoClose: 1000,
      });
      dispatch({
        type: CREATE_FOLDER_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllFolders());
      dispatch(getAllDocuments());
    } else {
      toast.error("error! please create again", {
        autoClose: 1000,
      });
      dispatch({
        type: CREATE_FOLDER_FAILURE,
        payload: {
          message: res?.data?.msg,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_FOLDER_FAILURE,
      payload: {
        message: error,
      },
    });
  }
};

export const getAllFolders = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let resp = await axios.get(`${GET_CREATED_FOLDER}`, { headers });
    let data = resp?.data;
    if (data) {
      dispatch({
        type: GET_FOLDER_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: GET_FOLDER_FAILURE,
    });
  }
};

export const moveFileToFolder = (fileId, folderId) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;

  const formData = new FormData();
  formData.append("folder", folderId);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const res = await axios.patch(
      `${PATCH_ADD_FILE_TO_FOLDER}/${fileId}`,
      formData,
      { headers }
    );
    if (res.status === 201 && res.data !== "") {
      toast.success("File successfully moved", {
        autoClose: 1000,
      });
      dispatch({
        type: MOVE_FILE_TO_FOLDER_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllDocuments());
    } else {
      toast.error("Please drag file again", {
        autoClose: 1000,
      });
      dispatch({
        type: MOVE_FILE_TO_FOLDER_FAILURE,
        payload: {
          message: res?.data?.msg,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: MOVE_FILE_TO_FOLDER_FAILURE,
      payload: {
        message: error,
      },
    });
  }
};

export const shareDocument = (data) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.post(POST_SHARE_DOCUMENT, data, { headers });
    if (res.data) {
      toast.success("Invitation Link succesfully shared", {
        autoClose: 1000,
      });
      dispatch({
        type: SHARE_DOCUMENT_SUCCESS,
        payload: res.data,
      });
    } else {
      toast.error("error! please create again", {
        autoClose: 1000,
      });
      dispatch({
        type: SHARE_DOCUMENT_FAILURE,
        payload: {
          message: res?.data?.msg,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: SHARE_DOCUMENT_FAILURE,
      payload: {
        message: error,
      },
    });
  }
};

export const allSharedDocuments = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let resp = await axios.get(`${GET_SHARE_DOCUMENT}`, { headers });
    let data = resp?.data;
    if (data) {
      dispatch({
        type: GET_SHARE_DOCUMENT_SUCCESS,
        payload: data,
      });
      dispatch(getAllFolders());
      // window.location.reload();
    }
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: GET_SHARE_DOCUMENT_FAILURE,
    });
  }
};

export const uploadDocumentPrivacy = (data) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;

  const formData = new FormData();
  formData.append("file", data?.file);
  formData.append("permission", data?.permission);
  formData.append("about", data?.about);
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const res = await axios.patch(POST_UPLOAD_DOCUMENTS, formData, { headers });
    if (res.status === 201 && res.data.file !== "") {
      toast.success("File successfully uploaded", {
        autoClose: 1000,
      });
      dispatch({
        type: PATCH_DOC_UPLOAD_SUCCESS,
        payload: res.data,
      });
    } else {
      toast.error("Please upload file again", {
        autoClose: 1000,
      });
      dispatch({
        type: PATCH_DOC_UPLOAD_FAILURE,
        payload: {
          message: res?.data?.msg,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: DOC_UPLOAD_FAILURE,
      payload: {
        message: error,
      },
    });
  }
};

export const editFolder = (data, id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  
  try {
    const res = await axios.patch(`${POST_CREATE_FOLDER}/${id}`, data, { headers });
    if (res.status === 201 && res.data) {
      toast.success("Folder name changed successfully", {
        autoClose: 1000,
      });
      dispatch({
        type: EDIT_FOLDER_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllFolders());
      dispatch(getAllDocuments());
    } else {
      toast.error("error! please create again", {
        autoClose: 1000,
      });
      dispatch({
        type: EDIT_FOLDER_FAILURE,
        payload: {
          message: res?.data?.msg,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: EDIT_FOLDER_FAILURE,
      payload: {
        message: error,
      },
    });
  }
};

export const deleteFolder = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    // "Content-Type": "application/json",
  };
  toast.success("Folder successfully deleted", {
    autoClose: 1000,
  });
  try {
    const res = await axios.delete(`${POST_CREATE_FOLDER}/${id}`, { headers });
    // if (res) {
      toast.success("Folder successfully deleted", {
        autoClose: 1000,
      });
      dispatch({
        type: DELETE_FOLDER_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllFolders());
      dispatch(getAllDocuments());
    // } 
    // else {
      toast.error("error! please create again", {
        autoClose: 1000,
      });
      dispatch({
        type: DELETE_FOLDER_FAILURE,
        payload: {
          message: res?.data?.msg,
        },
      });
    // }
  } catch (error) {
    dispatch({
      type: DELETE_FOLDER_FAILURE,
      payload: {
        message: error,
      },
    });
  }
};
