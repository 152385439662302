import React, { useState, useEffect } from "react";
import TopNavBar from "../../../layouts/topNavBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../../redux/Authentication/auth.action";
import { getFileType } from "../../../services/utils";
import { IMAGE_PROXY_URL } from "../../../services/Api";

export default function IndvBasicInformation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [image, setImage] = useState(null);
  const userDetail = store?.AuthManager?.userDetail?.userdetail;
  const profileImg = store?.AuthManager?.userDetail?.userdetail?.image;
  const getGender = store?.AuthManager?.userDetail?.userdetail?.gender;

  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [file, setFile] = useState(null);
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  useEffect(() => {
    if (userDetail?.name && userDetail?.company === null) {
      setDetail({ ...userDetail });
    }
  }, [userDetail]);

  useEffect(() => {
    if (profileImg) {
      setImage(`${IMAGE_PROXY_URL}${profileImg}`);
    }
  }, [profileImg]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const imageUrl = reader.result;

        try {
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const convertedFile = new File([blob], file.name, {
            type: blob.type,
          });
          setFile(convertedFile);
          setImage(imageUrl); // Update the image state with the selected image URL
        } catch (error) {
          console.error("Error converting image URL to file:", error);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSkip = () => {
    navigate("/Indv-basic-information1");
  };
  const handleNext = (e) => {
    e.preventDefault();
    const data = {
      image: file,
      name: name,
      gender: gender,
    };
    navigate("/Indv-basic-information1", { state: { data } });
  };

  const handleGenderSelection = (e) => {
    setGender(e.target.value);
  };

  useEffect(() => {
    if (getGender) {
      setGender(getGender);
    }
  }, [getGender]);

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.trim() === "") {
      setName("");
    } else {
      setName(inputValue);
    }
  };

  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <aside>
            <div className="blurred-widget-block">
              <div className="inner-div-sidebar">
                <div className="profile-status">
                  <div className="inner-ps d-flex justify-content-center align-items-center">
                    <img src="/images/icons/icon-circle.svg" alt="cirle" />
                    <span className="tilte fw600 fz14">Profile Status</span>
                    <span className="tilte-percentage fw400 fz14 ms-4">
                      30%
                    </span>
                  </div>
                </div>
                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img src="/images/icons/icon-checked.svg" alt="checked" />
                      <span className="inner-title">Basic Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal
                      assistance and sharing
                    </p>
                  </div>
                </div>

                <div className="white-box-unchecked">
                  <div className="white-box-inner">
                    <img
                      src="/images/icons/icon-unchecked.svg"
                      alt="un-checked"
                    />
                    <span className="inner-title">Detailed Information</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="green-theme-block">
              <img
                src="/images/illustrations/image-coffee-together.svg"
                alt="image-coffee-together"
                className="mx-auto"
              />

              <h2 className="mb-0 font-secondary fw600 text-white fz16">
                Let’s have coffee together
              </h2>

              <p className="mb-0 fz10">
                In need of any sort of assistance? We are here to help you!
              </p>

              <button className="text-uppercase fw600 fz10 bdrs10 btn btn-solid btn-solid--green">
                <span className="lh-1">Let’s chat!</span>
              </button>
            </div>
          </aside>
          <section className="body-profile2">
            <div className="title">
              <img
                className="d-inline-flex"
                src="./assets/images/icons/icon-left-awrrow.svg"
                alt=""
              />
              <span className="h-title-teal">Individual</span>
              <span className="h-title-black"> - Basic Information</span>
            </div>
            <div className="body-context height-adjust-context">
              <div className="btn-wrapper">
                <button className="custom-btn btn-light" onClick={handleSkip}>
                  skip {">"}
                </button>
                <button className="custom-btn btn-dark" onClick={handleNext}>
                  Next
                </button>
              </div>
              <div className="inner-body-context">
                <h6 className="heading-profiles">Personal Information</h6>
                <p className="details-grey">
                  Please select the basic information about you so we may know
                  you better
                </p>
                <img
                  className="mx-auto mt-2"
                  src="/images/horizontal-line.svg"
                  alt="horizontal-line"
                />
                <form className="docur-form">
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Upload Your Photo -
                    </label>
                    <div className="p-2 w-100 blurred-widget-block bdr-gray">
                      <div className="bg-white upload-photo-comp">
                        <button className="position-relative bg-transparent upload-photo-btn">
                          <input
                            className="p-0"
                            type="file"
                            onChange={handleImageChange}
                          />
                          {detail?.image ? (
                            <div className="btn btn-solid">
                              <img src={image} alt="user-dp-bg" />
                            </div>
                          ) : image ? (
                            <div className="btn btn-solid">
                              <img src={image} alt="user-dp-bg" />
                            </div>
                          ) : (
                            <div className="btn btn-solid">
                              <img src="/images/user-dp-bg.svg" alt="" />
                            </div>
                          )}
                          <span className="text-capitalize fw500 fz14 greenThemeText">
                            {file?.name
                              ? file?.name
                              : detail?.image
                              ? detail?.image
                              : "Upload Profile Photo"}
                          </span>
                        </button>

                        <div className="p-instructions">
                          {getFileType(file?.name) === "png" ? (
                            <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--png">
                              .PNG
                            </span>
                          ) : getFileType(file?.name) === "jpg" ? (
                            <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                              .JPG
                            </span>
                          ) : getFileType(file?.name) === "jpeg" ? (
                            <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                              .JPG
                            </span>
                          ) : getFileType(file?.name) === "webp" ? (
                            <span className="p-instructions__format fz10 fw600 text-white p-instructions__format--jpg">
                              .Webp
                            </span>
                          ) : (
                            ""
                          )}

                          <span className="p-instructions__format fz10 fw500 p-instructions__format--10mb">
                            {"<"}10 MB
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      What to call you?
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          className="fz14"
                          type="text"
                          value={name || (detail && detail?.name) || ""}
                          placeholder="Name"
                          onChange={handleNameChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Your Gender -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper p-0">
                        <div className="options-wrapper">
                          <label className="radio-button">
                            <input
                              type="radio"
                              className="radio-button__input"
                              name="gender"
                              value="Male"
                              onClick={handleGenderSelection}
                              checked={gender === "Male"}
                            />
                            <span className="radio-button__control"></span>
                            <span className="radio-button__label">Male</span>
                          </label>
                          <label className="radio-button">
                            <input
                              type="radio"
                              className="radio-button__input"
                              name="gender"
                              value="Female"
                              onClick={handleGenderSelection}
                              checked={gender === "Female"}
                            />
                            <span className="radio-button__control"></span>
                            <span className="radio-button__label">Female</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <!-- stepper below --> */}
              <div className="stepper position-relative">
                <img
                  className="stepper-divider"
                  src="/images/icons/icon-stepper-divider.svg"
                  alt="stepper-divider"
                />
                <div className="active-stepper-steps bg-c-green">
                  <div className="rotate-text">Personal information</div>
                </div>
                <div className="stepper-steps bg-grey">
                  <div className="rotate-text">contact information</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}
