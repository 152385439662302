import React, { useState } from "react";
import TopNavBar from "../../../layouts/topNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import GooglePlacesAutocomplete from "../../../reusable_components/googlePlacesAutoComplete";
import axios from "axios";

export default function CompanyInformation() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [companyContactNo, setCompanyContactNo] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [companyRegistrationNo, setCompanyRegistrationNo] = useState("");
  const [addressFromLatLng, setAddressFromLatLng] = useState("");

  const fetchLocation = (e) => {
    // const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const apiKey = "AIzaSyBGMUJe2XUHhIZKXIrq4btVXK2s3zeqLLM";
    e.preventDefault();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
          axios
            .get(apiUrl)
            .then((response) => {
              if (response.data.status === "OK") {
                setAddressFromLatLng(
                  response.data.results[0].formatted_address
                );
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        },
        (error) => {
          if (error.code === 1) {
            retryFetchLocation();
          } else {
            console.error("Error fetching location:", error.message);
          }
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  };

  const retryFetchLocation = () => {
    const apiKey = "AIzaSyBGMUJe2XUHhIZKXIrq4btVXK2s3zeqLLM";
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
        axios
          .get(apiUrl)
          .then((response) => {
            if (response.data.status === "OK") {
              setAddressFromLatLng(response.data.results[0].formatted_address);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      },
      (error) => {
        console.error("Error fetching location:", error.message);
      }
    );
  };

  const handleSkip = () => {
    navigate("/summary");
  };
  const handleNext = () => {
    const data2 = {
      contact: companyContactNo,
      url: companyUrl,
      reg_no: companyRegistrationNo,
      location: addressFromLatLng,
    };
    navigate("/summary", { state: { userData: state.data1, companyData: data2 }});
  };
  return (
    <main className="page-layout page-layout--dashboard">
      <TopNavBar />

      <article className="w-100 px-0 content-layout">
        <div className="content-layout__inner">
          <aside>
            <div className="blurred-widget-block">
              <div className="inner-div-sidebar">
                <div className="profile-status">
                  <div className="inner-ps d-flex justify-content-center align-items-center">
                    <img src="/images/icons/icon-circle.svg" alt="cirle" />
                    <span className="tilte fw600 fz14">Profile Status</span>
                    <span className="tilte-percentage fw400 fz14 ms-4">
                      30%
                    </span>
                  </div>
                </div>
                <div className="white-box-unchecked">
                  <div className="white-box-inner">
                    <img src="/images/icons/icon-checked.svg" alt="checked" />
                    <span className="inner-title">Your Information</span>
                  </div>
                </div>
                <div className="white-box-checked">
                  <div className="blurred-widget-block blurred-costomised-div">
                    <div className="white-box-inner">
                      <img src="/images/icons/icon-checked.svg" alt="checked" />
                      <span className="inner-title">Company Information</span>
                    </div>
                    <p className="checked-content">
                      I’m the only one who will use docurs for personal
                      assistance and sharing
                    </p>
                  </div>
                </div>
                <div className="white-box-unchecked">
                  <div className="white-box-inner">
                    <img
                      src="/images/icons/icon-unchecked.svg"
                      alt="un-checked"
                    />
                    <span className="inner-title">Summary</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="green-theme-block">
              <img
                src="/images/illustrations/image-coffee-together.svg"
                alt="image-coffee-together"
                className="mx-auto"
              />

              <h2 className="mb-0 font-secondary fw600 text-white fz16">
                Let’s have coffee together
              </h2>

              <p className="mb-0 fz10">
                In need of any sort of assistance? We are here to help you!
              </p>

              <button className="text-uppercase fw600 fz10 bdrs10 btn btn-solid btn-solid--green">
                <span className="lh-1">Let’s chat!</span>
              </button>
            </div>
          </aside>
          <section className="body-profile2">
            <div className="title">
              <img
                className="d-inline-flex"
                src="./assets/images/icons/icon-left-awrrow.svg"
                alt=""
              />
              <span className="h-title-teal">Both</span>
              <span className="h-title-black"> - Your Introduction</span>
            </div>
            <div className="body-context height-adjust-context">
              <div className="btn-wrapper">
                <button className="custom-btn btn-light" onClick={handleSkip}>
                  skip {">"}
                </button>
                <button className="custom-btn btn-dark" onClick={handleNext}>
                  Next
                </button>
              </div>
              <div className="inner-body-context">
                <h6 className="heading-profiles">About Company</h6>
                <p className="details-grey">
                  Please select the basic information about you so we may know
                  you better
                </p>
                <img
                  className="mx-auto mt-2"
                  src="/images/horizontal-line.svg"
                  alt="horizontal-line"
                />
                <form className="docur-form">
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Company Contact no -
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper p-0">
                        <div className="flag-dd">
                          <div className="dropdown">
                            <button
                              className="border-0 p-0 btn btn-secondary dropdown-toggle flag-dd__ff"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <div className="border-0 flag-dd__ff-inner">
                                <img
                                  src="/images/icons/icon-flag-india-lg-rounded.svg"
                                  alt="icon-flag-india-lg-rounded"
                                />
                              </div>
                              <b className="arrow">
                                <img
                                  src="/images/icons/icon-dropdown-line-arrow.svg"
                                  alt="icon-dropdown-line-arrow"
                                />
                              </b>
                            </button>
                            <ul className="border-0 dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  <img
                                    src="/images/icons/icon-flag-india-lg-rounded.svg"
                                    alt="icon-flag-india-lg-rounded"
                                  />
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  <img
                                    src="./assets/images/icons/icon-flag-india-lg-rounded.svg"
                                    alt="icon-flag-india-lg-rounded"
                                  />
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  <img
                                    src="./assets/images/icons/icon-flag-india-lg-rounded.svg"
                                    alt="icon-flag-india-lg-rounded"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>

                          <span className="fw500 fz14 grayText ps-2 fst-italic dd-value">
                            +91
                          </span>

                          <input
                            type="text"
                            className="form-control shadow-none fz14 ps-0 fw500 bg-transparent"
                            placeholder="0800 900 790"
                            onChange={(e) =>
                              setCompanyContactNo(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Company URL -{" "}
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder="www.company.com"
                          onChange={(e) => setCompanyUrl(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Company Registration No -{" "}
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          placeholder="#00000"
                          onChange={(e) =>
                            setCompanyRegistrationNo(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <label className="form-level d-flex align-items-center">
                      Company Location -{" "}
                    </label>
                    <div className="blurred-widget-block">
                      <div className="input-wrapper position-relative">
                        <button className="btn-black" onClick={fetchLocation}>
                          <img
                            src="/images/icons/icon-location.svg"
                            alt="icon-location"
                          />
                        </button>
                        <GooglePlacesAutocomplete
                          addressFromLatLng={addressFromLatLng}
                          setAddressFromLatLng={setAddressFromLatLng}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <!-- stepper below --> */}
              <a href="" className="stepper single-step bg-c-green">
                <div className="rotate-text">Company introduction</div>
              </a>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
}
